import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCqb3GxEUmIOoymZE3EVTwxXeEmFVAoeaA",
  authDomain: "medicom-79e23.firebaseapp.com",
  databaseURL: "https://medicom-79e23-default-rtdb.firebaseio.com",
  projectId: "medicom-79e23",
  storageBucket: "medicom-79e23.appspot.com",
  messagingSenderId: "713324119290",
  appId: "1:713324119290:web:b08c2dd2ceed06bf1bb543",
  measurementId: "G-G5HZS9CJ9N",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging();
const db = getFirestore(app);
const analytics = getAnalytics(app);
const database = getDatabase(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { messaging, db, analytics, database, storage, auth };
