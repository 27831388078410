import React, { lazy } from "react";
import Header from "../pharmacyadmin/components/header";

const Dashboard = lazy(() => import("../pharmacyadmin/components/dashboard"));
const Login = lazy(() => import("../pharmacyadmin/components/login"));
const Register = lazy(() => import("../pharmacyadmin/components/register"));
const PharmacyForgotPassword = lazy(() =>
  import("../pharmacyadmin/components/forgotpassword")
);
const PharmacyVerifiedCode = lazy(() =>
  import("../pharmacyadmin/components/forgotpassword/VerifiedCode")
);
const PharmacyChangePassword = lazy(() =>
  import("../pharmacyadmin/components/forgotpassword/ChangePassword")
);
const AdminProducts = lazy(() =>
  import("../pharmacyadmin/components/products/adminproducts")
);
const MyProducts = lazy(() =>
  import("../pharmacyadmin/components/products/myproducts")
);
const AddProduct = lazy(() =>
  import("../pharmacyadmin/components/products/addproduct")
);
const EditProduct = lazy(() =>
  import("../pharmacyadmin/components/products/editproduct")
);

const CompletedPrescription = lazy(() =>
  import("../pharmacyadmin/components/prescription/completedprescription")
);
const CompletedPrescriptionDetails = lazy(() =>
  import(
    "../pharmacyadmin/components/prescription/completedprescription/CompletedPrescriptionDetails"
  )
);

const ConvertedPrescription = lazy(() =>
  import("../pharmacyadmin/components/prescription/convertedprescription")
);
const ConvertedPrescriptionDetails = lazy(() =>
  import(
    "../pharmacyadmin/components/prescription/convertedprescription/ConvertedPrescriptionDetails"
  )
);

const CheckPrescriptionNumber = lazy(() =>
  import(
    "../pharmacyadmin/components/prescription/completedprescription/CheckPrescriptionNumber"
  )
);
const CheckCodeVerify = lazy(() =>
  import(
    "../pharmacyadmin/components/prescription/completedprescription/CheckCodeVerify"
  )
);
const AcceptPrescription = lazy(() =>
  import(
    "../pharmacyadmin/components/prescription/completedprescription/AcceptPrescription"
  )
);
const AttachedPrescriptions = lazy(() =>
  import("../pharmacyadmin/components/prescription/attachedprescriptions")
);

const Orders = lazy(() => import("../pharmacyadmin/components/orders"));
const OrderDetails = lazy(() =>
  import("../pharmacyadmin/components/orders/OrderDetails")
);
const Profile = lazy(() => import("../pharmacyadmin/components/profile"));
const EditPharmacyProfile = lazy(() =>
  import("../pharmacyadmin/components/profile/EditPharmacyProfile")
);
const EditMyProfile = lazy(() =>
  import("../pharmacyadmin/components/profile/EditMyProfile")
);

const PharnacyRoutes = [
  {
    path: "pharmacyadmin/register",
    component: <Register />,
  },
  {
    path: "pharmacyadmin/login",
    component: <Login />,
  },
  {
    path: "/pharmacyadmin/forgot-password",
    component: <PharmacyForgotPassword />,
  },
  {
    path: "/pharmacyadmin/verified-code",
    component: <PharmacyVerifiedCode />,
  },
  {
    path: "/pharmacyadmin/change-password",
    component: <PharmacyChangePassword />,
  },
  {
    path: "pharmacyadmin",
    component: (
      <>
        <Header />
        <Dashboard />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/admin-products",
    component: (
      <>
        <Header />
        <AdminProducts />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/my-products",
    component: (
      <>
        <Header />
        <MyProducts />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/add-product",
    component: (
      <>
        <Header />
        <AddProduct />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/edit-product/:id",
    component: (
      <>
        <Header />
        <EditProduct />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/completed-prescriptions",
    component: (
      <>
        <Header />
        <CompletedPrescription />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/completed-prescriptions/:id",
    component: (
      <>
        <Header />
        <CompletedPrescriptionDetails />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/converted-prescriptions",
    component: (
      <>
        <Header />
        <ConvertedPrescription />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/converted-prescriptions/:id",
    component: (
      <>
        <Header />
        <ConvertedPrescriptionDetails />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/attached-prescriptions",
    component: (
      <>
        <Header />
        <AttachedPrescriptions />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/prescription/add-prescription",
    component: (
      <>
        <Header />
        <CheckPrescriptionNumber />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/prescription/checkCodeVerify",
    component: (
      <>
        <Header />
        <CheckCodeVerify />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/orders",
    component: (
      <>
        <Header />
        <Orders />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/order-details/:id",
    component: (
      <>
        <Header />
        <OrderDetails />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/profile",
    component: (
      <>
        <Header />
        <Profile />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/profile/edit-pharmacy",
    component: (
      <>
        <Header />
        <EditPharmacyProfile />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/profile/edit-my-profile",
    component: (
      <>
        <Header />
        <EditMyProfile />
      </>
    ),
  },
  {
    path: "/pharmacyadmin/prescription/accept-prescription",
    component: (
      <>
        <Header />
        <AcceptPrescription />
      </>
    ),
  },
];

export default PharnacyRoutes;
