export const GET_ADMIN_PRODUCTS_REQUEST = "GET_ADMIN_PRODUCTS_REQUEST";
export const GET_ADMIN_PRODUCTS_SUCCESS = "GET_ADMIN_PRODUCTS_SUCCESS";
export const GET_ADMIN_PRODUCTS_FAIL = "GET_ADMIN_PRODUCTS_FAIL";

export const GET_MY_PRODUCTS_REQUEST = "GET_MY_PRODUCTS_REQUEST";
export const GET_MY_PRODUCTS_SUCCESS = "GET_MY_PRODUCTS_SUCCESS";
export const GET_MY_PRODUCTS_FAIL = "GET_MY_PRODUCTS_FAIL";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

export const PRODUCTS_SEARCH_REQUEST = "PRODUCTS_SEARCH_REQUEST";
export const PRODUCTS_SEARCH_SUCCESS = "PRODUCTS_SEARCH_SUCCESS";
export const PRODUCTS_SEARCH_FAIL = "PRODUCTS_SEARCH_FAIL";

export const GET_ADMIN_PRODUCT_DETAILS_REQUEST =
  "GET_ADMIN_PRODUCT_DETAILS_REQUEST";
export const GET_ADMIN_PRODUCT_DETAILS_SUCCESS =
  "GET_ADMIN_PRODUCT_DETAILS_SUCCESS";
export const GET_ADMIN_PRODUCT_DETAILS_FAIL = "GET_ADMIN_PRODUCT_DETAILS_FAIL";

export const GET_MY_PRODUCT_DETAILS_REQUEST = "GET_MY_PRODUCT_DETAILS_REQUEST";
export const GET_MY_PRODUCT_DETAILS_SUCCESS = "GET_MY_PRODUCT_DETAILS_SUCCESS";
export const GET_MY_PRODUCT_DETAILS_FAIL = "GET_MY_PRODUCT_DETAILS_FAIL";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const PRODUCTS_BY_CATEGORY_REQUEST = "PRODUCTS_BY_CATEGORY_REQUEST";
export const PRODUCTS_BY_CATEGORY_SUCCESS = "PRODUCTS_BY_CATEGORY_SUCCESS";
export const PRODUCTS_BY_CATEGORY_FAIL = "PRODUCTS_BY_CATEGORY_FAIL";
