export const CREATE_SLIDER_REQUEST = "CREATE_SLIDER_REQUEST";
export const CREATE_SLIDER_SUCCESS = "CREATE_SLIDER_SUCCESS";
export const CREATE_SLIDER_FAIL = "CREATE_SLIDER_FAIL";

export const GET_SLIDERS_REQUEST = "GET_SLIDERS_REQUEST";
export const GET_SLIDERS_SUCCESS = "GET_SLIDERS_SUCCESS";
export const GET_SLIDERS_FAIL = "GET_SLIDERS_FAIL";

export const GET_SLIDER_DETAILS_REQUEST = "GET_SLIDER_DETAILS_REQUEST";
export const GET_SLIDER_DETAILS_SUCCESS = "GET_SLIDER_DETAILS_SUCCESS";
export const GET_SLIDER_DETAILS_FAIL = "GET_SLIDER_DETAILS_FAIL";

export const DELETE_SLIDER_REQUEST = "DELETE_SLIDER_REQUEST";
export const DELETE_SLIDER_SUCCESS = "DELETE_SLIDER_SUCCESS";
export const DELETE_SLIDER_FAIL = "DELETE_SLIDER_FAIL";

export const UPDATE_SLIDER_REQUEST = "UPDATE_SLIDER_REQUEST";
export const UPDATE_SLIDER_SUCCESS = "UPDATE_SLIDER_SUCCESS";
export const UPDATE_SLIDER_FAIL = "UPDATE_SLIDER_FAIL";

export const GET_SLIDERS_URL_OPTIONS = "GET_SLIDERS_URL_OPTIONS";
