export const REGISTER_PHARMACY_REQUEST = "REGISTER_PHARMACY_REQUEST";
export const REGISTER_PHARMACY_SUCCESS = "REGISTER_PHARMACY_SUCCESS";
export const REGISTER_PHARMACY_FAIL = "REGISTER_PHARMACY_FAIL";

export const LOGIN_PHARMACY_REQUEST = "LOGIN_PHARMACY_REQUEST";
export const LOGIN_PHARMACY_SUCCESS = "LOGIN_PHARMACY_SUCCESS";
export const LOGIN_PHARMACY_FAIL = "LOGIN_PHARMACY_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const VERIFIED_CODE_REQUEST = "VERIFIED_CODE_REQUEST";
export const VERIFIED_CODE_SUCCESS = "VERIFIED_CODE_SUCCESS";
export const VERIFIED_CODE_FAIL = "VERIFIED_CODE_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const EDIT_PHARMACY_PROFILE_REQUEST = "EDIT_PHARMACY_PROFILE_REQUEST";
export const EDIT_PHARMACY_PROFILE_SUCCESS = "EDIT_PHARMACY_PROFILE_SUCCESS";
export const EDIT_PHARMACY_PROFILE_FAIL = "EDIT_PHARMACY_PROFILE_FAIL";

export const EDIT_MY_PROFILE_REQUEST = "EDIT_MY_PROFILE_REQUEST";
export const EDIT_MY_PROFILE_SUCCESS = "EDIT_MY_PROFILE_SUCCESS";
export const EDIT_MY_PROFILE_FAIL = "EDIT_MY_PROFILE_FAIL";

export const GET_PHARMACY_SEARCH_REQUEST = "GET_PHARMACY_SEARCH_REQUEST";
export const GET_PHARMACY_SEARCH_SUCCESS = "GET_PHARMACY_SEARCH_SUCCESS";
export const GET_PHARMACY_SEARCH_FAIL = "GET_PHARMACY_SEARCH_FAIL";
