export const CREATE_FEELING_REQUEST = "CREATE_FEELING_REQUEST";
export const CREATE_FEELING_SUCCESS = "CREATE_FEELING_SUCCESS";
export const CREATE_FEELING_FAIL = "CREATE_FEELING_FAIL";

export const GET_FEELINGS_REQUEST = "GET_FEELINGS_REQUEST";
export const GET_FEELINGS_SUCCESS = "GET_FEELINGS_SUCCESS";
export const GET_FEELINGS_FAIL = "GET_FEELINGS_FAIL";

export const GET_FEELING_DETAILS_REQUEST = "GET_FEELING_DETAILS_REQUEST";
export const GET_FEELING_DETAILS_SUCCESS = "GET_FEELING_DETAILS_SUCCESS";
export const GET_FEELING_DETAILS_FAIL = "GET_FEELING_DETAILS_FAIL";

export const DELETE_FEELING_REQUEST = "DELETE_FEELING_REQUEST";
export const DELETE_FEELING_SUCCESS = "DELETE_FEELING_SUCCESS";
export const DELETE_FEELING_FAIL = "DELETE_FEELING_FAIL";

export const UPDATE_FEELING_REQUEST = "UPDATE_FEELING_REQUEST";
export const UPDATE_FEELING_SUCCESS = "UPDATE_FEELING_SUCCESS";
export const UPDATE_FEELING_FAIL = "UPDATE_FEELING_FAIL";
