import {
  CREATE_PRODUCT_FAIL,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  PRODUCTS_SEARCH_REQUEST,
  PRODUCTS_SEARCH_SUCCESS,
  PRODUCTS_SEARCH_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  PRODUCTS_BY_CATEGORY_REQUEST,
  PRODUCTS_BY_CATEGORY_SUCCESS,
  PRODUCTS_BY_CATEGORY_FAIL,
} from "../types/productsTypes";

const initialValue = {
  products: [],
  productsSearch: [],
  productDetails: {},
  productsByCategory: {},
  loading: false,
};

function adminProductReducer(state = initialValue, action) {
  switch (action.type) {
    case CREATE_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case CREATE_PRODUCT_SUCCESS:
      return { ...state, products: action.payload, loading: false };
    case CREATE_PRODUCT_FAIL:
      return { ...state, loading: false };

    case GET_PRODUCTS_REQUEST:
      return { ...state, loading: true };
    case GET_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload, loading: false };
    case GET_PRODUCTS_FAIL:
      return { ...state, loading: false };

    case PRODUCTS_SEARCH_REQUEST:
      return { ...state, loading: true };
    case PRODUCTS_SEARCH_SUCCESS:
      return { ...state, productsSearch: action.payload, loading: false };
    case PRODUCTS_SEARCH_FAIL:
      return { ...state, loading: false };

    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { ...state, productDetails: action.payload, loading: false };
    case PRODUCT_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case DELETE_PRODUCT_SUCCESS:
      return { ...state, products: action.payload, loading: false };
    case DELETE_PRODUCT_FAIL:
      return { ...state, loading: false };

    case UPDATE_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case UPDATE_PRODUCT_SUCCESS:
      return { ...state, products: action.payload, loading: false };
    case UPDATE_PRODUCT_FAIL:
      return { ...state, loading: false };

    case PRODUCTS_BY_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case PRODUCTS_BY_CATEGORY_SUCCESS:
      return { ...state, productsByCategory: action.payload, loading: false };
    case PRODUCTS_BY_CATEGORY_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminProductReducer;
