export const CREATE_CATEGORY_LABTEST_REQUEST =
  "CREATE_CATEGORY_LABTEST_REQUEST";
export const CREATE_CATEGORY_LABTEST_SUCCESS =
  "CREATE_CATEGORY_LABTEST_SUCCESS";
export const CREATE_CATEGORY_LABTEST_FAIL = "CREATE_CATEGORY_LABTEST_FAIL";

export const GET_CATEGORY_LABTESTS_REQUEST = "GET_CATEGORY_LABTESTS_REQUEST";
export const GET_CATEGORY_LABTESTS_SUCCESS = "GET_CATEGORY_LABTESTS_SUCCESS";
export const GET_CATEGORY_LABTESTS_FAIL = "GET_CATEGORY_LABTESTS_FAIL";

export const SPECIFIC_CATEGORY_LABTEST_REQUEST =
  "SPECIFIC_CATEGORY_LABTEST_REQUEST";
export const SPECIFIC_CATEGORY_LABTEST_SUCCESS =
  "SPECIFIC_CATEGORY_LABTEST_SUCCESS";
export const SPECIFIC_CATEGORY_LABTEST_FAIL = "SPECIFIC_CATEGORY_LABTEST_FAIL";

export const DELETE_CATEGORY_LABTEST_REQUEST =
  "DELETE_CATEGORY_LABTEST_REQUEST";
export const DELETE_CATEGORY_LABTEST_SUCCESS =
  "DELETE_CATEGORY_LABTEST_SUCCESS";
export const DELETE_CATEGORY_LABTEST_FAIL = "DELETE_CATEGORY_LABTEST_FAIL";

export const UPDATE_CATEGORY_LABTEST_REQUEST =
  "UPDATE_CATEGORY_LABTEST_REQUEST";
export const UPDATE_CATEGORY_LABTEST_SUCCESS =
  "UPDATE_CATEGORY_LABTEST_SUCCESS";
export const UPDATE_CATEGORY_LABTEST_FAIL = "UPDATE_CATEGORY_LABTEST_FAIL";
