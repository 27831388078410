export const GET_PRESCRIPTIONS_REQUESTS_REQUEST =
  "GET_PRESCRIPTIONS_REQUESTS_REQUEST";
export const GET_PRESCRIPTIONS_REQUESTS_SUCCESS =
  "GET_PRESCRIPTIONS_REQUESTS_SUCCESS";
export const GET_PRESCRIPTIONS_REQUESTS_FAIL =
  "GET_PRESCRIPTIONS_REQUESTS_FAIL";

export const GET_PRES_REQUEST_DETAILS_REQUEST =
  "GET_PRES_REQUEST_DETAILS_REQUEST";
export const GET_PRES_REQUEST_DETAILS_SUCCESS = "GET_PRES_DETAIL_SUCCESS";
export const GET_PRES_REQUEST_DETAILS_FAIL = "GET_PRES_REQUEST_DETAILS_FAIL";

export const SEND_PRICE_PRESCRIPTION_REQUEST =
  "SEND_PRICE_PRESCRIPTION_REQUEST";
export const SEND_PRICE_PRESCRIPTION_SUCCESS =
  "SEND_PRICE_PRESCRIPTION_SUCCESS";
export const SEND_PRICE_PRESCRIPTION_FAIL = "SEND_PRICE_PRESCRIPTION_FAIL";

export const SEND_PRESCRIPTION_TO_ORDER_REQUEST =
  "SEND_PRESCRIPTION_TO_ORDER_REQUEST";
export const SEND_PRESCRIPTION_TO_ORDER_SUCCESS =
  "SEND_PRESCRIPTION_TO_ORDER_SUCCESS";
export const SEND_PRESCRIPTION_TO_ORDER_FAIL =
  "SEND_PRESCRIPTION_TO_ORDER_FAIL";
