import {
  CREATE_SLIDER_REQUEST,
  CREATE_SLIDER_SUCCESS,
  CREATE_SLIDER_FAIL,
  GET_SLIDERS_REQUEST,
  GET_SLIDERS_SUCCESS,
  GET_SLIDERS_FAIL,
  GET_SLIDER_DETAILS_REQUEST,
  GET_SLIDER_DETAILS_SUCCESS,
  GET_SLIDER_DETAILS_FAIL,
  DELETE_SLIDER_REQUEST,
  DELETE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAIL,
  UPDATE_SLIDER_REQUEST,
  UPDATE_SLIDER_SUCCESS,
  UPDATE_SLIDER_FAIL,
  GET_SLIDERS_URL_OPTIONS,
} from "../types/sliderTypes";

const initialState = {
  sliders: {},
  sliderDetails: "",
  sliderOptions: [],
  loading: false,
};

function adminSliderReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SLIDER_REQUEST:
      return { ...state, loading: true };
    case CREATE_SLIDER_SUCCESS:
      return { ...state, sliders: action.payload, loading: false };
    case CREATE_SLIDER_FAIL:
      return { ...state, loading: false };

    case GET_SLIDERS_REQUEST:
      return { ...state, loading: true };
    case GET_SLIDERS_SUCCESS:
      return { ...state, sliders: action.payload, loading: false };
    case GET_SLIDERS_FAIL:
      return { ...state, loading: false };

    case GET_SLIDER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_SLIDER_DETAILS_SUCCESS:
      return { ...state, sliderDetails: action.payload, loading: false };
    case GET_SLIDER_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_SLIDER_REQUEST:
      return { ...state, loading: true };
    case DELETE_SLIDER_SUCCESS:
      return { ...state, sliders: action.payload, loading: false };
    case DELETE_SLIDER_FAIL:
      return { ...state, loading: false };

    case UPDATE_SLIDER_REQUEST:
      return { ...state, loading: true };
    case UPDATE_SLIDER_SUCCESS:
      return { ...state, sliders: action.payload, loading: false };
    case UPDATE_SLIDER_FAIL:
      return { ...state, loading: false };

    case GET_SLIDERS_URL_OPTIONS:
      return { ...state, sliderOptions: action.payload };

    default:
      return state;
  }
}

export default adminSliderReducer;
