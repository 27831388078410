import {
  GET_SOCIAL_MEDIA_REQUEST,
  GET_SOCIAL_MEDIA_SUCCESS,
  GET_SOCIAL_MEDIA_FAIL,
  SET_SOCIAL_MEDIA_REQUEST,
  SET_SOCIAL_MEDIA_SUCCESS,
  SET_SOCIAL_MEDIA_FAIL,
} from "../types/socialMediaTypes";

const initialValue = {
  socialMedia: [],
  loading: false,
  actionLoading: false,
};

function medicalSocialMediaReducer(state = initialValue, action) {
  switch (action.type) {
    case GET_SOCIAL_MEDIA_REQUEST:
      return { ...state, loading: true };
    case GET_SOCIAL_MEDIA_SUCCESS:
      return { ...state, loading: false, socialMedia: action.payload };
    case GET_SOCIAL_MEDIA_FAIL:
      return { ...state, loading: false };

    case SET_SOCIAL_MEDIA_REQUEST:
      return { ...state, actionLoading: true };
    case SET_SOCIAL_MEDIA_SUCCESS:
      return { ...state, socialMedia: action.payload, actionLoading: false };
    case SET_SOCIAL_MEDIA_FAIL:
      return { ...state, actionLoading: false };

    default:
      return state;
  }
}

export default medicalSocialMediaReducer;
