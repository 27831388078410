export const CREATE_PHARMACY_REQUEST = "CREATE_PHARMACY_REQUEST";
export const CREATE_PHARMACY_SUCCESS = "CREATE_PHARMACY_SUCCESS";
export const CREATE_PHARMACY_FAIL = "CREATE_PHARMACY_FAIL";

export const GET_PHARMACIES_REQUEST = "GET_PHARMACIES_REQUEST";
export const GET_PHARMACIES_SUCCESS = "GET_PHARMACIES_SUCCESS";
export const GET_PHARMACIES_FAIL = "GET_PHARMACIES_FAIL";

export const GET_PHARMACY_DETAILS_REQUEST = "GET_PHARMACY_DETAILS_REQUEST";
export const GET_PHARMACY_DETAILS_SUCCESS = "GET_PHARMACY_DETAILS_SUCCESS";
export const GET_PHARMACY_DETAILS_FAIL = "GET_PHARMACY_DETAILS_FAIL";

export const DELETE_PHARMACY_REQUEST = "DELETE_PHARMACY_REQUEST";
export const DELETE_PHARMACY_SUCCESS = "DELETE_PHARMACY_SUCCESS";
export const DELETE_PHARMACY_FAIL = "DELETE_PHARMACY_FAIL";

export const UPDATE_PHARMACY_REQUEST = "UPDATE_PHARMACY_REQUEST";
export const UPDATE_PHARMACY_SUCCESS = "UPDATE_PHARMACY_SUCCESS";
export const UPDATE_PHARMACY_FAIL = "UPDATE_PHARMACY_FAIL";

export const APPROVE_PHARMACY_REQUEST = "APPROVE_PHARMACY_REQUEST";
export const APPROVE_PHARMACY_SUCCESS = "APPROVE_PHARMACY_SUCCESS";
export const APPROVE_PHARMACY_FAIL = "APPROVE_PHARMACY_FAIL";

export const CHANGE_STATUS_REQUEST = "CHANGE_STATUS_REQUEST";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_FAIL = "CHANGE_STATUS_FAIL";

export const GET_PHARMACY_TIME_REQUEST = "GET_PHARMACY_TIME_REQUEST";
export const GET_PHARMACY_TIME_SUCCESS = "GET_PHARMACY_TIME_SUCCESS";
export const GET_PHARMACY_TIME_FAIL = "GET_PHARMACY_TIME_FAIL";

export const SET_PHARMACY_TIME_REQUEST = "SET_PHARMACY_TIME_REQUEST";
export const SET_PHARMACY_TIME_SUCCESS = "SET_PHARMACY_TIME_SUCCESS";
export const SET_PHARMACY_TIME_FAIL = "SET_PHARMACY_TIME_FAIL";

export const GET_PHARMACY_PRESCRIPTIONS_REQUEST =
  "GET_PHARMACY_PRESCRIPTIONS_REQUEST";
export const GET_PHARMACY_PRESCRIPTIONS_SUCCESS =
  "GET_PHARMACY_PRESCRIPTIONS_SUCCESS";
export const GET_PHARMACY_PRESCRIPTIONS_FAIL =
  "GET_PHARMACY_PRESCRIPTIONS_FAIL";

export const GET_PHARMACY_PRESCRIPTION_DETAILS_REQUEST =
  "GET_PHARMACY_PRESCRIPTION_DETAILS_REQUEST";
export const GET_PHARMACY_PRESCRIPTION_DETAILS_SUCCESS =
  "GET_PHARMACY_PRESCRIPTION_DETAILS_SUCCESS";
export const GET_PHARMACY_PRESCRIPTION_DETAILS_FAIL =
  "GET_PHARMACY_PRESCRIPTION_DETAILS_FAIL";
