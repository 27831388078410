import {
  GET_PRESCRIPTIONS_REQUEST,
  GET_PRESCRIPTIONS_SUCCESS,
  GET_PRESCRIPTIONS_FAIL,
  GET_PRESCRIPTION_DETAILS_REQUEST,
  GET_PRESCRIPTION_DETAILS_SUCCESS,
  GET_PRESCRIPTION_DETAILS_FAIL,
} from "../types/prescriptionTypes";

const initialValue = {
  prescriptions: [],
  presDetails: {},
  loading: false,
};

function medicalPrescriptionReducer(state = initialValue, action) {
  switch (action.type) {
    case GET_PRESCRIPTIONS_REQUEST:
      return { ...state, loading: true };
    case GET_PRESCRIPTIONS_SUCCESS:
      return {
        ...state,
        prescriptions: action.payload,
        loading: false,
      };
    case GET_PRESCRIPTIONS_FAIL:
      return { ...state, loading: false };

    case GET_PRESCRIPTION_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_PRESCRIPTION_DETAILS_SUCCESS:
      return { ...state, presDetails: action.payload, loading: false };
    case GET_PRESCRIPTION_DETAILS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default medicalPrescriptionReducer;
