import {
  GET_PRESCRIPTIONS_REQUESTS_REQUEST,
  GET_PRESCRIPTIONS_REQUESTS_SUCCESS,
  GET_PRESCRIPTIONS_REQUESTS_FAIL,
  GET_PRES_REQUEST_DETAILS_REQUEST,
  GET_PRES_REQUEST_DETAILS_SUCCESS,
  GET_PRES_REQUEST_DETAILS_FAIL,
} from "../types/prescriptionRequestTypes";

const initialValue = {
  prescRequestDetails: [],
  prescriptionsRequests: [],
  loading: false,
};

function pharmacyPrescriptionReducer(state = initialValue, action) {
  switch (action.type) {
    case GET_PRESCRIPTIONS_REQUESTS_REQUEST:
      return { ...state, loading: true };
    case GET_PRESCRIPTIONS_REQUESTS_SUCCESS:
      return {
        ...state,
        prescriptionsRequests: action.payload,
        loading: false,
      };
    case GET_PRESCRIPTIONS_REQUESTS_FAIL:
      return { ...state, loading: false };

    case GET_PRES_REQUEST_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_PRES_REQUEST_DETAILS_SUCCESS:
      return { ...state, prescRequestDetails: action.payload, loading: false };
    case GET_PRES_REQUEST_DETAILS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default pharmacyPrescriptionReducer;
