import React, { useState, useEffect } from "react";
import { onMessageListener } from "../../hooks/firebase/useGetToken";
import { notify } from "../../hooks/notify/useNotification";
import {
  getAppointments,
  getCompletedAppointments,
} from "../../redux/common/actions/appointmentActions";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDoctorChat } from "../../redux/doctor/actions/chatActions";
import { getNotifications } from "../../redux/common/actions/notificationAction";

function MessageListener({ user, statusApp, userId }) {
  const [alert, setAlert] = useState({ title: "", body: "" });
  const type = user?.role === "doctor" ? "doctorId" : "medicalId";

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getNotifications(`${user?.role}s`));
    }
    if (alert?.title) {
      handleNotify();
    }
  }, [alert]);

  function ToastDisplay() {
    const link =
      alert?.sectionType === "appointments"
        ? `/${user?.role}/appointments`
        : `/doctor/chat`;
    return (
      <Link to={link} className="text-decoration-none">
        <p className="text-dark">
          <strong>{alert?.title}</strong>
        </p>
        <p className="text-muted">{alert?.body}</p>
      </Link>
    );
  }

  const getTodayDate = () => {
    let date = new Date();
    date = date.toLocaleDateString("en-CA");
    return date;
  };

  onMessageListener()
    .then((payload) => {
      const bookingDate = payload?.data?.bookingDate;
      setAlert({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
        sectionType: payload?.data?.sectionType,
      });
      if (payload?.data?.sectionType === "appointments") {
        dispatch(
          getAppointments({
            [type]: user?.id,
            statusApp: statusApp || 0,
            userId: userId ? userId : 0,
          })
        );
        if (getTodayDate() === bookingDate) {
          dispatch(
            getCompletedAppointments({
              [type]: user?.id,
              date: getTodayDate(),
            })
          );
        }
      }
      if (
        payload?.data &&
        payload?.data["gcm.notification.sectionType"] === "newPatient"
      ) {
        dispatch(getDoctorChat());
      }
    })
    .catch((err) => console.log("failed: ", err));

  const handleNotify = () => notify(<ToastDisplay />, "info");

  return;
}

export default MessageListener;
