import {
  CREATE_LAB_TEST_REQUEST,
  CREATE_LAB_TEST_SUCCESS,
  CREATE_LAB_TEST_FAIL,
  GET_LAB_TESTS_REQUEST,
  GET_LAB_TESTS_SUCCESS,
  GET_LAB_TESTS_FAIL,
  GET_LAB_TEST_DETAILS_REQUEST,
  GET_LAB_TEST_DETAILS_SUCCESS,
  GET_LAB_TEST_DETAILS_FAIL,
  DELETE_LAB_TEST_REQUEST,
  DELETE_LAB_TEST_SUCCESS,
  DELETE_LAB_TEST_FAIL,
  UPDATE_LAB_TEST_REQUEST,
  UPDATE_LAB_TEST_SUCCESS,
  UPDATE_LAB_TEST_FAIL,
} from "../types/labTestTypes";

const initialState = {
  labTests: [],
  labTestDetails: {},
  loading: false,
};

function adminLabTestReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_LAB_TEST_REQUEST:
      return { ...state, loading: true };
    case CREATE_LAB_TEST_SUCCESS:
      return { ...state, labTests: action.payload, loading: false };
    case CREATE_LAB_TEST_FAIL:
      return { ...state, loading: false };

    case GET_LAB_TESTS_REQUEST:
      return { ...state, loading: true };
    case GET_LAB_TESTS_SUCCESS:
      return { ...state, labTests: action.payload, loading: false };
    case GET_LAB_TESTS_FAIL:
      return { ...state, loading: false };

    case GET_LAB_TEST_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_LAB_TEST_DETAILS_SUCCESS:
      return { ...state, labTestDetails: action.payload, loading: false };
    case GET_LAB_TEST_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_LAB_TEST_REQUEST:
      return { ...state, loading: true };
    case DELETE_LAB_TEST_SUCCESS:
      return { ...state, labTests: action.payload, loading: false };
    case DELETE_LAB_TEST_FAIL:
      return { ...state, loading: false };

    case UPDATE_LAB_TEST_REQUEST:
      return { ...state, loading: true };
    case UPDATE_LAB_TEST_SUCCESS:
      return { ...state, labTests: action.payload, loading: false };
    case UPDATE_LAB_TEST_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminLabTestReducer;
