export const GET_BILLINGS_FAIL = "GET_BILLINGS_FAIL";
export const GET_BILLINGS_REQUEST = "GET_BILLINGS_REQUEST";
export const GET_BILLINGS_SUCCESS = "GET_BILLINGS_SUCCESS";

export const GET_BILLING_DEATAILS_REQUEST = "GET_BILLING_DEATAILS_REQUEST";
export const GET_BILLING_DEATAILS_SUCCESS = "GET_BILLING_DEATAILS_SUCCESS";
export const GET_BILLING_DEATAILS_FAIL = "GET_BILLING_DEATAILS_FAIL";

export const CREATE_BILLING_REQUEST = "CREATE_BILLING_REQUEST";
export const CREATE_BILLING_SUCCESS = "CREATE_BILLING_SUCCESS";
export const CREATE_BILLING_FAIL = "CREATE_BILLING_FAIL";
