import {
  GET_PATIENTS_REQUEST,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
  GET_PATIENT_DETAILS_REQUEST,
  GET_PATIENT_DETAILS_SUCCESS,
  GET_PATIENT_DETAILS_FAIL,
} from "../types/patientTypes";

const initialState = {
  patients: {},
  patientDetails: {},
  loading: false,
};

function medicalPatientReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PATIENTS_REQUEST:
      return { ...state, loading: true };
    case GET_PATIENTS_SUCCESS:
      return { ...state, patients: action.payload, loading: false };
    case GET_PATIENTS_FAIL:
      return { ...state, loading: false };

    case GET_PATIENT_DETAILS_REQUEST:
      return { ...state };
    case GET_PATIENT_DETAILS_SUCCESS:
      return { ...state, patientDetails: action.payload };
    case GET_PATIENT_DETAILS_FAIL:
      return { ...state };

    default:
      return state;
  }
}

export default medicalPatientReducer;
