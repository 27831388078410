export const CREATE_LAB_TEST_REQUEST = "CREATE_LAB_TEST_REQUEST";
export const CREATE_LAB_TEST_SUCCESS = "CREATE_LAB_TEST_SUCCESS";
export const CREATE_LAB_TEST_FAIL = "CREATE_LAB_TEST_FAIL";

export const GET_LAB_TESTS_REQUEST = "GET_LAB_TESTS_REQUEST";
export const GET_LAB_TESTS_SUCCESS = "GET_LAB_TESTS_SUCCESS";
export const GET_LAB_TESTS_FAIL = "GET_LAB_TESTS_FAIL";

export const GET_LAB_TEST_DETAILS_REQUEST = "GET_LAB_TEST_DETAILS_REQUEST";
export const GET_LAB_TEST_DETAILS_SUCCESS = "GET_LAB_TEST_DETAILS_SUCCESS";
export const GET_LAB_TEST_DETAILS_FAIL = "GET_SPECIFIC_LAB_TEST_FAIL";

export const DELETE_LAB_TEST_REQUEST = "DELETE_LAB_TEST_REQUEST";
export const DELETE_LAB_TEST_SUCCESS = "DELETE_LAB_TEST_SUCCESS";
export const DELETE_LAB_TEST_FAIL = "DELETE_LAB_TEST_FAIL";

export const UPDATE_LAB_TEST_REQUEST = "UPDATE_LAB_TEST_REQUEST";
export const UPDATE_LAB_TEST_SUCCESS = "UPDATE_LAB_TEST_SUCCESS";
export const UPDATE_LAB_TEST_FAIL = "UPDATE_LAB_TEST_FAIL";
