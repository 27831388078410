export const GET_APPOINT_DOCTOR_REQUEST = "GET_APPOINT_DOCTOR_REQUEST";
export const GET_APPOINT_DOCTOR_SUCCESS = "GET_APPOINT_DOCTOR_SUCCESS";
export const GET_APPOINT_DOCTOR_FAIL = "GET_APPOINT_DOCTOR_FAIL";

export const GET_CHECK_APPOINT_REQUEST = "GET_CHECK_APPOINT_REQUEST";
export const GET_CHECK_APPOINT_SUCCESS = "GET_CHECK_APPOINT_SUCCESS";
export const GET_CHECK_APPOINT_FAIL = "GET_CHECK_APPOINT_FAIL";

export const CREATE_DESCRIPTION_REQUEST = "CREATE_DESCRIPTION_REQUEST";
export const CREATE_DESCRIPTION_SUCCESS = "CREATE_DESCRIPTION_SUCCESS";
export const CREATE_DESCRIPTION_FAIL = "CREATE_DESCRIPTION_FAIL";
