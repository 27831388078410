import React, { lazy } from "react";

const Home = lazy(() => import("../client/components/home"));
const Policy = lazy(() => import("../pages/policy"));
const Terms = lazy(() => import("../pages/terms"));
const GuestRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/pages/policy",
    component: <Policy />,
  },
  {
    path: "/pages/terms",
    component: <Terms />,
  },
  // {
  //   path: "404",
  //   component: <PageNotFound />,
  // },
  // {
  //   path: "*",
  //   component: <Navigate to="/404" />,
  // },
];
export default GuestRoutes;
