import React from "react";
import { Media } from "react-bootstrap";
import { avatar02 } from "../imagepath";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getPharmacySearch } from "../../../redux/pharmacy/actions/authActions";

function Search() {
  // const [name, setName] = useState("");
  const { pharmacySearch } = useSelector((state) => state.pharmacyAuth);

  const dispatch = useDispatch();

  const handleSearch = (e) => {
    let text = e.target.value.trim();
    // setName(e.target.value);
    if (text) {
      dispatch(getPharmacySearch({ name: text }));
      localStorage.setItem("searchKey", text);
    }
  };

  return (
    <div className="top-nav-search">
      <div className="main">
        <form className="search">
          <div className="s-icon">
            <i className="fas fa-search" />
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Start typing your Search..."
            onKeyUp={handleSearch}
          />
          <nav className="results">
            <div className="d-flex justify-content-between">
              <ul className="p-0 doctors w-50">
                <li>
                  <h6>
                    <Link to="/pharmacyadmin/my-products">
                      <i className="me-1">
                        <FeatherIcon icon="shopping-cart" />
                      </i>
                      Products
                    </Link>
                  </h6>
                </li>
                {pharmacySearch?.products?.map((item) => {
                  return (
                    <li key={item?.id}>
                      <Media>
                        <Media.Body className="d-flex">
                          <h2 className="table-avatar">
                            <Link
                              className="avatar avatar-sm me-2 user-dt"
                              to={`/pharmacyadmin/my-products`}
                            >
                              <img
                                className="avatar avatar-img"
                                src={item?.photo || avatar02}
                                alt="User Image"
                              />
                            </Link>
                          </h2>
                          <Link
                            to={`/pharmacyadmin/my-products`}
                            className="user-name"
                          >
                            {item?.name}
                          </Link>
                        </Media.Body>
                      </Media>
                    </li>
                  );
                })}
              </ul>
              <ul className="p-0 doctors w-50">
                <li>
                  <h6>
                    <Link to="/pharmacyadmin/orders">
                      <i className="me-1">
                        <FeatherIcon icon="shopping-bag" />
                      </i>
                      Orders
                    </Link>
                  </h6>
                </li>
                {pharmacySearch?.orders?.map((item) => {
                  return (
                    <li key={item?.id}>
                      <Media>
                        <Media.Body className="d-flex">
                          <h2 className="table-avatar">
                            <Link
                              className="avatar avatar-sm me-2 user-dt"
                              to={`/pharmacyadmin/orders/profile/${item.id}`}
                            >
                              <img
                                className="avatar avatar-img"
                                src={item?.photo || avatar02}
                                alt="User Image"
                              />
                            </Link>
                          </h2>
                          <Link
                            to={`/pharmacyadmin/orders/profile/${item.id}`}
                            className="user-name"
                          >
                            {item.userName}
                          </Link>
                        </Media.Body>
                      </Media>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* <div className="text  mb-2">
              <Link to={`/medical/serach`}>More Search</Link>
            </div> */}
          </nav>
        </form>
      </div>
    </div>
  );
}

export default Search;
