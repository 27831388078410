import { useInsertData } from "../../../hooks/api/useInsertData";
import { useGetData } from "../../../hooks/api/useGetData";
import { notify } from "../../../hooks/notify/useNotification";
import {
  REGISTER_PHARMACY_REQUEST,
  REGISTER_PHARMACY_SUCCESS,
  REGISTER_PHARMACY_FAIL,
  LOGIN_PHARMACY_REQUEST,
  LOGIN_PHARMACY_SUCCESS,
  LOGIN_PHARMACY_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  VERIFIED_CODE_REQUEST,
  VERIFIED_CODE_SUCCESS,
  VERIFIED_CODE_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  EDIT_MY_PROFILE_REQUEST,
  EDIT_MY_PROFILE_SUCCESS,
  EDIT_PHARMACY_PROFILE_REQUEST,
  EDIT_PHARMACY_PROFILE_SUCCESS,
  GET_PHARMACY_SEARCH_REQUEST,
  GET_PHARMACY_SEARCH_SUCCESS,
  GET_PHARMACY_SEARCH_FAIL,
} from "../types/authTypes";

export const pharmacyRegister = (formData, callback) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_PHARMACY_REQUEST,
    });

    const res = await useInsertData("/api/pharmacy/register", formData);
    res.code === 201 && notify(res.message, "success");
    localStorage.setItem(
      "user",
      JSON.stringify({ ...res?.data, role: "pharmacy" })
    );
    dispatch({
      type: REGISTER_PHARMACY_SUCCESS,
      payload: { ...res?.data, role: "pharmacy" },
    });
    callback();
    console.log(res);
  } catch (err) {
    console.log(err);
    err &&
      notify(
        err?.response?.data?.message?.userName?.shift() ||
          err?.response?.data?.message?.mobile?.shift() ||
          err?.response?.data?.message?.email?.shift() ||
          err?.response?.data?.message?.password?.shift() ||
          err?.message,
        "error"
      );
    dispatch({
      type: REGISTER_PHARMACY_FAIL,
    });
  }
};

export const pharmacyLogin = (formData, callback) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_PHARMACY_REQUEST,
    });

    const res = await useInsertData("/api/pharmacy/login", formData);
    res.code === 201 && notify(res.message, "success");
    localStorage.setItem(
      "user",
      JSON.stringify({ ...res?.data, role: "pharmacy" })
    );
    dispatch({
      type: LOGIN_PHARMACY_SUCCESS,
      payload: { ...res?.data, role: "pharmacy" },
    });
    console.log(res);
    callback();
  } catch (err) {
    console.log(err);
    err &&
      notify(
        err?.response?.data?.message?.mobile?.shift() ||
          err?.response?.data?.message?.password?.shift() ||
          err?.message,
        "error"
      );
    dispatch({
      type: LOGIN_PHARMACY_FAIL,
    });
  }
};

export const forgotPassword = (formData, callback) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    const res = await useInsertData("/api/pharmacy/send_code", formData);
    res.code === 201 && notify(res.message, "success");
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: res.data,
    });
    callback();
  } catch (err) {
    console.log(err);
    err &&
      notify(
        err?.response?.data?.message?.mobile?.shift() || err?.message,
        "error"
      );
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: err?.response?.data.message || err?.message,
    });
  }
};

export const verifiedCode = (formData, callback) => async (dispatch) => {
  try {
    dispatch({
      type: VERIFIED_CODE_REQUEST,
    });

    const res = await useInsertData("/api/pharmacy/verify_code", formData);
    res.code === 201 && notify(res.message, "success");
    dispatch({
      type: VERIFIED_CODE_SUCCESS,
      payload: res.data,
    });
    callback();
  } catch (err) {
    console.log(err);
    err && notify(err?.response?.data?.message || err?.message, "error");
    dispatch({
      type: VERIFIED_CODE_FAIL,
    });
  }
};

export const changePassword = (formData, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
    });

    const res = await useInsertData("/api/pharmacy/change_password", formData);
    localStorage.setItem(
      "user",
      JSON.stringify({ ...res?.data, role: "pharmacy" })
    );
    res.code === 201 && notify(res.message, "success");
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: { ...res?.data, role: "pharmacy" },
    });
    callback();
  } catch (err) {
    console.log(err);
    err &&
      notify(
        err?.response?.data?.message?.new_password?.shift() || err?.message,
        "error"
      );
    dispatch({
      type: CHANGE_PASSWORD_FAIL,
      payload: err?.response?.data.message || err?.message,
    });
  }
};

export const getMyProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PROFILE_REQUEST,
    });

    const res = await useGetData("/api/pharmacy/profile");
    localStorage.setItem(
      "user",
      JSON.stringify({ ...res?.data, role: "pharmacy" })
    );
    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: { ...res?.data, role: "pharmacy" },
    });
    console.log(res);
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_PROFILE_FAIL,
    });
  }
};

export const editPharmacyProfile = (formData, callback) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_PHARMACY_PROFILE_REQUEST,
    });
    const res = await useInsertData("/api/pharmacy/update", formData);
    res.code === 201 && notify(res.message, "success");
    dispatch({
      type: EDIT_PHARMACY_PROFILE_SUCCESS,
      payload: res?.data,
    });
    callback();
    console.log(res);
  } catch (err) {
    console.log(err);
    err &&
      notify(
        err?.response?.data?.message?.pharmacyName?.shift() ||
          err?.response?.data?.message?.email?.shift() ||
          err?.response?.data?.message?.telPhone?.shift() ||
          err?.response?.data?.message?.line1?.shift() ||
          err?.response?.data?.message?.countryId?.shift() ||
          err?.response?.data?.message?.cityId?.shift() ||
          err?.response?.data?.message?.pharmacyMobile?.shift() ||
          err?.message,
        "error"
      );
  }
};

export const editMyProfile = (formData, callback) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_MY_PROFILE_REQUEST,
    });
    const res = await useInsertData("/api/pharmacy/profile/update", formData);
    res.code === 201 && notify(res.message, "success");
    localStorage.setItem(
      "user",
      JSON.stringify({ ...res?.data, role: "pharmacy" })
    );
    dispatch({
      type: EDIT_MY_PROFILE_SUCCESS,
      payload: { ...res?.data, role: "pharmacy" },
    });
    console.log(res);
    callback();
  } catch (err) {
    console.log(err);
  }
};

export const getPharmacySearch =
  ({ name }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_PHARMACY_SEARCH_REQUEST,
      });

      const res = await useGetData(`/api/pharmacy/search?name?=${name}`);
      dispatch({
        type: GET_PHARMACY_SEARCH_SUCCESS,
        payload: res?.data,
      });
      console.log(res);
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_PHARMACY_SEARCH_FAIL,
      });
    }
  };
