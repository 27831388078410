import {
  CREATE_BILLING_REQUEST,
  CREATE_BILLING_SUCCESS,
  CREATE_BILLING_FAIL,
  GET_BILLINGS_REQUEST,
  GET_BILLINGS_SUCCESS,
  GET_BILLINGS_FAIL,
  GET_BILLING_DEATAILS_REQUEST,
  GET_BILLING_DEATAILS_SUCCESS,
  GET_BILLING_DEATAILS_FAIL,
} from "../types/billingTypes";

const initialState = {
  billings: [],
  billingDetails: {},
  loading: false,
  actionLoading: false,
};

function doctorBillingReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_BILLING_REQUEST:
      return { ...state, loading: true, actionLoading: true };
    case CREATE_BILLING_SUCCESS:
      return {
        ...state,
        billings: action.payload,
        loading: false,
        actionLoading: false,
      };
    case CREATE_BILLING_FAIL:
      return { ...state, loading: false, actionLoading: false };

    case GET_BILLINGS_REQUEST:
      return { ...state, loading: true };
    case GET_BILLINGS_SUCCESS:
      return { ...state, billings: action.payload, loading: false };
    case GET_BILLINGS_FAIL:
      return { ...state, loading: false };

    case GET_BILLING_DEATAILS_REQUEST:
      return { ...state, loading: true };
    case GET_BILLING_DEATAILS_SUCCESS:
      return { ...state, billingDetails: action.payload, loading: false };
    case GET_BILLING_DEATAILS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default doctorBillingReducer;
