import {
  REGISTER_PHARMACY_REQUEST,
  REGISTER_PHARMACY_SUCCESS,
  REGISTER_PHARMACY_FAIL,
  LOGIN_PHARMACY_REQUEST,
  LOGIN_PHARMACY_SUCCESS,
  LOGIN_PHARMACY_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PHARMACY_SEARCH_REQUEST,
  GET_PHARMACY_SEARCH_SUCCESS,
  GET_PHARMACY_SEARCH_FAIL,
} from "../types/authTypes";
import { USER_LOGOUT_SUCCESS } from "../../common/types/commonTypes";

const initialState = {
  pharmacyData: JSON.parse(localStorage.getItem("user")) || {},
  pharmacySearch: {},
  loading: false,
};

function pharmacyAuthReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGOUT_SUCCESS:
      return { ...state, pharmacyData: {} };

    case REGISTER_PHARMACY_REQUEST:
      return { ...state, loading: true };
    case REGISTER_PHARMACY_SUCCESS:
      return { ...state, pharmacyData: action.payload, loading: false };
    case REGISTER_PHARMACY_FAIL:
      return { ...state, loading: false };

    case LOGIN_PHARMACY_REQUEST:
      return { ...state, loading: true };
    case LOGIN_PHARMACY_SUCCESS:
      return { ...state, pharmacyData: action.payload, loading: false };
    case LOGIN_PHARMACY_FAIL:
      return { ...state, loading: false };

    case GET_PROFILE_REQUEST:
      return { ...state, loading: true };
    case GET_PROFILE_SUCCESS:
      return { ...state, pharmacyData: action.payload, loading: false };
    case GET_PROFILE_FAIL:
      return { ...state, loading: false };

    case GET_PHARMACY_SEARCH_REQUEST:
      return { ...state, loading: true };
    case GET_PHARMACY_SEARCH_SUCCESS:
      return { ...state, pharmacySearch: action.payload, loading: false };
    case GET_PHARMACY_SEARCH_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default pharmacyAuthReducer;
