import {
  CREATE_LABTEST_CENTER_REQUEST,
  CREATE_LABTEST_CENTER_SUCCESS,
  CREATE_LABTEST_CENTER_FAIL,
  GET_PHARMACIES_REQUEST,
  GET_PHARMACIES_SUCCESS,
  GET_PHARMACIES_FAIL,
  GET_LABTEST_CENTER_DETAILS_REQUEST,
  GET_LABTEST_CENTER_DETAILS_SUCCESS,
  GET_LABTEST_CENTER_DETAILS_FAIL,
  DELETE_LABTEST_CENTER_REQUEST,
  DELETE_LABTEST_CENTER_SUCCESS,
  DELETE_LABTEST_CENTER_FAIL,
  UPDATE_LABTEST_CENTER_REQUEST,
  UPDATE_LABTEST_CENTER_SUCCESS,
  UPDATE_LABTEST_CENTER_FAIL,
  APPROVE_LABTEST_CENTER_REQUEST,
  APPROVE_LABTEST_CENTER_SUCCESS,
  APPROVE_LABTEST_CENTER_FAIL,
  CHANGE_STATUS_REQUEST,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAIL,
  GET_LABTEST_CENTER_TIME_REQUEST,
  GET_LABTEST_CENTER_TIME_SUCCESS,
  GET_LABTEST_CENTER_TIME_FAIL,
  SET_LABTEST_CENTER_TIME_REQUEST,
  SET_LABTEST_CENTER_TIME_SUCCESS,
  SET_LABTEST_CENTER_TIME_FAIL,
  GET_LABTEST_CENTER_PRESCRIPTIONS_REQUEST,
  GET_LABTEST_CENTER_PRESCRIPTIONS_SUCCESS,
  GET_LABTEST_CENTER_PRESCRIPTIONS_FAIL,
} from "../types/labtestCenterTypes";

const initialState = {
  labtestCenters: [],
  labtestCenterDetails: {},
  labtestCenterTimes: [],
  labtestCenterOrders: [],
  loading: false,
};

function adminlabtestCenterReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_LABTEST_CENTER_REQUEST:
      return { ...state, loading: true };
    case CREATE_LABTEST_CENTER_SUCCESS:
      return { ...state, labtestCenters: action.payload, loading: false };
    case CREATE_LABTEST_CENTER_FAIL:
      return { ...state, loading: false };

    case GET_PHARMACIES_REQUEST:
      return { ...state, loading: true };
    case GET_PHARMACIES_SUCCESS:
      return { ...state, labtestCenters: action.payload, loading: false };
    case GET_PHARMACIES_FAIL:
      return { ...state, loading: false };

    case GET_LABTEST_CENTER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_LABTEST_CENTER_DETAILS_SUCCESS:
      return { ...state, labtestCenterDetails: action.payload, loading: false };
    case GET_LABTEST_CENTER_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_LABTEST_CENTER_REQUEST:
      return { ...state, loading: true };
    case DELETE_LABTEST_CENTER_SUCCESS:
      return { ...state, labtestCenters: action.payload, loading: false };
    case DELETE_LABTEST_CENTER_FAIL:
      return { ...state, loading: false };

    case UPDATE_LABTEST_CENTER_REQUEST:
      return { ...state, loading: true };
    case UPDATE_LABTEST_CENTER_SUCCESS:
      return { ...state, labtestCenters: action.payload, loading: false };
    case UPDATE_LABTEST_CENTER_FAIL:
      return { ...state, loading: false };

    case APPROVE_LABTEST_CENTER_REQUEST:
      return { ...state, loading: true };
    case APPROVE_LABTEST_CENTER_SUCCESS:
      return { ...state, pharmacyProfile: action.payload, loading: false };
    case APPROVE_LABTEST_CENTER_FAIL:
      return { ...state, loading: false };

    case CHANGE_STATUS_REQUEST:
      return { ...state };
    case CHANGE_STATUS_SUCCESS:
      return { ...state, labtestCenters: action.payload };
    case CHANGE_STATUS_FAIL:
      return { ...state };

    case GET_LABTEST_CENTER_TIME_REQUEST:
      return { ...state, loading: true };
    case GET_LABTEST_CENTER_TIME_SUCCESS:
      return { ...state, labtestCenterTimes: action.payload, loading: false };
    case GET_LABTEST_CENTER_TIME_FAIL:
      return { ...state, loading: false };

    case SET_LABTEST_CENTER_TIME_REQUEST:
      return { ...state, loading: true };
    case SET_LABTEST_CENTER_TIME_SUCCESS:
      return { ...state, labtestCenterTimes: action.payload, loading: false };
    case SET_LABTEST_CENTER_TIME_FAIL:
      return { ...state, loading: false };

    case GET_LABTEST_CENTER_PRESCRIPTIONS_REQUEST:
      return { ...state, loading: true };
    case GET_LABTEST_CENTER_PRESCRIPTIONS_SUCCESS:
      return { ...state, labtestCenterOrders: action.payload, loading: false };
    case GET_LABTEST_CENTER_PRESCRIPTIONS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminlabtestCenterReducer;
