import pharmacyAuthReducer from "./authReducer";
import pharmacyProductsReducer from "./productsReducer";
import pharmacyPrescriptionReducer from "./prescriptionReducer";
import pharmacyOrderReducer from "./orderReducer";
import pharmacyPrescriptionRequestReducer from "./prescriptionRequestReducer";

const rootPharmacyReducers = {
  pharmacyAuth: pharmacyAuthReducer,
  pharmacyPrescription: pharmacyPrescriptionReducer,
  pharmacyPrescriptionRequest: pharmacyPrescriptionRequestReducer,
  pharmacyOrder: pharmacyOrderReducer,
  pharmacyProducts: pharmacyProductsReducer,
};

export default rootPharmacyReducers;
