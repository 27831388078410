import {
  GET_DOCTOR_CHAT_REQUEST,
  GET_DOCTOR_CHAT_SUCCESS,
  GET_DOCTOR_CHAT_FAIL,
} from "../types/chatTypes";

const initialValue = {
  chats: [],
  loading: false,
};

function doctorChatReducer(state = initialValue, action) {
  switch (action.type) {
    case GET_DOCTOR_CHAT_REQUEST:
      return { ...state, loading: !action?.user ? true : false };
    case GET_DOCTOR_CHAT_SUCCESS:
      return { ...state, loading: false, chats: action.payload };
    case GET_DOCTOR_CHAT_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default doctorChatReducer;
