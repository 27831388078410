export const REGISTER_LABTEST_REQUEST = "REGISTER_LABTEST_REQUEST";
export const REGISTER_LABTEST_SUCCESS = "REGISTER_LABTEST_SUCCESS";
export const REGISTER_LABTEST_FAIL = "REGISTER_LABTEST_FAIL";

export const LOGIN_LABTEST_REQUEST = "LOGIN_LABTEST_REQUEST";
export const LOGIN_LABTEST_SUCCESS = "LOGIN_LABTEST_SUCCESS";
export const LOGIN_LABTEST_FAIL = "LOGIN_LABTEST_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const VERIFIED_CODE_REQUEST = "VERIFIED_CODE_REQUEST";
export const VERIFIED_CODE_SUCCESS = "VERIFIED_CODE_SUCCESS";
export const VERIFIED_CODE_FAIL = "VERIFIED_CODE_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const EDIT_LABTEST_PROFILE_REQUEST = "EDIT_LABTEST_PROFILE_REQUEST";
export const EDIT_LABTEST_PROFILE_SUCCESS = "EDIT_LABTEST_PROFILE_SUCCESS";
export const EDIT_LABTEST_PROFILE_FAIL = "EDIT_LABTEST_PROFILE_FAIL";

export const EDIT_MY_PROFILE_REQUEST = "EDIT_MY_PROFILE_REQUEST";
export const EDIT_MY_PROFILE_SUCCESS = "EDIT_MY_PROFILE_SUCCESS";
export const EDIT_MY_PROFILE_FAIL = "EDIT_MY_PROFILE_FAIL";

export const GET_LABTEST_SEARCH_REQUEST = "GET_LABTEST_SEARCH_REQUEST";
export const GET_LABTEST_SEARCH_SUCCESS = "GET_LABTEST_SEARCH_SUCCESS";
export const GET_LABTEST_SEARCH_FAIL = "GET_LABTEST_SEARCH_FAIL";
