import adminAuthReducer from "./authReducer";
import adminAppointmentReducer from "./appointmentReducer";
import adminMedicalReducer from "./medicalReducer";
import adminPharmacyReducer from "./pharmacyReducer";
import adminDoctorReducer from "./doctorReducer";
import adminPatientReducer from "./patientReducer";
import adminSepecialReducer from "./sepecialReducer";
import adminCategoryReducer from "./categoryReducer";
import adminProductReducer from "./productReducer";
import adminSliderReducer from "./sliderReducer";
import adminMedicalTimeReducer from "./medicalTimeReducer";
import adminLabTestReducer from "./labTestReducer";
import adminFeelingReducer from "./feelingReducer";
import adminPrescriptionReducer from "./prescriptionReducer";
import adminAppointmentLabReducer from "./appointmentLabReducer";
import adminTopicReducer from "./topicReducer";
import adminQuestionReducer from "./questionReducer";
import adminArticleTypeReducer from "./article/articleTypeReducer";
import adminArticleReducer from "./article/articleReducer";
import adminlabtestCenterReducer from "./labtestCenterReducer";
import adminCategoryLabtestReducer from "./categoryLabtestReducer";

const rootAdminReducers = {
  admin: adminAuthReducer,
  adminMedical: adminMedicalReducer,
  adminPharmacy: adminPharmacyReducer,
  adminDoctors: adminDoctorReducer,
  adminPatients: adminPatientReducer,
  sepecialReducer: adminSepecialReducer,
  categoriesReducer: adminCategoryReducer,
  adminProducts: adminProductReducer,
  adminAppointment: adminAppointmentReducer,
  sliderReducer: adminSliderReducer,
  adminMedicalTime: adminMedicalTimeReducer,
  adminLabTest: adminLabTestReducer,
  adminFeeling: adminFeelingReducer,
  adminPrescription: adminPrescriptionReducer,
  adminAppointmentLab: adminAppointmentLabReducer,
  adminQuestions: adminQuestionReducer,
  adminTopics: adminTopicReducer,
  adminArticleType: adminArticleTypeReducer,
  adminArticle: adminArticleReducer,
  adminlabtestCenter: adminlabtestCenterReducer,
  adminCategoryLabtest: adminCategoryLabtestReducer,
};

export default rootAdminReducers;
