import {
  CREATE_FEELING_REQUEST,
  CREATE_FEELING_SUCCESS,
  CREATE_FEELING_FAIL,
  GET_FEELINGS_REQUEST,
  GET_FEELINGS_SUCCESS,
  GET_FEELINGS_FAIL,
  GET_FEELING_DETAILS_REQUEST,
  GET_FEELING_DETAILS_SUCCESS,
  GET_FEELING_DETAILS_FAIL,
  DELETE_FEELING_REQUEST,
  DELETE_FEELING_SUCCESS,
  DELETE_FEELING_FAIL,
  UPDATE_FEELING_REQUEST,
  UPDATE_FEELING_SUCCESS,
  UPDATE_FEELING_FAIL,
} from "../types/feelingsTypes";

const initialState = {
  feelings: [],
  feelingDetails: "",
  loading: false,
};

function adminFeelingReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_FEELING_REQUEST:
      return { ...state, loading: true };
    case CREATE_FEELING_SUCCESS:
      return { ...state, feelings: action.payload, loading: false };
    case CREATE_FEELING_FAIL:
      return { ...state, loading: false };

    case GET_FEELINGS_REQUEST:
      return { ...state, loading: true };
    case GET_FEELINGS_SUCCESS:
      return { ...state, feelings: action.payload, loading: false };
    case GET_FEELINGS_FAIL:
      return { ...state, loading: false };

    case GET_FEELING_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_FEELING_DETAILS_SUCCESS:
      return { ...state, feelingDetails: action.payload, loading: false };
    case GET_FEELING_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_FEELING_REQUEST:
      return { ...state, loading: true };
    case DELETE_FEELING_SUCCESS:
      return { ...state, feelings: action.payload, loading: false };
    case DELETE_FEELING_FAIL:
      return { ...state, loading: false };

    case UPDATE_FEELING_REQUEST:
      return { ...state, loading: true };
    case UPDATE_FEELING_SUCCESS:
      return { ...state, feelings: action.payload, loading: false };
    case UPDATE_FEELING_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminFeelingReducer;
