import {
  REGISTER_LABTEST_REQUEST,
  REGISTER_LABTEST_SUCCESS,
  REGISTER_LABTEST_FAIL,
  LOGIN_LABTEST_REQUEST,
  LOGIN_LABTEST_SUCCESS,
  LOGIN_LABTEST_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_LABTEST_SEARCH_REQUEST,
  GET_LABTEST_SEARCH_SUCCESS,
  GET_LABTEST_SEARCH_FAIL,
} from "../types/authTypes";
import { USER_LOGOUT_SUCCESS } from "../../common/types/commonTypes";

const initialState = {
  labtestData: JSON.parse(localStorage.getItem("user")) || {},
  labtestSearch: {},
  loading: false,
};

function labtestAuthReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGOUT_SUCCESS:
      return { ...state, labtestData: {} };

    case REGISTER_LABTEST_REQUEST:
      return { ...state, loading: true };
    case REGISTER_LABTEST_SUCCESS:
      return { ...state, labtestData: action.payload, loading: false };
    case REGISTER_LABTEST_FAIL:
      return { ...state, loading: false };

    case LOGIN_LABTEST_REQUEST:
      return { ...state, loading: true };
    case LOGIN_LABTEST_SUCCESS:
      return { ...state, labtestData: action.payload, loading: false };
    case LOGIN_LABTEST_FAIL:
      return { ...state, loading: false };

    case GET_PROFILE_REQUEST:
      return { ...state, loading: true };
    case GET_PROFILE_SUCCESS:
      return { ...state, labtestData: action.payload, loading: false };
    case GET_PROFILE_FAIL:
      return { ...state, loading: false };

    case GET_LABTEST_SEARCH_REQUEST:
      return { ...state, loading: true };
    case GET_LABTEST_SEARCH_SUCCESS:
      return { ...state, labtestSearch: action.payload, loading: false };
    case GET_LABTEST_SEARCH_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default labtestAuthReducer;
