import { useGetData } from "../../../hooks/api/useGetData";
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
} from "../types/notificationTypes";

export const getNotifications = (type, readable) => async (dispatch) => {
  try {
    dispatch({
      type: GET_NOTIFICATIONS_REQUEST,
    });

    const res = await useGetData(
      `/api/common/notifications?type=${type}&readable=${readable || 0}`
    );
    dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      payload: res?.data,
      readable,
    });
    console.log("Notifications", res);
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_NOTIFICATIONS_FAIL,
    });
  }
};
