import {
  CREATE_PRESCRIPTION_REQUEST,
  CREATE_PRESCRIPTION_SUCCESS,
  CREATE_PRESCRIPTION_FAIL,
  GET_PRESCRIPTIONS_REQUEST,
  GET_PRESCRIPTIONS_SUCCESS,
  GET_PRESCRIPTIONS_FAIL,
  GET_PRES_DETAILS_REQUEST,
  GET_PRES_DETAILS_SUCCESS,
  GET_PRES_DETAILS_FAIL,
  DELETE_PRESCRIPTION_REQUEST,
  DELETE_PRESCRIPTION_SUCCESS,
  DELETE_PRESCRIPTION_FAIL,
  UPDATE_PRESCRIPTION_REQUEST,
  UPDATE_PRESCRIPTION_SUCCESS,
  UPDATE_PRESCRIPTION_FAIL,
} from "../types/prescriptionTypes";
const initialValue = {
  prescriptions: [],
  presDetails: {},
  loading: false,
  actionLoading: false,
};

function doctorPrescriptionReducer(state = initialValue, action) {
  switch (action.type) {
    case CREATE_PRESCRIPTION_REQUEST:
      return { ...state, loading: true, actionLoading: true };
    case CREATE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        prescriptions: action.payload,
        loading: false,
        actionLoading: false,
      };
    case CREATE_PRESCRIPTION_FAIL:
      return { ...state, loading: false, actionLoading: false };

    case GET_PRESCRIPTIONS_REQUEST:
      return { ...state, loading: true };
    case GET_PRESCRIPTIONS_SUCCESS:
      return {
        ...state,
        prescriptions: action.payload,
        loading: false,
      };
    case GET_PRESCRIPTIONS_FAIL:
      return { ...state, loading: false };

    case GET_PRES_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_PRES_DETAILS_SUCCESS:
      return { ...state, presDetails: action.payload, loading: false };
    case GET_PRES_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_PRESCRIPTION_REQUEST:
      return { ...state, loading: true };
    case DELETE_PRESCRIPTION_SUCCESS:
      return { ...state, loading: false, prescriptions: action.payload };
    case DELETE_PRESCRIPTION_FAIL:
      return { ...state, loading: false };

    case UPDATE_PRESCRIPTION_REQUEST:
      return { ...state, loading: true };
    case UPDATE_PRESCRIPTION_SUCCESS:
      return { ...state, loading: false, prescriptions: action.payload };
    case UPDATE_PRESCRIPTION_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default doctorPrescriptionReducer;
