export const CREATE_TOPIC_REQUEST = "CREATE_TOPIC_REQUEST";
export const CREATE_TOPIC_SUCCESS = "CREATE_TOPIC_SUCCESS";
export const CREATE_TOPIC_FAIL = "CREATE_TOPIC_FAIL";

export const GET_TOPICS_REQUEST = "GET_TOPICS_REQUEST";
export const GET_TOPICS_SUCCESS = "GET_TOPICS_SUCCESS";
export const GET_TOPICS_FAIL = "GET_TOPICS_FAIL";

export const GET_TOPIC_DETAILS_REQUEST = "GET_TOPIC_DETAILS_REQUEST";
export const GET_TOPIC_DETAILS_SUCCESS = "GET_TOPIC_DETAILS_SUCCESS";
export const GET_TOPIC_DETAILS_FAIL = "GET_TOPIC_DETAILS_FAIL";

export const DELETE_TOPIC_REQUEST = "DELETE_TOPIC_REQUEST";
export const DELETE_TOPIC_SUCCESS = "DELETE_TOPIC_SUCCESS";
export const DELETE_TOPIC_FAIL = "DELETE_TOPIC_FAIL";

export const UPDATE_TOPIC_REQUEST = "UPDATE_TOPIC_REQUEST";
export const UPDATE_TOPIC_SUCCESS = "UPDATE_TOPIC_SUCCESS";
export const UPDATE_TOPIC_FAIL = "UPDATE_TOPIC_FAIL";
