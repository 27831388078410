import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { avatar02, avatar03, avatar05, avatar06 } from "../imagepath";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../../../redux/common/actions/notificationAction";
import { onMessageListener } from "../../../hooks/firebase/useGetToken";
import { notify } from "../../../hooks/notify/useNotification";

function Notifications() {
  const [alert, setAlert] = useState({ title: "", body: "", sectionType: "" });
  const { notifications } = useSelector((state) => state.notification);

  console.log("notifications", notifications);

  const dispatch = useDispatch();

  function ToastDisplay() {
    return (
      <Link
        to={`/pharmacyadmin/${alert?.sectionType}`}
        className="text-decoration-none"
      >
        <p className="text-dark">
          <strong>{alert?.title}</strong>
        </p>
        <p className="text-muted">{alert?.body}</p>
      </Link>
    );
  }

  const handleNotify = () => notify(<ToastDisplay />, "info");

  useEffect(() => {
    dispatch(getNotifications("labs"));
    if (alert?.title) {
      handleNotify();
    }
  }, [alert]);

  onMessageListener()
    .then((payload) => {
      setAlert({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
        sectionType: payload?.data?.sectionType,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <li className="nav-item dropdown noti-nav">
      <Link
        to="#"
        className="dropdown-toggle nav-link"
        data-bs-toggle="dropdown"
      >
        <FeatherIcon icon="bell" />
        <span className="badge" />
      </Link>
      <div className="dropdown-menu notifications">
        <div className="topnav-dropdown-header">
          <span className="notification-title">Notifications</span>
          <Link to="#" className="clear-noti">
            <FeatherIcon icon="vertical" />
            {/* <i className="feather-more-vertical" /> */}
          </Link>
        </div>
        <div className="noti-content">
          {notifications?.map((item) => {
            return (
              <React.Fragment key={item?.id}>
                <span className="menu-title">{item?.date}</span>
                <ul className="notification-list">
                  {item?.notifications?.map((item) => {
                    return (
                      <li className="notification-message" key={item?.id}>
                        <Link
                          to={`/pharmacyadmin/prescription-request/details/${item?.sectionId}`}
                        >
                          <div className="media d-flex">
                            <span className="avatar">
                              <img
                                className="avatar-img"
                                alt=""
                                src={avatar02}
                              />
                            </span>
                            <div className="media-body">
                              <h6>
                                {item?.title}{" "}
                                <span className="notification-time">
                                  {item?.readAt}
                                </span>
                              </h6>
                              <p className="noti-details">
                                {item?.body}{" "}
                                {/* <span className="noti-title">Dr.Ruby perin </span> */}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </li>
  );
}

export default Notifications;
