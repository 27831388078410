import {
  GET_TIME_REQUEST,
  GET_TIME_SUCCESS,
  GET_TIME_FAIL,
  SET_TIME_REQUEST,
  SET_TIME_SUCCESS,
  SET_TIME_FAIL,
} from "../types/workTimeTypes";

const initialValue = {
  workTime: [],
  loading: false,
};

function labtestWorkTimeReducer(state = initialValue, action) {
  switch (action.type) {
    case GET_TIME_REQUEST:
      return { ...state, loading: true };
    case GET_TIME_SUCCESS:
      return { ...state, workTime: action.payload, loading: false };
    case GET_TIME_FAIL:
      return { ...state, loading: false };

    case SET_TIME_REQUEST:
      return { ...state, loading: true };
    case SET_TIME_SUCCESS:
      return { ...state, workTime: action.payload, loading: false };
    case SET_TIME_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default labtestWorkTimeReducer;
