export const CREATE_LAB_TEST_REQUEST = "CREATE__LAB_TEST__REQUEST";
export const CREATE_LAB_TEST_SUCCESS = "CREATE__LAB_TEST__SUCCESS";
export const CREATE_LAB_TEST_FAIL = "CREATE__LAB_TEST__FAIL";

export const GET_LAB_TESTS_REQUEST = "GET__LAB_TEST__REQUEST";
export const GET_LAB_TESTS_SUCCESS = "GET__LAB_TEST__SUCCESS";
export const GET_LAB_TESTS_FAIL = "GET__LAB_TEST__FAIL";

export const GET_LAB_TEST_DETAILS_REQUEST = "GET__LAB_TEST__DETAILS_REQUEST";
export const GET_LAB_TEST_DETAILS_SUCCESS = "GET__LAB_TEST__DETAILS_SUCCESS";
export const GET_LAB_TEST_DETAILS_FAIL = "GET__LAB_TEST__DETAILS_FAIL";

export const DELETE_LAB_TEST_REQUEST = "DELETE__LAB_TEST__REQUEST";
export const DELETE_LAB_TEST_SUCCESS = "DELETE__LAB_TEST__SUCCESS";
export const DELETE_LAB_TEST_FAIL = "DELETE__LAB_TEST__FAIL";

export const UPDATE_LAB_TEST_REQUEST = "UPDATE__LAB_TEST__REQUEST";
export const UPDATE_LAB_TEST_SUCCESS = "UPDATE__LAB_TEST__SUCCESS";
export const UPDATE_LAB_TEST_FAIL = "UPDATE__LAB_TEST__FAIL";
