import patientRoutes from "./patientRoutes";
import AdminRoutes from "./AdminRoutes";
import MedicalRoutes from "./MedicalRoutes";
import DoctorRoute from "./DoctorRoute";
import PharnacyRoutes from "./PharnacyRoutes";
import LabtestRoutes from "./LabtestRoutes";

const RootRoutes = {
  routes: [
    ...DoctorRoute,
    ...patientRoutes,
    ...AdminRoutes,
    ...MedicalRoutes,
    ...PharnacyRoutes,
    ...LabtestRoutes,
  ],
};

export default RootRoutes;
