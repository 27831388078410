export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAIL = "GET_ORDER_DETAILS_FAIL";

export const CHANGE_ORDER_STATUS_REQUEST = "CHANGE_ORDER_STATUS_REQUEST";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";
export const CHANGE_ORDER_STATUS_FAIL = "CHANGE_ORDER_STATUS_FAIL";

export const CHECK_PRESCRIPTION_NUMBER_REQUEST =
  "CHECK_PRESCRIPTION_NUMBER_REQUEST";
export const CHECK_PRESCRIPTION_NUMBER_SUCCESS =
  "CHECK_PRESCRIPTION_NUMBER_SUCCESS";
export const CHECK_PRESCRIPTION_NUMBER_FAIL = "CHECK_PRESCRIPTION_NUMBER_FAIL";

export const CHECK_PEN_CODE_REQUEST = "CHECK_PEN_CODE_REQUEST";
export const CHECK_PEN_CODE_SUCCESS = "CHECK_PEN_CODE_SUCCESS";
export const CHECK_PEN_CODE_FAIL = "CHECK_PEN_CODE_FAIL";

export const SEND_TO_ORDER_REQUEST = "SEND_TO_ORDER_REQUEST";
export const SEND_TO_ORDER_SUCCESS = "SEND_TO_ORDER_SUCCESS";
export const SEND_TO_ORDER_FAIL = "SEND_TO_ORDER_FAIL";
