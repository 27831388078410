export const GET_MY_PROFILE_REQUEST = "GET_MY_PROFILE_REQUEST";
export const GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS";
export const GET_MY_PROFILE_FAIL = "GET_MY_PROFILE_FAIL";

export const MY_BASIC_INFO_REQUEST = "MY_BASIC_INFO_REQUEST";
export const MY_BASIC_INFO_SUCCESS = "MY_BASIC_INFO_SUCCESS";
export const MY_BASIC_INFO_FAIL = "MY_BASIC_INFO_FAIL";

export const MY_CLINIC_REQUEST = "MY_CLINIC_REQUEST";
export const MY_CLINIC_SUCCESS = "MY_CLINIC_SUCCESS";
export const MY_CLINIC_FAIL = "MY_CLINIC_FAIL";

export const MY_EDUCATION_REQUEST = "MY_EDUCATION_REQUEST";
export const MY_EDUCATION_SUCCESS = "MY_EDUCATION_SUCCESS";
export const MY_EDUCATION_FAIL = "MY_EDUCATION_FAIL";

export const MY_EXPERIENCE_REQUEST = "MY_EXPERIENCE_REQUEST";
export const MY_EXPERIENCE_SUCCESS = "MY_EXPERIENCE_SUCCESS";
export const MY_EXPERIENCE_FAIL = "MY_EXPERIENCE_FAIL";

export const MY_AWARDS_REQUEST = "MY_AWARDS_REQUEST";
export const MY_AWARDS_SUCCESS = "MY_AWARDS_SUCCESS";
export const MY_AWARDS_FAIL = "MY_AWARDS_FAIL";

export const MY_MEMBERSHIP_REQUEST = "MY_MEMBERSHIP_REQUEST";
export const MY_MEMBERSHIP_SUCCESS = "MY_MEMBERSHIP_SUCCESS";
export const MY_MEMBERSHIP_FAIL = "MY_MEMBERSHIP_FAIL";

export const MY_LANGUAGE_REQUEST = "MY_LANGUAGE_REQUEST";
export const MY_LANGUAGE_SUCCESS = "MY_LANGUAGE_SUCCESS";
export const MY_LANGUAGE_FAIL = "MY_LANGUAGE_FAIL";

export const SET_MY_WORK_TIME_REQUEST = "SET_MY_WORK_TIME_REQUEST";
export const SET_MY_WORK_TIME_SUCCESS = "SET_MY_WORK_TIME_SUCCESS";
export const SET_MY_WORK_TIME_FAIL = "SET_MY_WORK_TIME_FAIL";

export const GET_MY_WORK_TIME_REQUEST = "GET_MY_WORK_TIME_REQUEST";
export const GET_MY_WORK_TIME_SUCCESS = "GET_MY_WORK_TIME_SUCCESS";
export const GET_MY_WORK_TIME_FAIL = "GET_MY_WORK_TIME_FAIL";

export const SET_SOCIAL_MEDIA_REQUEST = "SET_SOCIAL_MEDIA_REQUEST";
export const SET_SOCIAL_MEDIA_SUCCESS = "SET_SOCIAL_MEDIA_SUCCESS";
export const SET_SOCIAL_MEDIA_FAIL = "SET_SOCIAL_MEDIA_FAIL";
