export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAIL = "CREATE_SERVICE_FAIL";

export const GET_SERVICES_REQUEST = "GET_SERVICES_REQUEST";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL";

export const GET_SPECIFIC_SERVICE_REQUEST = "GET_SPECIFIC_SERVICE_REQUEST";
export const GET_SPECIFIC_SERVICE_SUCCESS = "GET_SPECIFIC_SERVICE_SUCCESS";
export const GET_SPECIFIC_SERVICE_FAIL = "GET_SPECIFIC_SERVICE_FAIL";

export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL";

export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAIL = "UPDATE_SERVICE_FAIL";
