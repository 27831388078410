import {
  GET_MY_PROFILE_REQUEST,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_PROFILE_FAIL,
  MY_CLINIC_REQUEST,
  MY_CLINIC_SUCCESS,
  MY_CLINIC_FAIL,
  MY_EDUCATION_REQUEST,
  MY_EDUCATION_SUCCESS,
  MY_EDUCATION_FAIL,
  MY_EXPERIENCE_REQUEST,
  MY_EXPERIENCE_SUCCESS,
  MY_EXPERIENCE_FAIL,
  MY_AWARDS_REQUEST,
  MY_AWARDS_SUCCESS,
  MY_AWARDS_FAIL,
  MY_MEMBERSHIP_REQUEST,
  MY_MEMBERSHIP_SUCCESS,
  MY_MEMBERSHIP_FAIL,
  MY_LANGUAGE_REQUEST,
  MY_LANGUAGE_SUCCESS,
  MY_LANGUAGE_FAIL,
  SET_MY_WORK_TIME_REQUEST,
  SET_MY_WORK_TIME_SUCCESS,
  SET_MY_WORK_TIME_FAIL,
  GET_MY_WORK_TIME_REQUEST,
  GET_MY_WORK_TIME_SUCCESS,
  GET_MY_WORK_TIME_FAIL,
} from "../types/profileTypes";

const initialState = {
  myProfile: {},
  myWorkTime: [],
  loading: false,
  actionLoading: false,
};

function doctorProfileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MY_PROFILE_REQUEST:
      return { ...state, loading: true };
    case GET_MY_PROFILE_SUCCESS:
      return { ...state, myProfile: action.payload, loading: false };
    case GET_MY_PROFILE_FAIL:
      return { ...state, loading: false };

    case MY_CLINIC_REQUEST:
      return { ...state, actionLoading: true };
    case MY_CLINIC_SUCCESS:
      return { ...state, actionLoading: false };
    case MY_CLINIC_FAIL:
      return { ...state, actionLoading: false };

    case MY_EDUCATION_REQUEST:
      return { ...state, actionLoading: true };
    case MY_EDUCATION_SUCCESS:
      return { ...state, actionLoading: false };
    case MY_EDUCATION_FAIL:
      return { ...state, actionLoading: false };

    case MY_EXPERIENCE_REQUEST:
      return { ...state, actionLoading: true };
    case MY_EXPERIENCE_SUCCESS:
      return { ...state, actionLoading: false };
    case MY_EXPERIENCE_FAIL:
      return { ...state, actionLoading: false };

    case MY_AWARDS_REQUEST:
      return { ...state, actionLoading: true };
    case MY_AWARDS_SUCCESS:
      return { ...state, actionLoading: false };
    case MY_AWARDS_FAIL:
      return { ...state, actionLoading: false };

    case MY_MEMBERSHIP_REQUEST:
      return { ...state, actionLoading: true };
    case MY_MEMBERSHIP_SUCCESS:
      return { ...state, actionLoading: false };
    case MY_MEMBERSHIP_FAIL:
      return { ...state, actionLoading: false };

    case MY_LANGUAGE_REQUEST:
      return { ...state, actionLoading: true };
    case MY_LANGUAGE_SUCCESS:
      return { ...state, actionLoading: false };
    case MY_LANGUAGE_FAIL:
      return { ...state, actionLoading: false };

    case SET_MY_WORK_TIME_REQUEST:
      return { ...state, myWorkTime: action.payload, actionLoading: true };
    case SET_MY_WORK_TIME_SUCCESS:
      return { ...state, myWorkTime: action.payload, actionLoading: false };
    case SET_MY_WORK_TIME_FAIL:
      return { ...state, myWorkTime: action.payload, actionLoading: false };

    case GET_MY_WORK_TIME_REQUEST:
      return { ...state, myWorkTime: action.payload, loading: true };
    case GET_MY_WORK_TIME_SUCCESS:
      return { ...state, myWorkTime: action.payload, loading: false };
    case GET_MY_WORK_TIME_FAIL:
      return { ...state, myWorkTime: action.payload, loading: false };

    default:
      return state;
  }
}

export default doctorProfileReducer;
