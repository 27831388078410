export const CREATE_LABTEST_CENTER_REQUEST = "CREATE_LABTEST_CENTER_REQUEST";
export const CREATE_LABTEST_CENTER_SUCCESS = "CREATE_LABTEST_CENTER_SUCCESS";
export const CREATE_LABTEST_CENTER_FAIL = "CREATE_LABTEST_CENTER_FAIL";

export const GET_PHARMACIES_REQUEST = "GET_PHARMACIES_REQUEST";
export const GET_PHARMACIES_SUCCESS = "GET_PHARMACIES_SUCCESS";
export const GET_PHARMACIES_FAIL = "GET_PHARMACIES_FAIL";

export const GET_LABTEST_CENTER_DETAILS_REQUEST =
  "GET_LABTEST_CENTER_DETAILS_REQUEST";
export const GET_LABTEST_CENTER_DETAILS_SUCCESS =
  "GET_LABTEST_CENTER_DETAILS_SUCCESS";
export const GET_LABTEST_CENTER_DETAILS_FAIL =
  "GET_LABTEST_CENTER_DETAILS_FAIL";

export const DELETE_LABTEST_CENTER_REQUEST = "DELETE_LABTEST_CENTER_REQUEST";
export const DELETE_LABTEST_CENTER_SUCCESS = "DELETE_LABTEST_CENTER_SUCCESS";
export const DELETE_LABTEST_CENTER_FAIL = "DELETE_LABTEST_CENTER_FAIL";

export const UPDATE_LABTEST_CENTER_REQUEST = "UPDATE_LABTEST_CENTER_REQUEST";
export const UPDATE_LABTEST_CENTER_SUCCESS = "UPDATE_LABTEST_CENTER_SUCCESS";
export const UPDATE_LABTEST_CENTER_FAIL = "UPDATE_LABTEST_CENTER_FAIL";

export const APPROVE_LABTEST_CENTER_REQUEST = "APPROVE_LABTEST_CENTER_REQUEST";
export const APPROVE_LABTEST_CENTER_SUCCESS = "APPROVE_LABTEST_CENTER_SUCCESS";
export const APPROVE_LABTEST_CENTER_FAIL = "APPROVE_LABTEST_CENTER_FAIL";

export const CHANGE_STATUS_REQUEST = "CHANGE_STATUS_REQUEST";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_FAIL = "CHANGE_STATUS_FAIL";

export const GET_LABTEST_CENTER_TIME_REQUEST =
  "GET_LABTEST_CENTER_TIME_REQUEST";
export const GET_LABTEST_CENTER_TIME_SUCCESS =
  "GET_LABTEST_CENTER_TIME_SUCCESS";
export const GET_LABTEST_CENTER_TIME_FAIL = "GET_LABTEST_CENTER_TIME_FAIL";

export const SET_LABTEST_CENTER_TIME_REQUEST =
  "SET_LABTEST_CENTER_TIME_REQUEST";
export const SET_LABTEST_CENTER_TIME_SUCCESS =
  "SET_LABTEST_CENTER_TIME_SUCCESS";
export const SET_LABTEST_CENTER_TIME_FAIL = "SET_LABTEST_CENTER_TIME_FAIL";

export const GET_LABTEST_CENTER_PRESCRIPTIONS_REQUEST =
  "GET_LABTEST_CENTER_PRESCRIPTIONS_REQUEST";
export const GET_LABTEST_CENTER_PRESCRIPTIONS_SUCCESS =
  "GET_LABTEST_CENTER_PRESCRIPTIONS_SUCCESS";
export const GET_LABTEST_CENTER_PRESCRIPTIONS_FAIL =
  "GET_LABTEST_CENTER_PRESCRIPTIONS_FAIL";
