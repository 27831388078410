export const GET_PATIENTS_REQUEST = "GET_PATIENTS_REQUEST";
export const GET_PATIENTS_SUCCESS = "GET_PATIENTS_SUCCESS";
export const GET_PATIENTS_FAIL = "GET_PATIENTS_FAIL";

export const GET_PATIENT_DETAILS_REQUEST = "GET_PATIENT_DETAILS_REQUEST";
export const GET_PATIENT_DETAILS_SUCCESS = "GET_PATIENT_DETAILS_SUCCESS";
export const GET_PATIENT_DETAILS_FAIL = "GET_PATIENT_DETAILS_FAIL";

export const CHANGE_STATUS_REQUEST = "CHANGE_STATUS_REQUEST";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_FAIL = "CHANGE_STATUS_FAIL";

export const Get_PATIENT_PROFILE_REQUEST = "Get_PATIENT_PROFILE_REQUEST";
export const Get_PATIENT_PROFILE_SUCCESS = "Get_PATIENT_PROFILE_SUCCESS";
export const Get_PATIENT_PROFILE_FAIL = "Get_PATIENT_PROFILE_FAIL";

export const DELETE_PATIENT_REQUEST = "DELETE_PATIENT_REQUEST";
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS";
export const DELETE_PATIENT_FAIL = "DELETE_PATIENT_FAIL";
