export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL";

export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

export const PRODUCTS_SEARCH_REQUEST = "PRODUCTS_SEARCH_REQUEST";
export const PRODUCTS_SEARCH_SUCCESS = "PRODUCTS_SEARCH_SUCCESS";
export const PRODUCTS_SEARCH_FAIL = "PRODUCTS_SEARCH_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_LIKE_REQUEST = "PRODUCT_LIKE_REQUEST";
export const PRODUCT_LIKE_SUCCESS = "PRODUCT_LIKE_SUCCESS";
export const PRODUCT_LIKE_FAIL = "PRODUCT_LIKE_FAIL";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const PRODUCTS_BY_CATEGORY_REQUEST = "PRODUCTS_BY_CATEGORY_REQUEST";
export const PRODUCTS_BY_CATEGORY_SUCCESS = "PRODUCTS_BY_CATEGORY_SUCCESS";
export const PRODUCTS_BY_CATEGORY_FAIL = "PRODUCTS_BY_CATEGORY_FAIL";

export const PRODUCTS_BY_BRAND_REQUEST = "PRODUCTS_BY_BRAND_REQUEST";
export const PRODUCTS_BY_BRAND_SUCCESS = "PRODUCTS_BY_BRAND_SUCCESS";
export const PRODUCTS_BY_BRAND_FAIL = "PRODUCTS_BY_BRAND_FAIL";
