import {
  CREATE_MEDICAL_REQUEST,
  CREATE_MEDICAL_SUCCESS,
  CREATE_MEDICAL_FAIL,
  GET_MEDICALS_REQUEST,
  GET_MEDICALS_SUCCESS,
  GET_MEDICALS_FAIL,
  GET_SPECIFIC_MEDICAL_REQUEST,
  GET_SPECIFIC_MEDICAL_SUCCESS,
  GET_SPECIFIC_MEDICAL_FAIL,
  DELETE_MEDICAL_REQUEST,
  DELETE_MEDICAL_SUCCESS,
  DELETE_MEDICAL_FAIL,
  UPDATE_MEDICAL_REQUEST,
  UPDATE_MEDICAL_SUCCESS,
  UPDATE_MEDICAL_FAIL,
  APPROVE_MEDICAL_REQUEST,
  APPROVE_MEDICAL_SUCCESS,
  APPROVE_MEDICAL_FAIL,
  CHANGE_STATUS_REQUEST,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAIL,
} from "../types/medicalTypes";

const initialState = {
  medicals: [],
  medDetails: "",
  loading: false,
};

function adminMedicalReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MEDICAL_REQUEST:
      return { ...state, loading: true };
    case CREATE_MEDICAL_SUCCESS:
      return { ...state, medicals: action.payload, loading: false };
    case CREATE_MEDICAL_FAIL:
      return { ...state, loading: false };

    case GET_MEDICALS_REQUEST:
      return { ...state, loading: true };
    case GET_MEDICALS_SUCCESS:
      return { ...state, medicals: action.payload, loading: false };
    case GET_MEDICALS_FAIL:
      return { ...state, loading: false };

    case GET_SPECIFIC_MEDICAL_REQUEST:
      return { ...state, loading: true };
    case GET_SPECIFIC_MEDICAL_SUCCESS:
      return { ...state, medDetails: action.payload, loading: false };
    case GET_SPECIFIC_MEDICAL_FAIL:
      return { ...state, loading: false };

    case DELETE_MEDICAL_REQUEST:
      return { ...state, loading: true };
    case DELETE_MEDICAL_SUCCESS:
      return { ...state, medicals: action.payload, loading: false };
    case DELETE_MEDICAL_FAIL:
      return { ...state, loading: false };

    case UPDATE_MEDICAL_REQUEST:
      return { ...state, loading: true };
    case UPDATE_MEDICAL_SUCCESS:
      return { ...state, medicals: action.payload, loading: false };
    case UPDATE_MEDICAL_FAIL:
      return { ...state, loading: false };

    case APPROVE_MEDICAL_REQUEST:
      return { ...state };
    case APPROVE_MEDICAL_SUCCESS:
      return { ...state, medDetails: action.payload };
    case APPROVE_MEDICAL_FAIL:
      return { ...state };

    case CHANGE_STATUS_REQUEST:
      return { ...state };
    case CHANGE_STATUS_SUCCESS:
      return { ...state, medDetails: action.payload };
    case CHANGE_STATUS_FAIL:
      return { ...state };

    default:
      return state;
  }
}

export default adminMedicalReducer;
