import {
  GET_PATIENTS_REQUEST,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
  GET_PATIENT_DETAILS_REQUEST,
  GET_PATIENT_DETAILS_SUCCESS,
  GET_PATIENT_DETAILS_FAIL,
  Get_PATIENT_PROFILE_REQUEST,
  Get_PATIENT_PROFILE_SUCCESS,
  Get_PATIENT_PROFILE_FAIL,
  CHANGE_STATUS_REQUEST,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAIL,
  DELETE_PATIENT_REQUEST,
  DELETE_PATIENT_SUCCESS,
  DELETE_PATIENT_FAIL,
} from "../types/patientTypes";

const initialState = {
  patients: {},
  patientProfile: {},
  patientDetails: "",
  loading: false,
};

function adminPatientReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PATIENTS_REQUEST:
      return { ...state, loading: true };
    case GET_PATIENTS_SUCCESS:
      return { ...state, patients: action.payload, loading: false };
    case GET_PATIENTS_FAIL:
      return { ...state, loading: false };

    case Get_PATIENT_PROFILE_REQUEST:
      return { ...state, loading: true };
    case Get_PATIENT_PROFILE_SUCCESS:
      return { ...state, patientProfile: action.payload, loading: false };
    case Get_PATIENT_PROFILE_FAIL:
      return { ...state, loading: false };

    case GET_PATIENT_DETAILS_REQUEST:
      return { ...state };
    case GET_PATIENT_DETAILS_SUCCESS:
      return { ...state, patientDetails: action.payload };
    case GET_PATIENT_DETAILS_FAIL:
      return { ...state };

    case CHANGE_STATUS_REQUEST:
      return { ...state };
    case CHANGE_STATUS_SUCCESS:
      return { ...state, patientProfile: action.payload };
    case CHANGE_STATUS_FAIL:
      return { ...state };

    case DELETE_PATIENT_REQUEST:
      return { ...state, loading: true };
    case DELETE_PATIENT_SUCCESS:
      return { ...state, patients: action.payload, loading: false };
    case DELETE_PATIENT_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminPatientReducer;
