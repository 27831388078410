export const CREATE_ARTICLE_TYPE_REQUEST = "CREATE_ARTICLE_TYPE_REQUEST";
export const CREATE_ARTICLE_TYPE_SUCCESS = "CREATE_ARTICLE_TYPE_SUCCESS";
export const CREATE_ARTICLE_TYPE_FAIL = "CREATE_ARTICLE_TYPE_FAIL";

export const GET_ARTICLE_TYPES_REQUEST = "GET_ARTICLE_TYPES_REQUEST";
export const GET_ARTICLE_TYPES_SUCCESS = "GET_ARTICLE_TYPES_SUCCESS";
export const GET_ARTICLE_TYPES_FAIL = "GET_ARTICLE_TYPES_FAIL";

export const GET_ARTICLE_TYPE_DETAILS_REQUEST =
  "GET_ARTICLE_TYPE_DETAILS_REQUEST";
export const GET_ARTICLE_TYPE_DETAILS_SUCCESS =
  "GET_ARTICLE_TYPE_DETAILS_SUCCESS";
export const GET_ARTICLE_TYPE_DETAILS_FAIL = "GET_ARTICLE_TYPE_DETAILS_FAIL";

export const DELETE_ARTICLE_TYPE_REQUEST = "DELETE_ARTICLE_TYPE_REQUEST";
export const DELETE_ARTICLE_TYPE_SUCCESS = "DELETE_ARTICLE_TYPE_SUCCESS";
export const DELETE_ARTICLE_TYPE_FAIL = "DELETE_ARTICLE_TYPE_FAIL";

export const UPDATE_ARTICLE_TYPE_REQUEST = "UPDATE_ARTICLE_TYPE_REQUEST";
export const UPDATE_ARTICLE_TYPE_SUCCESS = "UPDATE_ARTICLE_TYPE_SUCCESS";
export const UPDATE_ARTICLE_TYPE_FAIL = "UPDATE_ARTICLE_TYPE_FAIL";
