export const CREATE_MEDICAL_REQUEST = "CREATE_MEDICAL_REQUEST";
export const CREATE_MEDICAL_SUCCESS = "CREATE_MEDICAL_SUCCESS";
export const CREATE_MEDICAL_FAIL = "CREATE_MEDICAL_FAIL";

export const GET_MEDICALS_REQUEST = "GET_MEDICALS_REQUEST";
export const GET_MEDICALS_SUCCESS = "GET_MEDICALS_SUCCESS";
export const GET_MEDICALS_FAIL = "GET_MEDICALS_FAIL";

export const GET_SPECIFIC_MEDICAL_REQUEST = "GET_SPECIFIC_MEDICAL_REQUEST";
export const GET_SPECIFIC_MEDICAL_SUCCESS = "GET_SPECIFIC_MEDICAL_SUCCESS";
export const GET_SPECIFIC_MEDICAL_FAIL = "GET_SPECIFIC_MEDICAL_FAIL";

export const DELETE_MEDICAL_REQUEST = "DELETE_MEDICAL_REQUEST";
export const DELETE_MEDICAL_SUCCESS = "DELETE_MEDICAL_SUCCESS";
export const DELETE_MEDICAL_FAIL = "DELETE_MEDICAL_FAIL";

export const UPDATE_MEDICAL_REQUEST = "UPDATE_MEDICAL_REQUEST";
export const UPDATE_MEDICAL_SUCCESS = "UPDATE_MEDICAL_SUCCESS";
export const UPDATE_MEDICAL_FAIL = "UPDATE_MEDICAL_FAIL";

export const APPROVE_MEDICAL_REQUEST = "APPROVE_MEDICAL_REQUEST";
export const APPROVE_MEDICAL_SUCCESS = "APPROVE_MEDICAL_SUCCESS";
export const APPROVE_MEDICAL_FAIL = "APPROVE_MEDICAL_FAIL";

export const CHANGE_STATUS_REQUEST = "CHANGE_STATUS_REQUEST";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_FAIL = "CHANGE_STATUS_FAIL";
