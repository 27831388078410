import baseURL from "../../api/baseURL";

// api hook to insert normla data
export const useInsertData = async (url, params) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("user"))?.token
      }`,
    },
  };
  const res = await baseURL.post(url, params, config);
  return res.data;
};

// api hook to insert data with image
export const useInsertDataWithImage = async (url, params) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("user"))?.token
      }`,
    },
  };
  const res = await baseURL.post(url, params, config);
  return res.data;
};
