import {
  GET_APPOINTMENTS_REQUEST,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_FAIL,
  GET_APPOINT_DETAILS_REQUEST,
  GET_APPOINT_DETAILS_SUCCESS,
  GET_APPOINT_DETAILS_FAIL,
  GET_COMPLETED_APPOINTMENTS_REQUEST,
  GET_COMPLETED_APPOINTMENTS_SUCCESS,
  GET_COMPLETED_APPOINTMENTS_FAIL,
  MEDICAL_CHANGE_APPOINTMENT_REQUEST,
  MEDICAL_CHANGE_APPOINTMENT_SUCCESS,
  MEDICAL_CHANGE_APPOINTMENT_FAIL,
  DOCTOR_CHANGE_APPOINTMENT_REQUEST,
  DOCTOR_CHANGE_APPOINTMENT_SUCCESS,
  DOCTOR_CHANGE_APPOINTMENT_FAIL,
  ADD_APPOINT_DESC_REQUEST,
  ADD_APPOINT_DESC_SUCCESS,
  ADD_APPOINT_DESC_FAIL,
} from "../types/appointmentTypes";

const initialState = {
  appointments: [],
  completedAppointments: [],
  appointDetails: {},
  loading: false,
  actionLoading: false,
};

function appointmentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_APPOINTMENTS_REQUEST:
      return { ...state, loading: true };
    case GET_APPOINTMENTS_SUCCESS:
      return { ...state, appointments: action.payload, loading: false };
    case GET_APPOINTMENTS_FAIL:
      return { ...state, loading: false };

    case GET_COMPLETED_APPOINTMENTS_REQUEST:
      return { ...state, loading: true };
    case GET_COMPLETED_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        completedAppointments: action.payload,
        loading: false,
      };
    case GET_COMPLETED_APPOINTMENTS_FAIL:
      return { ...state, loading: false };

    case GET_APPOINT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_APPOINT_DETAILS_SUCCESS:
      return { ...state, appointDetails: action.payload, loading: false };
    case GET_APPOINT_DETAILS_FAIL:
      return { ...state, loading: false };

    case MEDICAL_CHANGE_APPOINTMENT_REQUEST:
      return { ...state, actionLoading: true };
    case MEDICAL_CHANGE_APPOINTMENT_SUCCESS:
      return { ...state, actionLoading: false };
    case MEDICAL_CHANGE_APPOINTMENT_FAIL:
      return { ...state, actionLoading: false };

    case DOCTOR_CHANGE_APPOINTMENT_REQUEST:
      return { ...state, actionLoading: true };
    case DOCTOR_CHANGE_APPOINTMENT_SUCCESS:
      return { ...state, actionLoading: false };
    case DOCTOR_CHANGE_APPOINTMENT_FAIL:
      return { ...state, actionLoading: false };

    case ADD_APPOINT_DESC_REQUEST:
      return { ...state, actionLoading: true };
    case ADD_APPOINT_DESC_SUCCESS:
      return { ...state, actionLoading: false };
    case ADD_APPOINT_DESC_FAIL:
      return { ...state, actionLoading: false };

    default:
      return state;
  }
}

export default appointmentReducer;
