export const CREATE_GALLERY_REQUEST = "CREATE_GALLERY_REQUEST";
export const CREATE_GALLERY_SUCCESS = "CREATE_GALLERY_SUCCESS";
export const CREATE_GALLERY_FAIL = "CREATE_GALLERY_FAIL";

export const GET_GALLERIES_REQUEST = "GET_GALLERIES_REQUEST";
export const GET_GALLERIES_SUCCESS = "GET_GALLERIES_SUCCESS";
export const GET_GALLERIES_FAIL = "GET_GALLERIES_FAIL";

export const GET_SPECIFIC_GALLERY_REQUEST = "GET_SPECIFIC_GALLERY_REQUEST";
export const GET_SPECIFIC_GALLERY_SUCCESS = "GET_SPECIFIC_GALLERY_SUCCESS";
export const GET_SPECIFIC_GALLERY_FAIL = "GET_SPECIFIC_GALLERY_FAIL";

export const DELETE_GALLERY_REQUEST = "DELETE_GALLERY_REQUEST";
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS";
export const DELETE_GALLERY_FAIL = "DELETE_GALLERY_FAIL";

export const UPDATE_GALLERY_REQUEST = "UPDATE_GALLERY_REQUEST";
export const UPDATE_GALLERY_SUCCESS = "UPDATE_GALLERY_SUCCESS";
export const UPDATE_GALLERY_FAIL = "UPDATE_GALLERY_FAIL";
