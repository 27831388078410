import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";
// font awesome
import "./assets/css/all.min.css";
// toastify
import "react-toastify/dist/ReactToastify.css";
// lightbox
import "react-image-lightbox/style.css";
// owl.carousel
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// datepicker
// import "react-datepicker/dist/react-datepicker.css";
// skeleton loading
import "react-loading-skeleton/dist/skeleton.css";
import "react-image-lightbox/style.css";

const loder = () => {
  if (
    window.location.pathname.includes("admin") ||
    window.location.pathname.includes("labtest")
    // window.location.pathname.includes("pharmacyAdmin")
  ) {
    import("font-awesome/css/font-awesome.min.css");
    import("./assets/admin/css/bootstrap.min.css");
    import("./assets/admin/css/font-awesome.min.css");
    import("./assets/admin/css/feathericon.min.css");
    import("./assets/admin/css/adminStyle.css");
  } else {
    import("bootstrap/dist/css/bootstrap.min.css");
    import("bootstrap/dist/js/bootstrap.bundle.min");
    import("./assets/css/fontawesome.min.css");
    import("./assets/css/style.css");
  }
};

const AppLoder = () => {
  useEffect(() => {
    loder();
  }, []);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense>
          <App />
          <ToastContainer />
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppLoder />);
