export const CREATE_QUESTION_REQUEST = "CREATE_QUESTION_REQUEST";
export const CREATE_QUESTION_SUCCESS = "CREATE_QUESTION_SUCCESS";
export const CREATE_QUESTION_FAIL = "CREATE_QUESTION_FAIL";

export const GET_QUESTIONS_REQUEST = "GET_QUESTIONS_REQUEST";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAIL = "GET_QUESTIONS_FAIL";

export const GET_QUESTION_DETAILS_REQUEST = "GET_QUESTION_DETAILS_REQUEST";
export const GET_QUESTION_DETAILS_SUCCESS = "GET_QUESTION_DETAILS_SUCCESS";
export const GET_QUESTION_DETAILS_FAIL = "GET_QUESTION_DETAILS_FAIL";

export const DELETE_QUESTION_REQUEST = "DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAIL = "DELETE_QUESTION_FAIL";

export const UPDATE_QUESTION_REQUEST = "UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_FAIL = "UPDATE_QUESTION_FAIL";
