export const REGISTER_MEDICAL_REQUEST = "REGISTER_MEDICAL_REQUEST";
export const REGISTER_MEDICAL_SUCCESS = "REGISTER_MEDICAL_SUCCESS";
export const REGISTER_MEDICAL_FAIL = "REGISTER_MEDICAL_FAIL";

export const LOGIN_MEDICAL_REQUEST = "LOGIN_MEDICAL_REQUEST";
export const LOGIN_MEDICAL_SUCCESS = "LOGIN_MEDICAL_SUCCESS";
export const LOGIN_MEDICAL_FAIL = "LOGIN_MEDICAL_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const VERIFIED_CODE_REQUEST = "VERIFIED_CODE_REQUEST";
export const VERIFIED_CODE_SUCCESS = "VERIFIED_CODE_SUCCESS";
export const VERIFIED_CODE_FAIL = "VERIFIED_CODE_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const EDIT_MEDICAL_PROFILE_REQUEST = "EDIT_MEDICAL_PROFILE_REQUEST";
export const EDIT_MEDICAL_PROFILE_SUCCESS = "EDIT_MEDICAL_PROFILE_SUCCESS";
export const EDIT_MEDICAL_PROFILE_FAIL = "EDIT_MEDICAL_PROFILE_FAIL";

export const EDIT_MY_PROFILE_REQUEST = "EDIT_MY_PROFILE_REQUEST";
export const EDIT_MY_PROFILE_SUCCESS = "EDIT_MY_PROFILE_SUCCESS";
export const EDIT_MY_PROFILE_FAIL = "EDIT_MY_PROFILE_FAIL";

export const GET_MEDICAL_DASHBOARD_REQUEST = "GET_MEDICAL_DASHBOARD_REQUEST";
export const GET_MEDICAL_DASHBOARD_SUCCESS = "GET_MEDICAL_DASHBOARD_SUCCESS";
export const GET_MEDICAL_DASHBOARD_FAIL = "GET_MEDICAL_DASHBOARD_FAIL";

export const GET_MEDICAL_SEARCH_REQUEST = "GET_MEDICAL_SEARCH_REQUEST";
export const GET_MEDICAL_SEARCH_SUCCESS = "GET_MEDICAL_SEARCH_SUCCESS";
export const GET_MEDICAL_SEARCH_FAIL = "GET_MEDICAL_SEARCH_FAIL";

export const GET_ARTICLES_REQUEST = "GET_ARTICLES_REQUEST";
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS";
export const GET_ARTICLES_FAIL = "GET_ARTICLES_FAIL";
