import doctorAuthReducer from "./authReducer";
import doctorAppointmentReducer from "./appointmentReducer";
import doctorPatientReducer from "./patientReducer";
import doctorPrescriptionReducer from "./prescriptionReducer";
import doctorLabTestReducer from "./labTestReducer";
import doctorArticleReducer from "./articleReducer";
import doctorSocialMediaReducer from "./socialMediaReducer";
import doctorReviewReducer from "./reviewReducer";
import doctorChatReducer from "./chatReducer";
import doctorBillingReducer from "./billingReducer";
import doctorProfileReducer from "./ProfileReducer";
import doctorGalleryReducer from "./galleryReducer";

const rootDoctorReducers = {
  doctorAuth: doctorAuthReducer,
  doctorAppointment: doctorAppointmentReducer,
  doctorPatient: doctorPatientReducer,
  doctorPrescription: doctorPrescriptionReducer,
  doctorLabTest: doctorLabTestReducer,
  doctorArticle: doctorArticleReducer,
  doctorSocialMedia: doctorSocialMediaReducer,
  doctorReview: doctorReviewReducer,
  doctorChat: doctorChatReducer,
  doctorBilling: doctorBillingReducer,
  doctorProfile: doctorProfileReducer,
  doctorGallery: doctorGalleryReducer,
};

export default rootDoctorReducers;
