import React, { lazy } from "react";
import Header from "../client/components/header";
import { RequireDoctor } from "./RequireRoutes";
import Backbtn from "../client/components/backbtn";

const Login = lazy(() => import("../client/components/doctors/login"));
const DoctorForgotPassword = lazy(() =>
  import("../client/components/doctors/forgot-password")
);
const DoctorVerifiedCode = lazy(() =>
  import("../client/components/doctors/forgot-password/VerifiedCode")
);
const DoctorChangePassword = lazy(() =>
  import("../client/components/doctors/forgot-password/ChangePassword")
);
const Dashboard = lazy(() => import("../client/components/doctors/dashboard"));
const ProfileSetting = lazy(() =>
  import("../client/components/doctors/profilesetting")
);
const Appointments = lazy(() =>
  import("../client/components/doctors/appointments")
);
const MyPatients = lazy(() =>
  import("../client/components/doctors/mypatients")
);
const ScheduleTimings = lazy(() =>
  import("../client/components/doctors/scheduletimings")
);
const AvailableTiming = lazy(() =>
  import("../client/components/doctors/availabletimings")
);
const Invoice = lazy(() =>
  import("../client/components/doctors/invoices/invoices")
);
const InvoiceView = lazy(() =>
  import("../client/components/doctors/invoices/view")
);
const Reviews = lazy(() => import("../client/components/doctors/reviews"));
const SocialMedia = lazy(() =>
  import("../client/components/doctors/socialmedia")
);
const DoctorChangePasswword = lazy(() =>
  import("../client/components/doctors/password")
);

const PatientProfile = lazy(() =>
  import("../client/components/doctors/patientprofile")
);
const AppointmentDetails = lazy(() =>
  import("../client/components/doctors/appointments/AppointmentDetails")
);
const AddPescription = lazy(() =>
  import("../client/components/doctors/pescription/AddPrescription")
);
const AddBilling = lazy(() =>
  import("../client/components/doctors/invoices/AddBilling")
);
const EditPescription = lazy(() =>
  import("../client/components/doctors/pescription/EditPrescription")
);
const PrescriptionDetails = lazy(() =>
  import("../client/components/doctors/pescription/PrescriptionDetails")
);
const LabTestDetails = lazy(() =>
  import("../client/components/doctors/labtest/LabTestDetails")
);
const EditLabTest = lazy(() =>
  import("../client/components/doctors/labtest/EditLabTest")
);
const DoctorChat = lazy(() => import("../client/components/doctors/chat"));
const Articles = lazy(() => import("../client/components/doctors/article"));
const Galleries = lazy(() => import("../client/components/doctors/galleries"));

const DoctorRoute = [
  {
    path: "/doctor",
    component: (
      <RequireDoctor>
        <Header />
        <Dashboard />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/login",
    component: <Login />,
  },
  {
    path: "/doctor/forgot-password",
    component: <DoctorForgotPassword />,
  },
  {
    path: "/doctor/verified-code",
    component: <DoctorVerifiedCode />,
  },
  {
    path: "/doctor/change-password",
    component: <DoctorChangePassword />,
  },
  {
    path: "/doctor/appointments",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <Appointments />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/profile-setting",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <ProfileSetting />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/my-patients",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <MyPatients />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/schedule-timings",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <ScheduleTimings />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/available-timing",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <AvailableTiming />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/invoice",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <Invoice />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/invoice-view/:id",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <InvoiceView />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/reviews",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <Reviews />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/doctor-change-passwword",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <DoctorChangePasswword />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/social-media",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <SocialMedia />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/patient-profile/:id",
    component: (
      <RequireDoctor>
        <PatientProfile />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/appointments/:id",
    component: (
      <RequireDoctor>
        <Header />
        <AppointmentDetails />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/patient/add-prescription/:id",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <AddPescription />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/patient/edit-prescription/:id",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <EditPescription />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/prescription-details/:id",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <PrescriptionDetails />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/patient/lab-test-details/:id",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <LabTestDetails />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/patient/edit-lab-test/:id",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <EditLabTest />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/patient/add-billing/:id",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <AddBilling />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/articles",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <Articles />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/galleries",
    component: (
      <RequireDoctor>
        <Header />
        <Backbtn />
        <Galleries />
      </RequireDoctor>
    ),
  },
  {
    path: "/doctor/chat/:id?",
    component: (
      <RequireDoctor>
        <Header />
        <DoctorChat />
      </RequireDoctor>
    ),
  },
];

export default DoctorRoute;
