export const REGISTER_ADMIN_REQUEST = "REGISTER_ADMIN_REQUEST";
export const REGISTER_ADMIN_SUCCESS = "REGISTER_ADMIN_SUCCESS";
export const REGISTER_ADMIN_FAIL = "REGISTER_ADMIN_FAIL";

export const LOGIN_ADMIN_REQUEST = "LOGIN_ADMIN_REQUEST";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAIL = "LOGIN_ADMIN_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const VERIFIED_CODE_REQUEST = "VERIFIED_CODE_REQUEST";
export const VERIFIED_CODE_SUCCESS = "VERIFIED_CODE_SUCCESS";
export const VERIFIED_CODE_FAIL = "VERIFIED_CODE_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const GET_ADMIN_DASHBOARD_REQUEST = "GET_ADMIN_DASHBOARD_REQUEST";
export const GET_ADMIN_DASHBOARD_SUCCESS = "GET_ADMIN_DASHBOARD_SUCCESS";
export const GET_ADMIN_DASHBOARD_FAIL = "GET_ADMIN_DASHBOARD_FAIL";
