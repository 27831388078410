import {
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAIL,
  SET_REVIEWS_REQUEST,
  SET_REVIEWS_SUCCESS,
  SET_REVIEWS_FAIL,
} from "../types/reviewsTypes";

const initialValue = {
  reviews: [],
  loading: false,
};

function doctorReviewReducer(state = initialValue, action) {
  switch (action.type) {
    case GET_REVIEWS_REQUEST:
      return { ...state, loading: true };
    case GET_REVIEWS_SUCCESS:
      return { ...state, loading: false, reviews: action.payload };
    case GET_REVIEWS_FAIL:
      return { ...state, loading: false };

    case SET_REVIEWS_REQUEST:
      return { ...state, loading: true };
    case SET_REVIEWS_SUCCESS:
      return { ...state, loading: false, reviews: action.payload };
    case SET_REVIEWS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default doctorReviewReducer;
