import medicalAuthReducer from "./authReducer";
import medicalDepartmentReducer from "./departmentReducer";
import medicalServiceReducer from "./serviceReducer";
import medicalDoctorReducer from "./doctorReducer";
import medicalPatientReducer from "./patientReducer";
import medicalPrescriptionReducer from "./prescriptionReducer";
import medicalLabTestReducer from "./labTestReducer";
import medicalBillingReducer from "./billingReducer";
import medicalSocialMediaReducer from "./socialMediaReducer";

const rootMedicalReducers = {
  medicalAuth: medicalAuthReducer,
  departmentReducer: medicalDepartmentReducer,
  serviceReducer: medicalServiceReducer,
  medicalDoctor: medicalDoctorReducer,
  medicalPatient: medicalPatientReducer,
  medicalPrescription: medicalPrescriptionReducer,
  medicalLabTest: medicalLabTestReducer,
  medicalBilling: medicalBillingReducer,
  medicalSocialMedia: medicalSocialMediaReducer,
};

export default rootMedicalReducers;
