import {
  CREATE_PHARMACY_REQUEST,
  CREATE_PHARMACY_SUCCESS,
  CREATE_PHARMACY_FAIL,
  GET_PHARMACIES_REQUEST,
  GET_PHARMACIES_SUCCESS,
  GET_PHARMACIES_FAIL,
  GET_PHARMACY_DETAILS_REQUEST,
  GET_PHARMACY_DETAILS_SUCCESS,
  GET_PHARMACY_DETAILS_FAIL,
  DELETE_PHARMACY_REQUEST,
  DELETE_PHARMACY_SUCCESS,
  DELETE_PHARMACY_FAIL,
  UPDATE_PHARMACY_REQUEST,
  UPDATE_PHARMACY_SUCCESS,
  UPDATE_PHARMACY_FAIL,
  APPROVE_PHARMACY_REQUEST,
  APPROVE_PHARMACY_SUCCESS,
  APPROVE_PHARMACY_FAIL,
  CHANGE_STATUS_REQUEST,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAIL,
  GET_PHARMACY_TIME_REQUEST,
  GET_PHARMACY_TIME_SUCCESS,
  GET_PHARMACY_TIME_FAIL,
  SET_PHARMACY_TIME_REQUEST,
  SET_PHARMACY_TIME_SUCCESS,
  SET_PHARMACY_TIME_FAIL,
  GET_PHARMACY_PRESCRIPTIONS_REQUEST,
  GET_PHARMACY_PRESCRIPTIONS_SUCCESS,
  GET_PHARMACY_PRESCRIPTIONS_FAIL,
  GET_PHARMACY_PRESCRIPTION_DETAILS_REQUEST,
  GET_PHARMACY_PRESCRIPTION_DETAILS_SUCCESS,
  GET_PHARMACY_PRESCRIPTION_DETAILS_FAIL,
} from "../types/pharmacyTypes";

const initialState = {
  pharmacies: [],
  pharmacyDetails: "",
  pharmacyProfile: {},
  pharmacyTimes: [],
  pharmacyPrescription: [],
  pharmacyPrescriptionDetails: {},
  loading: false,
};

function adminPharmacyReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PHARMACY_REQUEST:
      return { ...state, loading: true };
    case CREATE_PHARMACY_SUCCESS:
      return { ...state, pharmacies: action.payload, loading: false };
    case CREATE_PHARMACY_FAIL:
      return { ...state, loading: false };

    case GET_PHARMACIES_REQUEST:
      return { ...state, loading: true };
    case GET_PHARMACIES_SUCCESS:
      return { ...state, pharmacies: action.payload, loading: false };
    case GET_PHARMACIES_FAIL:
      return { ...state, loading: false };

    case GET_PHARMACY_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_PHARMACY_DETAILS_SUCCESS:
      return { ...state, pharmacyDetails: action.payload, loading: false };
    case GET_PHARMACY_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_PHARMACY_REQUEST:
      return { ...state, loading: true };
    case DELETE_PHARMACY_SUCCESS:
      return { ...state, pharmacies: action.payload, loading: false };
    case DELETE_PHARMACY_FAIL:
      return { ...state, loading: false };

    case UPDATE_PHARMACY_REQUEST:
      return { ...state, loading: true };
    case UPDATE_PHARMACY_SUCCESS:
      return { ...state, pharmacies: action.payload, loading: false };
    case UPDATE_PHARMACY_FAIL:
      return { ...state, loading: false };

    case APPROVE_PHARMACY_REQUEST:
      return { ...state, loading: true };
    case APPROVE_PHARMACY_SUCCESS:
      return { ...state, pharmacyProfile: action.payload, loading: false };
    case APPROVE_PHARMACY_FAIL:
      return { ...state, loading: false };

    case CHANGE_STATUS_REQUEST:
      return { ...state, loading: true };
    case CHANGE_STATUS_SUCCESS:
      return { ...state, pharmacyProfile: action.payload, loading: false };
    case CHANGE_STATUS_FAIL:
      return { ...state, loading: false };

    case GET_PHARMACY_TIME_REQUEST:
      return { ...state, loading: true };
    case GET_PHARMACY_TIME_SUCCESS:
      return { ...state, pharmacyTimes: action.payload, loading: false };
    case GET_PHARMACY_TIME_FAIL:
      return { ...state, loading: false };

    case SET_PHARMACY_TIME_REQUEST:
      return { ...state, loading: true };
    case SET_PHARMACY_TIME_SUCCESS:
      return { ...state, pharmacyTimes: action.payload, loading: false };
    case SET_PHARMACY_TIME_FAIL:
      return { ...state, loading: false };

    case GET_PHARMACY_PRESCRIPTIONS_REQUEST:
      return { ...state, loading: true };
    case GET_PHARMACY_PRESCRIPTIONS_SUCCESS:
      return { ...state, pharmacyPrescription: action.payload, loading: false };
    case GET_PHARMACY_PRESCRIPTIONS_FAIL:
      return { ...state, loading: false };

    case GET_PHARMACY_PRESCRIPTION_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_PHARMACY_PRESCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        pharmacyPrescriptionDetails: action.payload,
        loading: false,
      };
    case GET_PHARMACY_PRESCRIPTION_DETAILS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminPharmacyReducer;
