import {
  CREATE_MEDICAL_TIME_REQUEST,
  CREATE_MEDICAL_TIME_SUCCESS,
  CREATE_MEDICAL_TIME_FAIL,
  GET_MEDICAL_TIMES_REQUEST,
  GET_MEDICAL_TIMES_SUCCESS,
  GET_MEDICAL_TIMES_FAIL,
  GET_MEDICAL_TIME_DETAILS_REQUEST,
  GET_MEDICAL_TIME_DETAILS_SUCCESS,
  GET_MEDICAL_TIME_DETAILS_FAIL,
  DELETE_MEDICAL_TIME_REQUEST,
  DELETE_MEDICAL_TIME_SUCCESS,
  DELETE_MEDICAL_TIME_FAIL,
  UPDATE_MEDICAL_TIME_REQUEST,
  UPDATE_MEDICAL_TIME_SUCCESS,
  UPDATE_MEDICAL_TIME_FAIL,
} from "../types/medicalTimeTypes";

const initialState = {
  medicalTimes: [],
  medicalTimeDetails: {},
  loading: false,
};

function adminMedicalTimeReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MEDICAL_TIME_REQUEST:
      return { ...state, loading: true };
    case CREATE_MEDICAL_TIME_SUCCESS:
      return { ...state, medicalTimes: action.payload, loading: false };
    case CREATE_MEDICAL_TIME_FAIL:
      return { ...state, loading: false };

    case GET_MEDICAL_TIMES_REQUEST:
      return { ...state, loading: true };
    case GET_MEDICAL_TIMES_SUCCESS:
      return { ...state, medicalTimes: action.payload, loading: false };
    case GET_MEDICAL_TIMES_FAIL:
      return { ...state, loading: false };

    case GET_MEDICAL_TIME_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_MEDICAL_TIME_DETAILS_SUCCESS:
      return { ...state, medicalTimeDetails: action.payload, loading: false };
    case GET_MEDICAL_TIME_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_MEDICAL_TIME_REQUEST:
      return { ...state, loading: true };
    case DELETE_MEDICAL_TIME_SUCCESS:
      return { ...state, medicalTimes: action.payload, loading: false };
    case DELETE_MEDICAL_TIME_FAIL:
      return { ...state, loading: false };

    case UPDATE_MEDICAL_TIME_REQUEST:
      return { ...state, loading: true };
    case UPDATE_MEDICAL_TIME_SUCCESS:
      return { ...state, medicalTimes: action.payload, loading: false };
    case UPDATE_MEDICAL_TIME_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminMedicalTimeReducer;
