export const COUNTRIES_REQUEST = "COUNTRIES_REQUEST";
export const COUNTRIES_SUCCESS = "COUNTRIES_SUCCESS";
export const COUNTRIES_FAIL = "COUNTRIES_FAIL";

export const CITIES_REQUEST = "CITIES_REQUEST";
export const CITIES_SUCCESS = "CITIES_SUCCESS";
export const CITIES_FAIL = "CITIES_FAIL";

export const ATTCH_IMG_REQUEST = "ATTCH_IMG_REQUEST";
export const ATTCH_IMG_SUCCESS = "ATTCH_IMG_SUCCESS";
export const ATTCH_IMG_FAIL = "ATTCH_IMG_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const GET_DOCTOR_HOURS_REQUEST = "GET_DOCTOR_HOURS_REQUEST";
export const GET_DOCTOR_HOURS_SUCCESS = "GET_DOCTOR_HOURS_SUCCESS";
export const GET_DOCTOR_HOURS_FAIL = "GET_DOCTOR_HOURS_FAIL";

export const GET_DOCTOR_REVIEW_REQUEST = "GET_DOCTOR_REVIEW_REQUEST";
export const GET_DOCTOR_REVIEW_SUCCESS = "GET_DOCTOR_REVIEW_SUCCESS";
export const GET_DOCTOR_REVIEW_FAIL = "GET_DOCTOR_REVIEW_FAIL";

export const GET_MEDICAL_TIME = "GET_MEDICAL_TIME";
export const GET_LAB_TEST = "GET_LAB_TEST";
export const GET_DRUG_ITEMS = "GET_DRUG_ITEMS";

export const LABTEST_REQUEST = "LABTEST_REQUEST";
export const LABTEST_SUCCESS = "LABTEST_SUCCESS";
export const LABTEST_FAIL = "LABTEST_FAIL";
