import React, { lazy } from "react";
import { RequireAdmin } from "./RequireRoutes";
import Header from "../admin/components/header";

const AdminDashboard = lazy(() => import("../admin/components/dashboard"));
const AdminAppointments = lazy(() =>
  import("../admin/components/appointments")
);
const AdminAppointmentsDetails = lazy(() =>
  import("../admin/components/appointments/AppointmentDetails")
);
const AdminSlider = lazy(() => import("../admin/components/slider"));
const AdminLogin = lazy(() => import("../admin/components/login"));
const AdminForgotPassword = lazy(() =>
  import("../admin/components/forgotpassword")
);
const AdminVerifiedCode = lazy(() =>
  import("../admin/components/forgotpassword/VerifiedCode")
);
const AdminChangePassword = lazy(() =>
  import("../admin/components/forgotpassword/ChangePassword")
);
const Specialities = lazy(() => import("../admin/components/specialities"));
const MedicalTimes = lazy(() => import("../admin/components/medicalTimes"));
const LabTests = lazy(() => import("../admin/components/labTest"));
const Feelings = lazy(() => import("../admin/components/feeling"));
const Medicals = lazy(() => import("../admin/components/medicals"));
const MedicalsProfile = lazy(() =>
  import("../admin/components/medicals/medicalprofile")
);
const Doctors = lazy(() => import("../admin/components/doctors"));
const DoctorProfile = lazy(() =>
  import("../admin/components/doctors/doctorprofile")
);
const Patients = lazy(() => import("../admin/components/patients"));
const PatientProfile = lazy(() =>
  import("../admin/components/patients/patientprofile")
);

const Pharmacies = lazy(() => import("../admin/components/pharmacies"));
const PharmacyProfile = lazy(() =>
  import("../admin/components/pharmacies/pharmacyprofile")
);
const PharmacyPrescriptionDetails = lazy(() =>
  import("../admin/components/pharmacies/pharmacyprofile/PrescriptionDetails")
);

const Products = lazy(() => import("../admin/components/products"));
const Categories = lazy(() => import("../admin/components/categories"));
const PrescriptionDetails = lazy(() =>
  import("../admin/components/appointments/PrescriptionDetails")
);
const LabTestDetails = lazy(() =>
  import("../admin/components/appointments/LabTestDetails")
);
const Questions = lazy(() => import("../admin/components/questions"));
const Topics = lazy(() => import("../admin/components/topics"));
const Settings = lazy(() => import("../admin/components/settings"));
const Articles = lazy(() => import("../admin/components/article/article"));
const ArticlesTypes = lazy(() =>
  import("../admin/components/article/articletype")
);
const LabtestCenters = lazy(() => import("../admin/components/labtests"));
const LabtestProfile = lazy(() =>
  import("../admin/components/labtests/labtestprofile")
);
const LabtestCategories = lazy(() =>
  import("../admin/components/labtests/labtestcategories")
);
const LabtestOrderDetails = lazy(() =>
  import("../admin/components/labtests/labtestprofile/OrderDetails")
);
const AdminRoutes = [
  {
    path: "/admin",
    component: (
      <RequireAdmin>
        <Header />
        <AdminDashboard />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/appointments",
    component: (
      <RequireAdmin>
        <Header />
        <AdminAppointments />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/appointments/:id",
    component: (
      <RequireAdmin>
        <Header />
        <AdminAppointmentsDetails />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/login",
    component: <AdminLogin />,
  },
  {
    path: "/admin/forgot-password",
    component: <AdminForgotPassword />,
  },
  {
    path: "/admin/verified-code",
    component: <AdminVerifiedCode />,
  },
  {
    path: "/admin/change-password",
    component: <AdminChangePassword />,
  },
  {
    path: "/admin/specialities",
    component: (
      <RequireAdmin>
        <Header />
        <Specialities />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/medical-times",
    component: (
      <RequireAdmin>
        <Header />
        <MedicalTimes />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/lab-tests",
    component: (
      <RequireAdmin>
        <Header />
        <LabTests />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/feelings",
    component: (
      <RequireAdmin>
        <Header />
        <Feelings />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/slider",
    component: (
      <RequireAdmin>
        <Header />
        <AdminSlider />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/medicals",
    component: (
      <RequireAdmin>
        <Header />
        <Medicals />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/medicals/profile/:id",
    component: (
      <RequireAdmin>
        <Header />
        <MedicalsProfile />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/doctors",
    component: (
      <RequireAdmin>
        <Header />
        <Doctors />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/doctors/profile/:id",
    component: (
      <RequireAdmin>
        <Header />
        <DoctorProfile />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/patients",
    component: (
      <RequireAdmin>
        <Header />
        <Patients />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/patient/profile/:id",
    component: (
      <RequireAdmin>
        <Header />
        <PatientProfile />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/pharmacies",
    component: (
      <RequireAdmin>
        <Header />
        <Pharmacies />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/pharmacies/profile/:id",
    component: (
      <RequireAdmin>
        <Header />
        <PharmacyProfile />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/pharmacies/prescription/:id",
    component: (
      <RequireAdmin>
        <Header />
        <PharmacyPrescriptionDetails />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/categories",
    component: (
      <RequireAdmin>
        <Header />
        <Categories />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/products",
    component: (
      <RequireAdmin>
        <Header />
        <Products />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/appointments/prescription/:id",
    component: (
      <RequireAdmin>
        <Header />
        <PrescriptionDetails />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/appointments/labt-test/:id",
    component: (
      <RequireAdmin>
        <Header />
        <LabTestDetails />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/questions",
    component: (
      <RequireAdmin>
        <Header />
        <Questions />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/topics",
    component: (
      <RequireAdmin>
        <Header />
        <Topics />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/articles",
    component: (
      <RequireAdmin>
        <Header />
        <Articles />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/articles-types",
    component: (
      <RequireAdmin>
        <Header />
        <ArticlesTypes />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/settings",
    component: (
      <RequireAdmin>
        <Header />
        <Settings />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/labtest-centers",
    component: (
      <LabtestCenters>
        <Header />
        <Settings />
      </LabtestCenters>
    ),
  },
  {
    path: "/admin/labtest-center/profile/:id",
    component: (
      <RequireAdmin>
        <Header />
        <LabtestProfile />
      </RequireAdmin>
    ),
  },
  {
    path: "/admin/labtest-categories",
    component: (
      <RequireAdmin>
        <Header />
        <LabtestCategories />
      </RequireAdmin>
    ),
  },
  {
    path: "/labtest/order-details/:id",
    component: (
      <RequireAdmin>
        <Header />
        <LabtestOrderDetails />
      </RequireAdmin>
    ),
  },
];

export default AdminRoutes;
