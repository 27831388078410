import React from "react";
import { useNavigate } from "react-router-dom";

function Backbtn({ type }) {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div
      className={`breadcrumb-bar bg-transparent pb-0 ${
        type === "chat" ? "pt-0" : ""
      }`}
    >
      <div className={type === "chat" ? "p-0" : "container-fluid"}>
        <button className="btn btn-info mr-2" onClick={() => navigate(-1)}>
          <i className="fa fa-arrow-circle-left mr-2"></i>Back
        </button>
        <button
          className="btn btn-info"
          onClick={() => navigate(`/${user?.role}`)}
        >
          <i className="fas fa-columns mr-2"></i>Dashboard
        </button>
      </div>
    </div>
  );
}

export default Backbtn;
