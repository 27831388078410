import {
  GET_PATIENTS_REQUEST,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
  GET_PATIENT_DETAILS_REQUEST,
  GET_PATIENT_DETAILS_SUCCESS,
  GET_PATIENT_DETAILS_FAIL,
  GET_BLOOD_TYPES_REQUEST,
  GET_BLOOD_TYPES_SUCCESS,
  GET_BLOOD_TYPES_FAIL,
  GET_PATIENT_INFO_REQUEST,
  GET_PATIENT_INFO_SUCCESS,
  GET_PATIENT_INFO_FAIL,
  SET_PATIENT_INFO_REQUEST,
  SET_PATIENT_INFO_SUCCESS,
  SET_PATIENT_INFO_FAIL,
} from "../types/patientTypes";

const initialState = {
  myPatients: [],
  myPatientDetails: {},
  bloodTypes: [],
  patientInfo: {},
  loading: false,
};

function doctorPatientReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PATIENTS_REQUEST:
      return { ...state, loading: true };
    case GET_PATIENTS_SUCCESS:
      return { ...state, myPatients: action.payload, loading: false };
    case GET_PATIENTS_FAIL:
      return { ...state, loading: false };

    case GET_PATIENT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_PATIENT_DETAILS_SUCCESS:
      return { ...state, myPatientDetails: action.payload, loading: false };
    case GET_PATIENT_DETAILS_FAIL:
      return { ...state, loading: false };

    case GET_BLOOD_TYPES_REQUEST:
      return { ...state, loading: true };
    case GET_BLOOD_TYPES_SUCCESS:
      return { ...state, bloodTypes: action.payload, loading: false };
    case GET_BLOOD_TYPES_FAIL:
      return { ...state, loading: false };

    case GET_PATIENT_INFO_REQUEST:
      return { ...state, loading: true };
    case GET_PATIENT_INFO_SUCCESS:
      return { ...state, loading: false, patientInfo: action.payload };
    case GET_PATIENT_INFO_FAIL:
      return { ...state, loading: false };

    case SET_PATIENT_INFO_REQUEST:
      return { ...state, loading: true };
    case SET_PATIENT_INFO_SUCCESS:
      return { ...state, loading: false, patientInfo: action.payload };
    case SET_PATIENT_INFO_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default doctorPatientReducer;
