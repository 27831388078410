export const CREATE_ARTICLE_REQUEST = "CREATE_ARTICLE_REQUEST";
export const CREATE_ARTICLE_SUCCESS = "CREATE_ARTICLE_SUCCESS";
export const CREATE_ARTICLE_FAIL = "CREATE_ARTICLE_FAIL";

export const GET_ARTICLES_REQUEST = "GET_ARTICLES_REQUEST";
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS";
export const GET_ARTICLES_FAIL = "GET_ARTICLES_FAIL";

export const GET_ARTICLE_DETAILS_REQUEST = "GET_ARTICLE_DETAILS_REQUEST";
export const GET_ARTICLE_DETAILS_SUCCESS = "GET_ARTICLE_DETAILS_SUCCESS";
export const GET_ARTICLE_DETAILS_FAIL = "GET_ARTICLE_DETAILS_FAIL";

export const DELETE_ARTICLE_REQUEST = "DELETE_ARTICLE_REQUEST";
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS";
export const DELETE_ARTICLE_FAIL = "DELETE_ARTICLE_FAIL";

export const UPDATE_ARTICLE_REQUEST = "UPDATE_ARTICLE_REQUEST";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_FAIL = "UPDATE_ARTICLE_FAIL";
