import { Routes, Route, Navigate } from "react-router-dom";
import RootRoutes from "./routes/RootRoutes";

function App() {
  return (
    <Routes>
      {RootRoutes.routes.map((route, index) => {
        return (
          <Route
            path={route.path}
            element={route.component}
            key={index}
            exact
          />
        );
      })}
    </Routes>
  );
}

export default App;
