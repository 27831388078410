export const CREATE_MEDICAL_TIME_REQUEST = "CREATE_MEDICAL_TIME_REQUEST";
export const CREATE_MEDICAL_TIME_SUCCESS = "CREATE_MEDICAL_TIME_SUCCESS";
export const CREATE_MEDICAL_TIME_FAIL = "CREATE_MEDICAL_TIME_FAIL";

export const GET_MEDICAL_TIMES_REQUEST = "GET_MEDICAL_TIMES_REQUEST";
export const GET_MEDICAL_TIMES_SUCCESS = "GET_MEDICAL_TIMES_SUCCESS";
export const GET_MEDICAL_TIMES_FAIL = "GET_MEDICAL_TIMES_FAIL";

export const GET_MEDICAL_TIME_DETAILS_REQUEST =
  "GET_MEDICAL_TIME_DETAILS_REQUEST";
export const GET_MEDICAL_TIME_DETAILS_SUCCESS =
  "GET_MEDICAL_TIME_DETAILS_SUCCESS";
export const GET_MEDICAL_TIME_DETAILS_FAIL = "GET_SPECIFIC_MEDICAL_TIME_FAIL";

export const DELETE_MEDICAL_TIME_REQUEST = "DELETE_MEDICAL_TIME_REQUEST";
export const DELETE_MEDICAL_TIME_SUCCESS = "DELETE_MEDICAL_TIME_SUCCESS";
export const DELETE_MEDICAL_TIME_FAIL = "DELETE_MEDICAL_TIME_FAIL";

export const UPDATE_MEDICAL_TIME_REQUEST = "UPDATE_MEDICAL_TIME_REQUEST";
export const UPDATE_MEDICAL_TIME_SUCCESS = "UPDATE_MEDICAL_TIME_SUCCESS";
export const UPDATE_MEDICAL_TIME_FAIL = "UPDATE_MEDICAL_TIME_FAIL";
