import {
  CREATE_QUESTION_REQUEST,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAIL,
  GET_QUESTIONS_REQUEST,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
  GET_QUESTION_DETAILS_REQUEST,
  GET_QUESTION_DETAILS_SUCCESS,
  GET_QUESTION_DETAILS_FAIL,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAIL,
} from "../types/questionTypes";

const initialState = {
  questions: [],
  questionDetails: {},
  loading: false,
};

function adminQuestionReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_QUESTION_REQUEST:
      return { ...state, loading: true };
    case CREATE_QUESTION_SUCCESS:
      return { ...state, questions: action.payload, loading: false };
    case CREATE_QUESTION_FAIL:
      return { ...state, loading: false };

    case GET_QUESTIONS_REQUEST:
      return { ...state, loading: true };
    case GET_QUESTIONS_SUCCESS:
      return { ...state, questions: action.payload, loading: false };
    case GET_QUESTIONS_FAIL:
      return { ...state, loading: false };

    case GET_QUESTION_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_QUESTION_DETAILS_SUCCESS:
      return { ...state, questionDetails: action.payload, loading: false };
    case GET_QUESTION_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_QUESTION_REQUEST:
      return { ...state, loading: true };
    case DELETE_QUESTION_SUCCESS:
      return { ...state, questions: action.payload, loading: false };
    case DELETE_QUESTION_FAIL:
      return { ...state, loading: false };

    case UPDATE_QUESTION_REQUEST:
      return { ...state, loading: true };
    case UPDATE_QUESTION_SUCCESS:
      return { ...state, questions: action.payload, loading: false };
    case UPDATE_QUESTION_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminQuestionReducer;
