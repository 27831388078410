import { combineReducers } from "redux";
import rootAdminReducers from "./admin/reducers/rootAdminReducers";
import rootMedicalReducers from "./medical/reducers/rootMedicalReducers";
import rootPharmacyReducers from "./pharmacy/reducers/rootPharmacyReducers";
import rootDoctorReducers from "./doctor/reducers/rootDoctorReducers";
import rootLabTestReducers from "./labtest/reducers/rootLabTestReducers";
import rootCommonReducers from "./common/reducers/rootCommonReducers";

const reducers = combineReducers({
  ...rootAdminReducers,
  ...rootMedicalReducers,
  ...rootPharmacyReducers,
  ...rootDoctorReducers,
  ...rootLabTestReducers,
  ...rootCommonReducers,
});

export default reducers;

// const useAppSelector = () => {
//   const selector = useSelector();
//   return selector();
// };

// const useAdmin=()=>{
// const {admin,}= useAppSelector()
// const {adminDispatch}=useAppDispatch()
// const getdata=  getall( admindispatch)
// return {state,getdata,setdata}
// }
