import { auth } from "../firebase/firebase";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

// Function to sign in with Google
export const useSignInWithGoogle = async (type) => {
  try {
    const provider = type === "google" ? googleProvider : facebookProvider;
    const result = await signInWithPopup(auth, provider);
    return result;
  } catch (error) {
    console.error(error.message);
  }
};

// const signInWithFacebook = async () => {
//   try {
//     const result = await signInWithPopup(auth, facebookProvider);
//     setUser(result.user);
//   } catch (error) {
//     console.error(error.message);
//   }
// };

export const useSignOutWithSocial = () => {
  signOut(auth);
};
