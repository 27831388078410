export const SET_DOCTOR_CLINIC = "SET_DOCTOR_CLINIC";
export const SET_DOCTOR_EDUCATION = "SET_DOCTOR_EDUCATION";
export const SET_DOCTOR_EXPERIENCE = "SET_DOCTOR_EXPERIENCE";
export const SET_DOCTOR_AWARDS = "SET_DOCTOR_AWARDS";
export const SET_DOCTOR_MEMBERSHIP = "SET_DOCTOR_MEMBERSHIP";
export const SET_DOCTOR_LANGUAGE = "SET_DOCTOR_LANGUAGE";

export const Get_DOCTOR_PROFILE_REQUEST = "Get_DOCTOR_PROFILE_REQUEST";
export const Get_DOCTOR_PROFILE_SUCCESS = "Get_DOCTOR_PROFILE_SUCCESS";
export const Get_DOCTOR_PROFILE_FAIL = "Get_DOCTOR_PROFILE_FAIL";

export const GET_DOCTOR_DAYS_REQUEST = "GET_DOCTOR_DAYS_REQUEST";
export const GET_DOCTOR_DAYS_SUCCESS = "GET_DOCTOR_DAYS_SUCCESS";
export const GET_DOCTOR_DAYS_FAIL = "GET_DOCTOR_DAYS_FAIL";
export const GET_DOCTOR_TIMES_REQUEST = "GET_DOCTOR_TIMES_REQUEST";
export const GET_DOCTOR_TIMES_SUCCESS = "GET_DOCTOR_TIMES_SUCCESS";
export const GET_DOCTOR_TIMES_FAIL = "GET_DOCTOR_TIMES_FAIL";

export const SET_DOCTOR_TIME_WORK = "SET_DOCTOR_TIME_WORK";

export const GET_APPOINT_DOCTOR_REQUEST = "GET_APPOINT_DOCTOR_REQUEST";
export const GET_APPOINT_DOCTOR_SUCCESS = "GET_APPOINT_DOCTOR_SUCCESS";
export const GET_APPOINT_DOCTOR_FAIL = "GET_APPOINT_DOCTOR_FAIL";
