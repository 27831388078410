export const REGISTER_DOCTOR_REQUEST = "REGISTER_DOCTOR_REQUEST";
export const REGISTER_DOCTOR_SUCCESS = "REGISTER_DOCTOR_SUCCESS";
export const REGISTER_DOCTOR_FAIL = "REGISTER_DOCTOR_FAIL";

export const LOGIN_DOCTOR_REQUEST = "LOGIN_DOCTOR_REQUEST";
export const LOGIN_DOCTOR_SUCCESS = "LOGIN_DOCTOR_SUCCESS";
export const LOGIN_DOCTOR_FAIL = "LOGIN_DOCTOR_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const VERIFIED_CODE_REQUEST = "VERIFIED_CODE_REQUEST";
export const VERIFIED_CODE_SUCCESS = "VERIFIED_CODE_SUCCESS";
export const VERIFIED_CODE_FAIL = "VERIFIED_CODE_FAIL";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const LOGIN_DOCTOR_SOCIAL_REQUEST = "LOGIN_DOCTOR_SOCIAL_REQUEST";
export const LOGIN_DOCTOR_SOCIAL_SUCCESS = "LOGIN_DOCTOR_SOCIAL_SUCCESS";
export const LOGIN_DOCTOR_SOCIAL_FAIL = "LOGIN_DOCTOR_SOCIAL_FAIL";
