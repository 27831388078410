import {
  GET_ADMIN_PRODUCTS_REQUEST,
  GET_ADMIN_PRODUCTS_SUCCESS,
  GET_ADMIN_PRODUCTS_FAIL,
  GET_ADMIN_PRODUCT_DETAILS_REQUEST,
  GET_ADMIN_PRODUCT_DETAILS_SUCCESS,
  GET_ADMIN_PRODUCT_DETAILS_FAIL,
  GET_MY_PRODUCTS_REQUEST,
  GET_MY_PRODUCTS_SUCCESS,
  GET_MY_PRODUCTS_FAIL,
  GET_MY_PRODUCT_DETAILS_REQUEST,
  GET_MY_PRODUCT_DETAILS_SUCCESS,
  GET_MY_PRODUCT_DETAILS_FAIL,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  PRODUCTS_BY_CATEGORY_REQUEST,
  PRODUCTS_BY_CATEGORY_SUCCESS,
  PRODUCTS_BY_CATEGORY_FAIL,
} from "../types/productsTypes";

const initialValue = {
  adminProducts: [],
  adminProductDetails: {},
  myProducts: [],
  myProductDetails: {},
  productsByCategory: {},
  loading: false,
};

function pharmacyProductsReducer(state = initialValue, action) {
  switch (action.type) {
    case GET_ADMIN_PRODUCTS_REQUEST:
      return { ...state, loading: true };
    case GET_ADMIN_PRODUCTS_SUCCESS:
      return { ...state, adminProducts: action.payload, loading: false };
    case GET_ADMIN_PRODUCTS_FAIL:
      return { ...state, loading: false };

    case GET_ADMIN_PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_ADMIN_PRODUCT_DETAILS_SUCCESS:
      return { ...state, adminProductDetails: action.payload, loading: false };
    case GET_ADMIN_PRODUCT_DETAILS_FAIL:
      return { ...state, loading: false };

    case GET_MY_PRODUCTS_REQUEST:
      return { ...state, loading: true };
    case GET_MY_PRODUCTS_SUCCESS:
      return { ...state, myProducts: action.payload, loading: false };
    case GET_MY_PRODUCTS_FAIL:
      return { ...state, loading: false };

    case GET_MY_PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_MY_PRODUCT_DETAILS_SUCCESS:
      return { ...state, myProductDetails: action.payload, loading: false };
    case GET_MY_PRODUCT_DETAILS_FAIL:
      return { ...state, loading: false };

    case ADD_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case ADD_PRODUCT_SUCCESS:
      return { ...state, myProducts: action.payload, loading: false };
    case ADD_PRODUCT_FAIL:
      return { ...state, loading: false };

    case DELETE_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case DELETE_PRODUCT_SUCCESS:
      return { ...state, myProducts: action.payload, loading: false };
    case DELETE_PRODUCT_FAIL:
      return { ...state, loading: false };

    case UPDATE_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case UPDATE_PRODUCT_SUCCESS:
      return { ...state, myProducts: action.payload, loading: false };
    case UPDATE_PRODUCT_FAIL:
      return { ...state, loading: false };

    case PRODUCTS_BY_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case PRODUCTS_BY_CATEGORY_SUCCESS:
      return { ...state, productsByCategory: action.payload, loading: false };
    case PRODUCTS_BY_CATEGORY_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default pharmacyProductsReducer;
