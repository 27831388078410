import {
  GET_APPOINT_LAB_TESTS_REQUEST,
  GET_APPOINT_LAB_TESTS_SUCCESS,
  GET_APPOINT_LAB_TESTS_FAIL,
  GET_APPOINT_LAB_DETAILS_REQUEST,
  GET_APPOINT_LAB_DETAILS_SUCCESS,
  GET_APPOINT_LAB_DETAILS_FAIL,
} from "../types/appointmentLabTypes";

const initialValue = {
  labTests: [],
  labTestDetails: {},
  loading: false,
};

function adminAppointmentLabReducer(state = initialValue, action) {
  switch (action.type) {
    case GET_APPOINT_LAB_TESTS_REQUEST:
      return { ...state, loading: true };
    case GET_APPOINT_LAB_TESTS_SUCCESS:
      return {
        ...state,
        labTests: action.payload,
        loading: false,
      };
    case GET_APPOINT_LAB_TESTS_FAIL:
      return { ...state, loading: false };

    case GET_APPOINT_LAB_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_APPOINT_LAB_DETAILS_SUCCESS:
      return { ...state, labTestDetails: action.payload, loading: false };
    case GET_APPOINT_LAB_DETAILS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminAppointmentLabReducer;
