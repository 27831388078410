import {
  GET_APPOINTMENTS_FAIL,
  GET_APPOINTMENTS_REQUEST,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINT_BILLS_REQUEST,
  GET_APPOINT_BILLS_SUCCESS,
  GET_APPOINT_BILLS_FAIL,
} from "../types/appointmentTypes";

const initialValue = {
  appointments: [],
  appointmentBilling: [],
  loading: false,
};

function adminAppointmentReducer(state = initialValue, action) {
  switch (action.type) {
    case GET_APPOINTMENTS_REQUEST:
      return { ...state, loading: true };
    case GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointments: action.payload,
        loading: false,
      };
    case GET_APPOINTMENTS_FAIL:
      return { ...state, loading: false };

    case GET_APPOINT_BILLS_REQUEST:
      return { ...state, loading: true };
    case GET_APPOINT_BILLS_SUCCESS:
      return { ...state, appointmentBilling: action.payload, loading: false };
    case GET_APPOINT_BILLS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminAppointmentReducer;
