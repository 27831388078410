export const CHECK_PRESCRIPTION_NUMBER_REQUEST =
  "CHECK_PRESCRIPTION_NUMBER_REQUEST";
export const CHECK_PRESCRIPTION_NUMBER_SUCCESS =
  "CHECK_PRESCRIPTION_NUMBER_SUCCESS";
export const CHECK_PRESCRIPTION_NUMBER_FAIL = "CHECK_PRESCRIPTION_NUMBER_FAIL";

export const CHECK_PEN_CODE_REQUEST = "CHECK_PEN_CODE_REQUEST";
export const CHECK_PEN_CODE_SUCCESS = "CHECK_PEN_CODE_SUCCESS";
export const CHECK_PEN_CODE_FAIL = "CHECK_PEN_CODE_FAIL";

export const SEND_TO_ORDER_REQUEST = "SEND_TO_ORDER_REQUEST";
export const SEND_TO_ORDER_SUCCESS = "SEND_TO_ORDER_SUCCESS";
export const SEND_TO_ORDER_FAIL = "SEND_TO_ORDER_FAIL";

export const GET_PRESCRIPTIONS_REQUEST = "GET_PRESCRIPTIONS_REQUEST";
export const GET_PRESCRIPTIONS_SUCCESS = "GET_PRESCRIPTIONS_SUCCESS";
export const GET_PRESCRIPTIONS_FAIL = "GET_PRESCRIPTIONS_FAIL";

export const GET_PRES_DETAILS_REQUEST = "GET_PRES_DETAILS_REQUEST";
export const GET_PRES_DETAILS_SUCCESS = "GET_PRES_DETAILS_SUCCESS";
export const GET_PRES_DETAILS_FAIL = "GET_PRES_DETAILS_FAIL";
