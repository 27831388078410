import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAIL,
  CITIES_REQUEST,
  CITIES_SUCCESS,
  CITIES_FAIL,
  ATTCH_IMG_REQUEST,
  ATTCH_IMG_SUCCESS,
  ATTCH_IMG_FAIL,
  GET_DOCTOR_HOURS_REQUEST,
  GET_DOCTOR_HOURS_SUCCESS,
  GET_DOCTOR_HOURS_FAIL,
  GET_DOCTOR_REVIEW_REQUEST,
  GET_DOCTOR_REVIEW_SUCCESS,
  GET_DOCTOR_REVIEW_FAIL,
  GET_MEDICAL_TIME,
  GET_LAB_TEST,
  GET_DRUG_ITEMS,
  LABTEST_REQUEST,
  LABTEST_SUCCESS,
  LABTEST_FAIL,
} from "../types/commonTypes";

const initialState = {
  countries: [],
  cities: [],
  selectedImg: {},
  doctorBusinessHours: [],
  doctorReviews: [],
  labTest: [],
  medicalsTime: [],
  drusItems: [],
  labtestCategory: [],
  loading: false,
};

function commonReducers(state = initialState, action) {
  switch (action.type) {
    case COUNTRIES_REQUEST:
      return { ...state, loading: true };
    case COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload, loading: false };
    case COUNTRIES_FAIL:
      return { ...state, loading: false };

    case CITIES_REQUEST:
      return { ...state, loading: true };
    case CITIES_SUCCESS:
      return { ...state, cities: action.payload, loading: false };
    case CITIES_FAIL:
      return { ...state, loading: false };

    case ATTCH_IMG_REQUEST:
      return { ...state, loading: true };
    case ATTCH_IMG_SUCCESS:
      return { ...state, selectedImg: action.payload, loading: false };
    case ATTCH_IMG_FAIL:
      return { ...state, loading: false };

    case GET_DOCTOR_HOURS_REQUEST:
      return { ...state, loading: true };
    case GET_DOCTOR_HOURS_SUCCESS:
      return { ...state, doctorBusinessHours: action.payload, loading: false };
    case GET_DOCTOR_HOURS_FAIL:
      return { ...state, loading: false };

    case GET_DOCTOR_REVIEW_REQUEST:
      return { ...state, loading: true };
    case GET_DOCTOR_REVIEW_SUCCESS:
      return { ...state, doctorReviews: action.payload, loading: false };
    case GET_DOCTOR_REVIEW_FAIL:
      return { ...state, loading: false };

    case GET_MEDICAL_TIME:
      return { ...state, medicalsTime: action.payload, loading: false };
    case GET_LAB_TEST:
      return { ...state, labTest: action.payload, loading: false };
    case GET_DRUG_ITEMS:
      return { ...state, drusItems: action.payload, loading: false };

    case LABTEST_REQUEST:
      return { ...state, loading: true };
    case LABTEST_SUCCESS:
      return { ...state, labtestCategory: action.payload, loading: false };
    case LABTEST_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default commonReducers;
