import {
  GET_DOCTORS_REQUEST,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_FAIL,
  GET_SPECIFIC_DOCTOR_REQUEST,
  GET_SPECIFIC_DOCTOR_SUCCESS,
  GET_SPECIFIC_DOCTOR_FAIL,
  Get_DOCTOR_PROFILE_REQUEST,
  Get_DOCTOR_PROFILE_SUCCESS,
  Get_DOCTOR_PROFILE_FAIL,
  CHANGE_STATUS_REQUEST,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAIL,
  DELETE_DOCTOR_REQUEST,
  DELETE_DOCTOR_SUCCESS,
  DELETE_DOCTOR_FAIL,
} from "../types/doctorTypes";

const initialState = {
  doctors: {},
  doctorProfile: {},
  docDetails: "",
  loading: false,
};

function adminDoctorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DOCTORS_REQUEST:
      return { ...state, loading: true };
    case GET_DOCTORS_SUCCESS:
      return { ...state, doctors: action.payload, loading: false };
    case GET_DOCTORS_FAIL:
      return { ...state, loading: false };

    case Get_DOCTOR_PROFILE_REQUEST:
      return { ...state, loading: true };
    case Get_DOCTOR_PROFILE_SUCCESS:
      return { ...state, doctorProfile: action.payload, loading: false };
    case Get_DOCTOR_PROFILE_FAIL:
      return { ...state, loading: false };

    case GET_SPECIFIC_DOCTOR_REQUEST:
      return { ...state };
    case GET_SPECIFIC_DOCTOR_SUCCESS:
      return { ...state, docDetails: action.payload };
    case GET_SPECIFIC_DOCTOR_FAIL:
      return { ...state };

    case CHANGE_STATUS_REQUEST:
      return { ...state };
    case CHANGE_STATUS_SUCCESS:
      return { ...state, doctorProfile: action.payload };
    case CHANGE_STATUS_FAIL:
      return { ...state };

    case DELETE_DOCTOR_REQUEST:
      return { ...state, loading: true };
    case DELETE_DOCTOR_SUCCESS:
      return { ...state, doctors: action.payload, loading: false };
    case DELETE_DOCTOR_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminDoctorReducer;
