import {
  CREATE_ARTICLE_TYPE_REQUEST,
  CREATE_ARTICLE_TYPE_SUCCESS,
  CREATE_ARTICLE_TYPE_FAIL,
  GET_ARTICLE_TYPES_REQUEST,
  GET_ARTICLE_TYPES_SUCCESS,
  GET_ARTICLE_TYPES_FAIL,
  GET_ARTICLE_TYPE_DETAILS_REQUEST,
  GET_ARTICLE_TYPE_DETAILS_SUCCESS,
  GET_ARTICLE_TYPE_DETAILS_FAIL,
  DELETE_ARTICLE_TYPE_REQUEST,
  DELETE_ARTICLE_TYPE_SUCCESS,
  DELETE_ARTICLE_TYPE_FAIL,
  UPDATE_ARTICLE_TYPE_REQUEST,
  UPDATE_ARTICLE_TYPE_SUCCESS,
  UPDATE_ARTICLE_TYPE_FAIL,
} from "../../types/aricle/aricleTypeTypes";

const initialState = {
  articlesTypes: [],
  articleTypeDetails: "",
  loading: false,
};

function adminArticleReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ARTICLE_TYPE_REQUEST:
      return { ...state, loading: true };
    case CREATE_ARTICLE_TYPE_SUCCESS:
      return { ...state, articlesTypes: action.payload, loading: false };
    case CREATE_ARTICLE_TYPE_FAIL:
      return { ...state, loading: false };

    case GET_ARTICLE_TYPES_REQUEST:
      return { ...state, loading: true };
    case GET_ARTICLE_TYPES_SUCCESS:
      return { ...state, articlesTypes: action.payload, loading: false };
    case GET_ARTICLE_TYPES_FAIL:
      return { ...state, loading: false };

    case GET_ARTICLE_TYPE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_ARTICLE_TYPE_DETAILS_SUCCESS:
      return { ...state, articleTypeDetails: action.payload, loading: false };
    case GET_ARTICLE_TYPE_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_ARTICLE_TYPE_REQUEST:
      return { ...state, loading: true };
    case DELETE_ARTICLE_TYPE_SUCCESS:
      return { ...state, articlesTypes: action.payload, loading: false };
    case DELETE_ARTICLE_TYPE_FAIL:
      return { ...state, loading: false };

    case UPDATE_ARTICLE_TYPE_REQUEST:
      return { ...state, loading: true };
    case UPDATE_ARTICLE_TYPE_SUCCESS:
      return { ...state, articlesTypes: action.payload, loading: false };
    case UPDATE_ARTICLE_TYPE_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminArticleReducer;
