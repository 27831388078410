import { useGetData } from "../../../hooks/api/useGetData";
import { useInsertData } from "../../../hooks/api/useInsertData";
import { notify } from "../../../hooks/notify/useNotification";
import {
  GET_APPOINTMENTS_REQUEST,
  GET_APPOINTMENTS_SUCCESS,
  GET_APPOINTMENTS_FAIL,
  GET_APPOINT_DETAILS_REQUEST,
  GET_APPOINT_DETAILS_SUCCESS,
  GET_APPOINT_DETAILS_FAIL,
  GET_COMPLETED_APPOINTMENTS_REQUEST,
  GET_COMPLETED_APPOINTMENTS_SUCCESS,
  GET_COMPLETED_APPOINTMENTS_FAIL,
  MEDICAL_CHANGE_APPOINTMENT_REQUEST,
  MEDICAL_CHANGE_APPOINTMENT_SUCCESS,
  MEDICAL_CHANGE_APPOINTMENT_FAIL,
  DOCTOR_CHANGE_APPOINTMENT_REQUEST,
  DOCTOR_CHANGE_APPOINTMENT_SUCCESS,
  DOCTOR_CHANGE_APPOINTMENT_FAIL,
  ADD_APPOINT_DESC_REQUEST,
  ADD_APPOINT_DESC_SUCCESS,
  ADD_APPOINT_DESC_FAIL,
} from "../types/appointmentTypes";

export const getAppointments =
  ({ page, size, medicalId, doctorId, userId, date, status, statusApp }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_APPOINTMENTS_REQUEST,
      });

      const res = await useGetData(
        `/api/common/appointments?page=${page || 1}&size=${
          size || 10
        }&medicalId=${medicalId || 0}&doctorId=${doctorId || 0}&userId=${
          userId || 0
        }&date=${date || 0}&status=${status || 0}&statusApp=${statusApp || 0}`
      );
      dispatch({
        type: GET_APPOINTMENTS_SUCCESS,
        payload: res,
      });
      console.log(res);
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_APPOINTMENTS_FAIL,
      });
    }
  };

export const getCompletedAppointments =
  ({ page, size, medicalId, doctorId, userId, date, status, statusApp }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_COMPLETED_APPOINTMENTS_REQUEST,
      });

      const res = await useGetData(
        `/api/common/appointments?page=${page || 1}&size=${
          size || 10
        }&medicalId=${medicalId || 0}&doctorId=${doctorId || 0}&userId=${
          userId || 0
        }&date=${date || 0}&status=${status || 0}&statusApp=${statusApp || 0}`
      );
      dispatch({
        type: GET_COMPLETED_APPOINTMENTS_SUCCESS,
        payload: res,
      });
      console.log(res);
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_COMPLETED_APPOINTMENTS_FAIL,
      });
    }
  };

export const getAppointDetails = (appointId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPOINT_DETAILS_REQUEST,
    });

    const res = await useGetData(
      `/api/common/appointments?appointmentBookingId=${appointId}`
    );
    dispatch({
      type: GET_APPOINT_DETAILS_SUCCESS,
      payload: res?.data.shift(),
    });
    console.log(res);
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_APPOINT_DETAILS_FAIL,
    });
  }
};

export const medicalchangeAppointStatus =
  (formData, callback) => async (dispatch) => {
    try {
      dispatch({
        type: MEDICAL_CHANGE_APPOINTMENT_REQUEST,
      });

      const res = await useInsertData(
        `/api/medical/appointments/updateStatus`,
        formData
      );
      res.code === 201 && notify(res.message, "success");
      dispatch({
        type: MEDICAL_CHANGE_APPOINTMENT_SUCCESS,
        payload: res,
      });
      callback();
      console.log(res);
    } catch (err) {
      console.log(err);
      dispatch({
        type: MEDICAL_CHANGE_APPOINTMENT_FAIL,
      });
    }
  };

export const doctorchangeAppointStatus =
  (formData, callback) => async (dispatch) => {
    try {
      dispatch({
        type: DOCTOR_CHANGE_APPOINTMENT_REQUEST,
      });

      const res = await useInsertData(
        `/api/doctors/patients/appointments/updateStatus`,
        formData
      );
      res.code === 201 && notify(res.message, "success");
      dispatch({
        type: DOCTOR_CHANGE_APPOINTMENT_SUCCESS,
        payload: res,
      });
      console.log(res);
      callback();
    } catch (err) {
      console.log(err);
      // dispatch if there error
      dispatch({
        type: DOCTOR_CHANGE_APPOINTMENT_FAIL,
      });
    }
  };

export const addAppointDescription =
  (formData, callback) => async (dispatch) => {
    try {
      dispatch({ type: ADD_APPOINT_DESC_REQUEST });

      const res = await useInsertData(
        "/api/doctors/patients/prescription/description/update",
        formData
      );
      res.code === 201 && notify(res?.message, "success");
      console.log(res);
      dispatch({
        type: ADD_APPOINT_DESC_SUCCESS,
        payload: res?.data,
      });
      callback();
    } catch (err) {
      console.log(err);
      err &&
        notify(
          err?.response?.data?.message?.appointmentBookingId?.shift() ||
            err?.response?.data?.message?.description?.shift() ||
            err?.message,
          "error"
        );
      dispatch({
        type: ADD_APPOINT_DESC_FAIL,
      });
    }
  };
