export const CREATE_DOCTOR_REQUEST = "CREATE_DOCTOR_REQUEST";
export const CREATE_DOCTOR_SUCCESS = "CREATE_DOCTOR_SUCCESS";
export const CREATE_DOCTOR_FAIL = "CREATE_DOCTOR_FAIL";

export const GET_DOCTORS_REQUEST = "GET_DOCTORS_REQUEST";
export const GET_DOCTORS_SUCCESS = "GET_DOCTORS_SUCCESS";
export const GET_DOCTORS_FAIL = "GET_DOCTORS_FAIL";

export const GET_SPECIFIC_DOCTOR_REQUEST = "GET_SPECIFIC_DOCTOR_REQUEST";
export const GET_SPECIFIC_DOCTOR_SUCCESS = "GET_SPECIFIC_DOCTOR_SUCCESS";
export const GET_SPECIFIC_DOCTOR_FAIL = "GET_SPECIFIC_DOCTOR_FAIL";

export const DELETE_DOCTOR_REQUEST = "DELETE_DOCTOR_REQUEST";
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS";
export const DELETE_DOCTOR_FAIL = "DELETE_DOCTOR_FAIL";

export const UPDATE_DOCTOR_REQUEST = "UPDATE_DOCTOR_REQUEST";
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS";
export const UPDATE_DOCTOR_FAIL = "UPDATE_DOCTOR_FAIL";

export const CHANGE_STATUS_REQUEST = "CHANGE_STATUS_REQUEST";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_FAIL = "CHANGE_STATUS_FAIL";
