import { useGetData } from "../../../hooks/api/useGetData";
import {
  useInsertData,
  useInsertDataWithImage,
} from "../../../hooks/api/useInsertData";
import { useSignOutWithSocial } from "../../../hooks/api/useSignInWithGoogle";
import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAIL,
  CITIES_REQUEST,
  CITIES_SUCCESS,
  CITIES_FAIL,
  ATTCH_IMG_REQUEST,
  ATTCH_IMG_SUCCESS,
  ATTCH_IMG_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  GET_DOCTOR_HOURS_REQUEST,
  GET_DOCTOR_HOURS_SUCCESS,
  GET_DOCTOR_HOURS_FAIL,
  GET_DOCTOR_REVIEW_REQUEST,
  GET_DOCTOR_REVIEW_SUCCESS,
  GET_DOCTOR_REVIEW_FAIL,
  GET_MEDICAL_TIME,
  GET_LAB_TEST,
  GET_DRUG_ITEMS,
  LABTEST_REQUEST,
  LABTEST_SUCCESS,
  LABTEST_FAIL,
} from "../types/commonTypes";

export const getCountries = () => async (dispatch) => {
  try {
    dispatch({
      type: COUNTRIES_REQUEST,
    });

    const res = await useGetData("/api/common/countries");
    dispatch({
      type: COUNTRIES_SUCCESS,
      payload: res.data,
    });
    console.log(res);
  } catch (err) {
    console.log(err);
    dispatch({
      type: COUNTRIES_FAIL,
    });
  }
};

export const getCities = (country_id) => async (dispatch) => {
  try {
    dispatch({
      type: CITIES_REQUEST,
    });

    const res = await useGetData(`/api/common/cities?country_id=${country_id}`);
    dispatch({
      type: CITIES_SUCCESS,
      payload: res.data,
    });
    console.log(res);
  } catch (err) {
    console.log(err);
    dispatch({
      type: CITIES_FAIL,
    });
  }
};

export const attachImg = (attachment) => async (dispatch) => {
  try {
    dispatch({
      type: ATTCH_IMG_REQUEST,
    });

    const res = await useInsertDataWithImage(
      "/api/common/attachment/store",
      attachment
    );
    dispatch({
      type: ATTCH_IMG_SUCCESS,
      payload: res.data,
    });
    console.log(res);
  } catch (err) {
    console.log(err);
    dispatch({
      type: ATTCH_IMG_FAIL,
    });
  }
};

export const userLogout = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    useSignOutWithSocial();
    const res = await useInsertData("/api/common/logout");
    localStorage.removeItem("user");
    localStorage.removeItem("searchKey");
    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: res.data,
    });
    callback();
  } catch (err) {
    console.log(err);
    dispatch({
      type: USER_LOGOUT_FAIL,
    });
  }
};

export const getDoctorBusinessHours =
  (doctorId, fallback) => async (dispatch) => {
    try {
      dispatch({
        type: GET_DOCTOR_HOURS_REQUEST,
      });

      const res = await useGetData(
        `/api/doctors/appointment?doctorId=${doctorId}`
      );
      dispatch({
        type: GET_DOCTOR_HOURS_SUCCESS,
        payload: res?.data?.appointment,
      });
      fallback();
      console.log(res);
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_DOCTOR_HOURS_FAIL,
      });
    }
  };

export const getDoctorReviews = (doctorId, page) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOCTOR_REVIEW_REQUEST,
    });

    const res = await useGetData(
      `/api/common/doctors/reviews?doctorId=${doctorId}&page=${
        page || 1
      }&size=10`
    );
    dispatch({
      type: GET_DOCTOR_REVIEW_SUCCESS,
      payload: res,
    });
    console.log(res);
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_DOCTOR_REVIEW_FAIL,
    });
  }
};

export const getMedicalsTime = () => async (dispatch) => {
  try {
    const res = await useGetData("/api/common/medicalTime");
    dispatch({
      type: GET_MEDICAL_TIME,
      payload: res.data,
    });
    console.log(res);
  } catch (err) {
    console.log(err);
  }
};

export const getLabTest = () => async (dispatch) => {
  try {
    const res = await useGetData("/api/common/testLabs");
    dispatch({
      type: GET_LAB_TEST,
      payload: res.data,
    });
    console.log(res);
  } catch (err) {
    console.log(err);
  }
};
export const getDrugItems = () => async (dispatch) => {
  try {
    const res = await useGetData("/api/common/drug");
    dispatch({
      type: GET_DRUG_ITEMS,
      payload: res.data,
    });
    console.log(res);
  } catch (err) {
    console.log(err);
  }
};

export const getLabcategory = () => async (dispatch) => {
  try {
    dispatch({
      type: LABTEST_REQUEST,
    });

    const res = await useGetData(`/api/common/lab/category`);
    dispatch({
      type: LABTEST_SUCCESS,
      payload: res.data,
    });
    console.log(res);
  } catch (err) {
    console.log(err);
    dispatch({
      type: LABTEST_FAIL,
    });
  }
};
