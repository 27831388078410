import appointmentReducer from "./appointmentReducer";
import commonReducers from "./commonReducer";
import notificationReducer from "./notificationReducer";

const rootCommonReducers = {
  appointmentReducer: appointmentReducer,
  common: commonReducers,
  notification: notificationReducer,
};

export default rootCommonReducers;
