import {
  GET_APPOINT_DOCTOR_REQUEST,
  GET_APPOINT_DOCTOR_SUCCESS,
  GET_APPOINT_DOCTOR_FAIL,
  GET_CHECK_APPOINT_REQUEST,
  GET_CHECK_APPOINT_SUCCESS,
  GET_CHECK_APPOINT_FAIL,
  CREATE_DESCRIPTION_REQUEST,
  CREATE_DESCRIPTION_SUCCESS,
  CREATE_DESCRIPTION_FAIL,
} from "../types/appointmentTypes";

const initialState = {
  doctorAppointment: [],
  checkAppointment: [],
  loading: false,
};

function doctorAppointmentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_APPOINT_DOCTOR_REQUEST:
      return { ...state, loading: true };
    case GET_APPOINT_DOCTOR_SUCCESS:
      return { ...state, doctorAppointment: action.payload, loading: false };
    case GET_APPOINT_DOCTOR_FAIL:
      return { ...state, loading: false };

    case CREATE_DESCRIPTION_REQUEST:
      return { ...state, loading: true };
    case CREATE_DESCRIPTION_SUCCESS:
      return { ...state, doctorAppointment: action.payload, loading: false };
    case CREATE_DESCRIPTION_FAIL:
      return { ...state, loading: false };

    case GET_CHECK_APPOINT_REQUEST:
      return { ...state, loading: true };
    case GET_CHECK_APPOINT_SUCCESS:
      return { ...state, checkAppointment: action.payload, loading: false };
    case GET_CHECK_APPOINT_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default doctorAppointmentReducer;
