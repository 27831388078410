import {
  CREATE_ARTICLE_REQUEST,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAIL,
  GET_ARTICLES_REQUEST,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAIL,
  GET_ARTICLE_DETAILS_REQUEST,
  GET_ARTICLE_DETAILS_SUCCESS,
  GET_ARTICLE_DETAILS_FAIL,
  DELETE_ARTICLE_REQUEST,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAIL,
  UPDATE_ARTICLE_REQUEST,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAIL,
  GET_ARTICLES_TYPES_REQUEST,
  GET_ARTICLES_TYPES_SUCCESS,
  GET_ARTICLES_TYPES_FAIL,
} from "../types/aricleTypes";

const initialState = {
  articles: [],
  articleDetails: {},
  articlesTypes: [],
  loading: false,
  actionLoading: false,
};

function doctorArticleReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ARTICLE_REQUEST:
      return { ...state, actionLoading: true };
    case CREATE_ARTICLE_SUCCESS:
      return { ...state, articles: action.payload, actionLoading: false };
    case CREATE_ARTICLE_FAIL:
      return { ...state, actionLoading: false };

    case GET_ARTICLES_REQUEST:
      return { ...state, loading: true };
    case GET_ARTICLES_SUCCESS:
      return { ...state, articles: action.payload, loading: false };
    case GET_ARTICLES_FAIL:
      return { ...state, loading: false };

    case GET_ARTICLE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_ARTICLE_DETAILS_SUCCESS:
      return { ...state, articleDetails: action.payload, loading: false };
    case GET_ARTICLE_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_ARTICLE_REQUEST:
      return { ...state, actionLoading: true };
    case DELETE_ARTICLE_SUCCESS:
      return { ...state, articles: action.payload, actionLoading: false };
    case DELETE_ARTICLE_FAIL:
      return { ...state, actionLoading: false };

    case UPDATE_ARTICLE_REQUEST:
      return { ...state, actionLoading: true };
    case UPDATE_ARTICLE_SUCCESS:
      return { ...state, articles: action.payload, actionLoading: false };
    case UPDATE_ARTICLE_FAIL:
      return { ...state, actionLoading: false };

    case GET_ARTICLES_TYPES_REQUEST:
      return { ...state, loading: true };
    case GET_ARTICLES_TYPES_SUCCESS:
      return { ...state, articlesTypes: action.payload, loading: false };
    case GET_ARTICLES_TYPES_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default doctorArticleReducer;
