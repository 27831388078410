import {
  CREATE_CATEGORY_LABTEST_REQUEST,
  CREATE_CATEGORY_LABTEST_SUCCESS,
  CREATE_CATEGORY_LABTEST_FAIL,
  GET_CATEGORY_LABTESTS_REQUEST,
  GET_CATEGORY_LABTESTS_SUCCESS,
  GET_CATEGORY_LABTESTS_FAIL,
  SPECIFIC_CATEGORY_LABTEST_REQUEST,
  SPECIFIC_CATEGORY_LABTEST_SUCCESS,
  SPECIFIC_CATEGORY_LABTEST_FAIL,
  DELETE_CATEGORY_LABTEST_REQUEST,
  DELETE_CATEGORY_LABTEST_SUCCESS,
  DELETE_CATEGORY_LABTEST_FAIL,
  UPDATE_CATEGORY_LABTEST_REQUEST,
  UPDATE_CATEGORY_LABTEST_SUCCESS,
  UPDATE_CATEGORY_LABTEST_FAIL,
} from "../types/categoryLabtestTypes";

const initialValue = {
  categoryLabtests: [],
  categoryLabtestDetails: {},
  loading: false,
};

function adminCategoryLabtestReducer(state = initialValue, action) {
  switch (action.type) {
    case CREATE_CATEGORY_LABTEST_REQUEST:
      return { ...state, loading: true };
    case CREATE_CATEGORY_LABTEST_SUCCESS:
      return { ...state, categoryLabtests: action.payload, loading: false };
    case CREATE_CATEGORY_LABTEST_FAIL:
      return { ...state, loading: false };

    case GET_CATEGORY_LABTESTS_REQUEST:
      return { ...state, loading: true };
    case GET_CATEGORY_LABTESTS_SUCCESS:
      return {
        ...state,
        categoryLabtests: action.payload,
        loading: false,
      };
    case GET_CATEGORY_LABTESTS_FAIL:
      return { ...state, loading: false };

    case SPECIFIC_CATEGORY_LABTEST_REQUEST:
      return { ...state, loading: true };
    case SPECIFIC_CATEGORY_LABTEST_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryLabtestDetails: action.payload,
      };
    case SPECIFIC_CATEGORY_LABTEST_FAIL:
      return { ...state, loading: false };

    case DELETE_CATEGORY_LABTEST_REQUEST:
      return { ...state, loading: true };
    case DELETE_CATEGORY_LABTEST_SUCCESS:
      return { ...state, loading: false, categoryLabtests: action.payload };
    case DELETE_CATEGORY_LABTEST_FAIL:
      return { ...state, loading: false };

    case UPDATE_CATEGORY_LABTEST_REQUEST:
      return { ...state, loading: true };
    case UPDATE_CATEGORY_LABTEST_SUCCESS:
      return { ...state, loading: false, categoryLabtests: action.payload };
    case UPDATE_CATEGORY_LABTEST_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminCategoryLabtestReducer;
