import React, { lazy } from "react";
import Header from "../labtest/components/header";
import { RequireLabtest } from "./RequireRoutes";
const Dashboard = lazy(() => import("../labtest/components/dashboard"));
const Login = lazy(() => import("../labtest/components/login"));
const Register = lazy(() => import("../labtest/components/register"));
const PharmacyForgotPassword = lazy(() =>
  import("../labtest/components/forgotpassword")
);
const PharmacyVerifiedCode = lazy(() =>
  import("../labtest/components/forgotpassword/VerifiedCode")
);
const PharmacyChangePassword = lazy(() =>
  import("../labtest/components/forgotpassword/ChangePassword")
);
const AdminProducts = lazy(() =>
  import("../labtest/components/products/adminproducts")
);
const MyProducts = lazy(() =>
  import("../labtest/components/products/myproducts")
);
const AddProduct = lazy(() =>
  import("../labtest/components/products/addproduct")
);
const EditProduct = lazy(() =>
  import("../labtest/components/products/editproduct")
);

const CheckPrescriptionNumber = lazy(() =>
  import("../labtest/components/orders/CheckPrescriptionNumber")
);
const CheckCodeVerify = lazy(() =>
  import("../labtest/components/orders/CheckCodeVerify")
);
const AcceptOrder = lazy(() =>
  import("../labtest/components/orders/AcceptOrder")
);
const Orders = lazy(() => import("../labtest/components/orders"));
const OrderDetails = lazy(() =>
  import("../labtest/components/orders/OrderDetails")
);

const Profile = lazy(() => import("../labtest/components/profile"));
const EditPharmacyProfile = lazy(() =>
  import("../labtest/components/profile/EditPharmacyProfile")
);
const EditMyProfile = lazy(() =>
  import("../labtest/components/profile/EditMyProfile")
);
const WorkTime = lazy(() => import("../labtest/components/worktime"));

const LabtestRoutes = [
  {
    path: "labtest/register",
    component: <Register />,
  },
  {
    path: "labtest/login",
    component: <Login />,
  },
  {
    path: "/labtest/forgot-password",
    component: <PharmacyForgotPassword />,
  },
  {
    path: "/labtest/verified-code",
    component: <PharmacyVerifiedCode />,
  },
  {
    path: "/labtest/change-password",
    component: <PharmacyChangePassword />,
  },
  {
    path: "labtest",
    component: (
      <RequireLabtest>
        <Header />
        <Dashboard />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/admin-products",
    component: (
      <RequireLabtest>
        <Header />
        <AdminProducts />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/my-products",
    component: (
      <RequireLabtest>
        <Header />
        <MyProducts />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/add-product",
    component: (
      <RequireLabtest>
        <Header />
        <AddProduct />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/edit-product/:id",
    component: (
      <RequireLabtest>
        <Header />
        <EditProduct />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/order/add-order",
    component: (
      <RequireLabtest>
        <Header />
        <CheckPrescriptionNumber />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/order/checkCodeVerify",
    component: (
      <RequireLabtest>
        <Header />
        <CheckCodeVerify />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/orders",
    component: (
      <RequireLabtest>
        <Header />
        <Orders />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/order-details/:id",
    component: (
      <RequireLabtest>
        <Header />
        <OrderDetails />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/profile",
    component: (
      <RequireLabtest>
        <Header />
        <Profile />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/profile/edit-labtest",
    component: (
      <RequireLabtest>
        <Header />
        <EditPharmacyProfile />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/profile/edit-my-profile",
    component: (
      <RequireLabtest>
        <Header />
        <EditMyProfile />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/order/accept-order",
    component: (
      <RequireLabtest>
        <Header />
        <AcceptOrder />
      </RequireLabtest>
    ),
  },
  {
    path: "/labtest/work-time",
    component: (
      <RequireLabtest>
        <Header />
        <WorkTime />
      </RequireLabtest>
    ),
  },
];

export default LabtestRoutes;
