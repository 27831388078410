import {
  LOGIN_DOCTOR_REQUEST,
  LOGIN_DOCTOR_SUCCESS,
  LOGIN_DOCTOR_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  VERIFIED_CODE_REQUEST,
  VERIFIED_CODE_SUCCESS,
  VERIFIED_CODE_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "../types/authTypes";
import {
  MY_BASIC_INFO_REQUEST,
  MY_BASIC_INFO_SUCCESS,
  MY_BASIC_INFO_FAIL,
  SET_SOCIAL_MEDIA_REQUEST,
  SET_SOCIAL_MEDIA_SUCCESS,
  SET_SOCIAL_MEDIA_FAIL,
} from "../types/profileTypes";
import { USER_LOGOUT_SUCCESS } from "../../common/types/commonTypes";

const initialState = {
  doctorData: JSON.parse(localStorage.getItem("user")) || {},
  loading: false,
  actionLoading: false,
};

function doctorAuthReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGOUT_SUCCESS:
      return { ...state, doctorData: {} };

    case LOGIN_DOCTOR_REQUEST:
      return { ...state, actionLoading: true };
    case LOGIN_DOCTOR_SUCCESS:
      return { ...state, doctorData: action.payload, actionLoading: false };
    case LOGIN_DOCTOR_FAIL:
      return { ...state, actionLoading: false };

    case FORGOT_PASSWORD_REQUEST:
      return { ...state, actionLoading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, doctorData: action.payload, actionLoading: false };
    case FORGOT_PASSWORD_FAIL:
      return { ...state, actionLoading: false };

    case VERIFIED_CODE_REQUEST:
      return { ...state, actionLoading: true };
    case VERIFIED_CODE_SUCCESS:
      return { ...state, doctorData: action.payload, actionLoading: false };
    case VERIFIED_CODE_FAIL:
      return { ...state, actionLoading: false };

    case CHANGE_PASSWORD_REQUEST:
      return { ...state, actionLoading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, doctorData: action.payload, actionLoading: false };
    case CHANGE_PASSWORD_FAIL:
      return { ...state, actionLoading: false };

    case MY_BASIC_INFO_REQUEST:
      return { ...state, doctorData: action.payload, actionLoading: true };
    case MY_BASIC_INFO_SUCCESS:
      return { ...state, doctorData: action.payload, actionLoading: false };
    case MY_BASIC_INFO_FAIL:
      return { ...state, doctorData: action.payload, actionLoading: false };

    case SET_SOCIAL_MEDIA_REQUEST:
      return { ...state, doctorData: action.payload, actionLoading: true };
    case SET_SOCIAL_MEDIA_SUCCESS:
      return { ...state, doctorData: action.payload, actionLoading: false };
    case SET_SOCIAL_MEDIA_FAIL:
      return { ...state, doctorData: action.payload, actionLoading: false };

    default:
      return state;
  }
}

export default doctorAuthReducer;
