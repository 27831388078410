import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import logo from "../../assets/images/logo.png";
import {
  avatar01,
  avatar02,
  avatar03,
  avatar05,
  avatar06,
  logo,
  logoSmall,
} from "../imagepath";
import FeatherIcon from "feather-icons-react";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../redux/common/actions/commonActions";
import Search from "./Search";
import Notifications from "./Notifications";

const Header = () => {
  const [task, settask] = useState(true);
  const [task1, settask1] = useState(true);

  const handletheme = () => {
    document.body.classList.toggle("darkmode");
    settask(!task);
    settask1(!task1);
  };
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };
  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle("slide-nav");
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(userLogout(() => navigate("/pharmacyadmin/login")));
  };

  const user = JSON.parse(localStorage.getItem("user")) || {};

  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div className="header-left">
          <Link to="/admin" className="logo">
            <img src={logo} alt="Logo" />
          </Link>
          <Link to="/admin" className="logo logo-small">
            <img src={logoSmall} alt="Logo" width={30} height={30} />
          </Link>
          <Link to="#" id="toggle_btn" onClick={handlesidebar}>
            <FeatherIcon icon="chevrons-left" />
            {/* <i className="feather-chevrons-left" /> */}
          </Link>
        </div>
        {/* /Logo */}
        {/* Search */}
        <Search />
        {/* /Search */}
        {/* Mobile Menu Toggle */}
        <Link
          to="#"
          className="mobile_btn"
          id="mobile_btn"
          onClick={() => handlesidebarmobilemenu()}
        >
          <i className="fas fa-bars" />
        </Link>
        {/* /Mobile Menu Toggle */}
        {/* Header Menu */}
        <ul className="nav nav-tabs user-menu">
          {/* Flag */}
          <li className="nav-item">
            <Link to="#" id="dark-mode-toggle" className="dark-mode-toggle">
              <i
                onClick={handletheme}
                className={` light-mode ${task ? "active" : ""}`}
              >
                {" "}
                <FeatherIcon icon="sun" />
              </i>
              <i
                onClick={handletheme}
                className={`dark-mode ${task1 ? "" : "active"}`}
              >
                {" "}
                <FeatherIcon icon="moon" />
              </i>
            </Link>
          </li>
          {/* /Flag */}
          {/* Notifications */}
          <Notifications />
          {/* /Notifications */}
          {/* User Menu */}
          <li className="nav-item dropdown main-drop">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span className="user-img">
                <img
                  src={user?.pharmacy?.ownerPharmacy?.photo || avatar01}
                  alt=""
                />
                <span className="status online" />
              </span>
            </Link>
            <div className="dropdown-menu">
              <div className="user-header">
                <div className="avatar avatar-sm">
                  <img
                    src={user?.pharmacy?.ownerPharmacy.photo || avatar01}
                    alt="User Image"
                    className="avatar-img rounded-circle"
                  />
                </div>
                <div className="user-text">
                  {user.name}
                  <p className="text-muted mb-0">Pharmacy Admin</p>
                </div>
              </div>
              <Link className="dropdown-item" to="/pharmacyadmin/profile">
                <i className="me-1">
                  <FeatherIcon icon="user" />
                </i>
                My Profile
              </Link>
              {/* <Link className="dropdown-item" to="/pharmacyadmin/edit-profile">
                <i className="me-1">
                  <FeatherIcon icon="edit" />
                </i>{" "}
                Edit Profile
              </Link> */}
              <hr className="my-0 ms-2 me-2" />
              <Link className="dropdown-item" to="#0" onClick={handleLogout}>
                <i className="me-1">
                  <FeatherIcon icon="log-out" />
                </i>
                Logout
              </Link>
            </div>
          </li>
          {/* /User Menu */}
        </ul>
        {/* /Header Menu */}
      </div>
      {/* /Header */}
    </>
  );
};

export default Header;
