import {
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  SPECIFIC_CATEGORY_REQUEST,
  SPECIFIC_CATEGORY_SUCCESS,
  SPECIFIC_CATEGORY_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
} from "../types/categoriesTypes";

const initialValue = {
  categories: [],
  categoryDetails: {},
  loading: false,
};

function adminCategoryReducer(state = initialValue, action) {
  switch (action.type) {
    case CREATE_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case CREATE_CATEGORY_SUCCESS:
      return { ...state, categories: action.payload, loading: false };
    case CREATE_CATEGORY_FAIL:
      return { ...state, loading: false };

    case GET_CATEGORIES_REQUEST:
      return { ...state, loading: true };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case GET_CATEGORIES_FAIL:
      return { ...state, loading: false };

    case SPECIFIC_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case SPECIFIC_CATEGORY_SUCCESS:
      return { ...state, loading: false, categoryDetails: action.payload };
    case SPECIFIC_CATEGORY_FAIL:
      return { ...state, loading: false };

    case DELETE_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case DELETE_CATEGORY_SUCCESS:
      return { ...state, loading: false, categories: action.payload };
    case DELETE_CATEGORY_FAIL:
      return { ...state, loading: false };

    case UPDATE_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case UPDATE_CATEGORY_SUCCESS:
      return { ...state, loading: false, categories: action.payload };
    case UPDATE_CATEGORY_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminCategoryReducer;
