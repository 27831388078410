import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export function RequireMedical({ children }) {
  const {
    medicalData: { role },
  } = useSelector((state) => state.medicalAuth);
  if (!(role === "medical")) {
    return <Navigate to="/medical/login" />;
  }
  return children ? children : <Outlet />;
}

export function RequireAdmin({ children }) {
  if (!(JSON.parse(localStorage.getItem("user"))?.role === "admin")) {
    return <Navigate to="/admin/login" />;
  }
  return children;
}

export function RequireDoctor({ children }) {
  if (!(JSON.parse(localStorage.getItem("user"))?.role === "doctor")) {
    return <Navigate to="/doctor/login" />;
  }
  return children;
}

export function RequireLabtest({ children }) {
  if (!(JSON.parse(localStorage.getItem("user"))?.role === "labtest")) {
    return <Navigate to="/labtest/login" />;
  }
  return children;
}
