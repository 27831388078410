import {
  CREATE_DOCTOR_REQUEST,
  CREATE_DOCTOR_SUCCESS,
  CREATE_DOCTOR_FAIL,
  GET_DOCTORS_REQUEST,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_FAIL,
  GET_SPECIFIC_DOCTOR_REQUEST,
  GET_SPECIFIC_DOCTOR_SUCCESS,
  GET_SPECIFIC_DOCTOR_FAIL,
  DELETE_DOCTOR_REQUEST,
  DELETE_DOCTOR_SUCCESS,
  DELETE_DOCTOR_FAIL,
  UPDATE_DOCTOR_REQUEST,
  UPDATE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAIL,
  CHANGE_STATUS_SUCCESS,
} from "../types/doctorTypes";

import {
  Get_DOCTOR_PROFILE_REQUEST,
  Get_DOCTOR_PROFILE_SUCCESS,
  Get_DOCTOR_PROFILE_FAIL,
  GET_DOCTOR_DAYS_REQUEST,
  GET_DOCTOR_DAYS_SUCCESS,
  GET_DOCTOR_DAYS_FAIL,
  GET_DOCTOR_TIMES_REQUEST,
  GET_DOCTOR_TIMES_SUCCESS,
  GET_DOCTOR_TIMES_FAIL,
  SET_DOCTOR_CLINIC,
  GET_APPOINT_DOCTOR_REQUEST,
  GET_APPOINT_DOCTOR_SUCCESS,
  GET_APPOINT_DOCTOR_FAIL,
} from "../types/doctorProfileTypes";

const initialState = {
  doctors: {},
  doctorProfile: {},
  docDetails: "",
  doctorDays: [],
  doctorTimes: [],
  doctorAppointment: [],
  loading: false,
  actionLoading: false,
};

function medicalDoctorReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_DOCTOR_REQUEST:
      return { ...state, actionLoading: true };
    case CREATE_DOCTOR_SUCCESS:
      return { ...state, doctors: action.payload, actionLoading: false };
    case CREATE_DOCTOR_FAIL:
      return { ...state, actionLoading: false };

    case Get_DOCTOR_PROFILE_REQUEST:
      return { ...state, loading: true };
    case Get_DOCTOR_PROFILE_SUCCESS:
      return { ...state, doctorProfile: action.payload, loading: false };
    case Get_DOCTOR_PROFILE_FAIL:
      return { ...state, loading: false };

    case CHANGE_STATUS_SUCCESS:
      return { ...state, doctorProfile: action.payload, loading: false };

    case SET_DOCTOR_CLINIC:
      return { ...state, doctorProfile: action.payload, loading: false };

    case GET_DOCTORS_REQUEST:
      return { ...state, loading: true };
    case GET_DOCTORS_SUCCESS:
      return { ...state, doctors: action.payload, loading: false };
    case GET_DOCTORS_FAIL:
      return { ...state, loading: false };

    case GET_SPECIFIC_DOCTOR_REQUEST:
      return { ...state, loading: true };
    case GET_SPECIFIC_DOCTOR_SUCCESS:
      return { ...state, docDetails: action.payload, loading: false };
    case GET_SPECIFIC_DOCTOR_FAIL:
      return { ...state, loading: false };

    case DELETE_DOCTOR_REQUEST:
      return { ...state, loading: true };
    case DELETE_DOCTOR_SUCCESS:
      return { ...state, doctors: action.payload, loading: false };
    case DELETE_DOCTOR_FAIL:
      return { ...state, loading: false };

    case UPDATE_DOCTOR_REQUEST:
      return { ...state, actionLoading: true };
    case UPDATE_DOCTOR_SUCCESS:
      return { ...state, doctors: action.payload, actionLoading: false };
    case UPDATE_DOCTOR_FAIL:
      return { ...state, actionLoading: false };

    case GET_DOCTOR_DAYS_REQUEST:
      return { ...state, loading: true };
    case GET_DOCTOR_DAYS_SUCCESS:
      return { ...state, doctorDays: action.payload, loading: false };
    case GET_DOCTOR_DAYS_FAIL:
      return { ...state, loading: false };

    case GET_DOCTOR_TIMES_REQUEST:
      return { ...state, loading: true };
    case GET_DOCTOR_TIMES_SUCCESS:
      return { ...state, doctorTimes: action.payload, loading: false };
    case GET_DOCTOR_TIMES_FAIL:
      return { ...state, loading: false };

    case GET_APPOINT_DOCTOR_REQUEST:
      return { ...state, loading: true };
    case GET_APPOINT_DOCTOR_SUCCESS:
      return { ...state, loading: false, doctorAppointment: action.payload };
    case GET_APPOINT_DOCTOR_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default medicalDoctorReducer;
