export const GET_PATIENTS_REQUEST = "GET_PATIENTS_REQUEST";
export const GET_PATIENTS_SUCCESS = "GET_PATIENTS_SUCCESS";
export const GET_PATIENTS_FAIL = "GET_PATIENTS_FAIL";

export const GET_PATIENT_DETAILS_REQUEST = "GET_PATIENT_DETAILS_REQUEST";
export const GET_PATIENT_DETAILS_SUCCESS = "GET_PATIENT_DETAILS_SUCCESS";
export const GET_PATIENT_DETAILS_FAIL = "GET_PATIENT_DETAILS_FAIL";

export const GET_PATIENT_INFO_REQUEST = "GET_PATIENT_INFO_REQUEST";
export const GET_PATIENT_INFO_SUCCESS = "GET_PATIENT_INFO_SUCCESS";
export const GET_PATIENT_INFO_FAIL = "GET_PATIENT_INFO_FAIL";

export const SET_PATIENT_INFO_REQUEST = "SET_PATIENT_INFO_REQUEST";
export const SET_PATIENT_INFO_SUCCESS = "SET_PATIENT_INFO_SUCCESS";
export const SET_PATIENT_INFO_FAIL = "SET_PATIENT_INFO_FAIL";

export const GET_BLOOD_TYPES_REQUEST = "GET_BLOOD_TYPES_REQUEST";
export const GET_BLOOD_TYPES_SUCCESS = "GET_BLOOD_TYPES_SUCCESS";
export const GET_BLOOD_TYPES_FAIL = "GET_BLOOD_TYPES_FAIL";
