import {
  CHECK_PEN_CODE_REQUEST,
  CHECK_PEN_CODE_SUCCESS,
  CHECK_PEN_CODE_FAIL,
  GET_PRESCRIPTIONS_REQUEST,
  GET_PRESCRIPTIONS_SUCCESS,
  GET_PRESCRIPTIONS_FAIL,
  GET_PRES_DETAILS_REQUEST,
  GET_PRES_DETAILS_SUCCESS,
  GET_PRES_DETAILS_FAIL,
} from "../types/prescriptionTypes";

const initialValue = {
  prescDetails: [],
  prescriptions: [],
  orderDetails: {},
  loading: false,
};

function pharmacyPrescriptionReducer(state = initialValue, action) {
  switch (action.type) {
    case CHECK_PEN_CODE_REQUEST:
      return { ...state, loading: true };
    case CHECK_PEN_CODE_SUCCESS:
      return { ...state, prescDetails: action.payload, loading: false };
    case CHECK_PEN_CODE_FAIL:
      return { ...state, loading: false };

    case GET_PRESCRIPTIONS_REQUEST:
      return { ...state, loading: true };
    case GET_PRESCRIPTIONS_SUCCESS:
      return { ...state, prescriptions: action.payload, loading: false };
    case GET_PRESCRIPTIONS_FAIL:
      return { ...state, loading: false };

    case GET_PRES_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_PRES_DETAILS_SUCCESS:
      return { ...state, prescDetails: action.payload, loading: false };
    case GET_PRES_DETAILS_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default pharmacyPrescriptionReducer;
