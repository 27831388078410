import { useGetData } from "../../../hooks/api/useGetData";
import {
  GET_DOCTOR_CHAT_REQUEST,
  GET_DOCTOR_CHAT_SUCCESS,
  GET_DOCTOR_CHAT_FAIL,
} from "../types/chatTypes";

export const getDoctorChat = (userName) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOCTOR_CHAT_REQUEST,
      user: userName,
    });

    const res = await useGetData(
      `/api/doctors/chats?userName=${userName || 0}`
    );
    dispatch({
      type: GET_DOCTOR_CHAT_SUCCESS,
      payload: res?.data,
    });
    console.log(res);
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_DOCTOR_CHAT_FAIL,
    });
  }
};
