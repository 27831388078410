import labtestAuthReducer from "./authReducer";
import labtestProductsReducer from "./productsReducer";
import labtestOrderReducer from "./orderReducer";
import labtestWorkTimeReducer from "./workTimeReducer";

const rootLabTestReducers = {
  labtestAuth: labtestAuthReducer,
  labtestOrder: labtestOrderReducer,
  labtestProducts: labtestProductsReducer,
  labtestWorkTime: labtestWorkTimeReducer,
};

export default rootLabTestReducers;
