import {
  CREATE_GALLERY_REQUEST,
  CREATE_GALLERY_SUCCESS,
  CREATE_GALLERY_FAIL,
  GET_GALLERIES_REQUEST,
  GET_GALLERIES_SUCCESS,
  GET_GALLERIES_FAIL,
  GET_SPECIFIC_GALLERY_REQUEST,
  GET_SPECIFIC_GALLERY_SUCCESS,
  GET_SPECIFIC_GALLERY_FAIL,
  DELETE_GALLERY_REQUEST,
  DELETE_GALLERY_SUCCESS,
  DELETE_GALLERY_FAIL,
  UPDATE_GALLERY_REQUEST,
  UPDATE_GALLERY_SUCCESS,
  UPDATE_GALLERY_FAIL,
} from "../types/galleryTypes";

const initialState = {
  galleries: {},
  galleryDetails: "",
  loading: false,
  actionLoading: false,
};

function doctorGalleryReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_GALLERY_REQUEST:
      return { ...state, actionLoading: true };
    case CREATE_GALLERY_SUCCESS:
      return { ...state, galleries: action.payload, actionLoading: false };
    case CREATE_GALLERY_FAIL:
      return { ...state, actionLoading: false };

    case GET_GALLERIES_REQUEST:
      return { ...state, loading: true };
    case GET_GALLERIES_SUCCESS:
      return { ...state, galleries: action.payload, loading: false };
    case GET_GALLERIES_FAIL:
      return { ...state, loading: false };

    case GET_SPECIFIC_GALLERY_REQUEST:
      return { ...state, loading: true };
    case GET_SPECIFIC_GALLERY_SUCCESS:
      return { ...state, galleryDetails: action.payload, loading: false };
    case GET_SPECIFIC_GALLERY_FAIL:
      return { ...state, loading: false };

    case DELETE_GALLERY_REQUEST:
      return { ...state, actionLoading: true };
    case DELETE_GALLERY_SUCCESS:
      return { ...state, galleries: action.payload, actionLoading: false };
    case DELETE_GALLERY_FAIL:
      return { ...state, actionLoading: false };

    case UPDATE_GALLERY_REQUEST:
      return { ...state, actionLoading: true };
    case UPDATE_GALLERY_SUCCESS:
      return { ...state, galleries: action.payload, actionLoading: false };
    case UPDATE_GALLERY_FAIL:
      return { ...state, actionLoading: false };

    default:
      return state;
  }
}

export default doctorGalleryReducer;
