import {
  REGISTER_MEDICAL_REQUEST,
  REGISTER_MEDICAL_SUCCESS,
  REGISTER_MEDICAL_FAIL,
  LOGIN_MEDICAL_REQUEST,
  LOGIN_MEDICAL_SUCCESS,
  LOGIN_MEDICAL_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  VERIFIED_CODE_REQUEST,
  VERIFIED_CODE_SUCCESS,
  VERIFIED_CODE_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_MEDICAL_DASHBOARD_REQUEST,
  GET_MEDICAL_DASHBOARD_SUCCESS,
  GET_MEDICAL_DASHBOARD_FAIL,
  GET_MEDICAL_SEARCH_REQUEST,
  GET_MEDICAL_SEARCH_SUCCESS,
  GET_MEDICAL_SEARCH_FAIL,
  EDIT_MEDICAL_PROFILE_REQUEST,
  EDIT_MEDICAL_PROFILE_SUCCESS,
  EDIT_MEDICAL_PROFILE_FAIL,
  GET_ARTICLES_REQUEST,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAIL,
} from "../types/authTypes";
import { USER_LOGOUT_SUCCESS } from "../../common/types/commonTypes";
import {
  SET_SOCIAL_MEDIA_REQUEST,
  SET_SOCIAL_MEDIA_SUCCESS,
  SET_SOCIAL_MEDIA_FAIL,
} from "../types/socialMediaTypes";

const initialState = {
  medicalData: JSON.parse(localStorage.getItem("user")) || {},
  medicalDashboard: [],
  medicalSearch: {},
  articles: [],
  loading: false,
  actionLoading: false,
};

function medicalAuthReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGOUT_SUCCESS:
      return { ...state, adminData: {} };

    case REGISTER_MEDICAL_REQUEST:
      return { ...state, actionLoading: true };
    case REGISTER_MEDICAL_SUCCESS:
      return { ...state, medicalData: action.payload, actionLoading: false };
    case REGISTER_MEDICAL_FAIL:
      return { ...state, actionLoading: false };

    case LOGIN_MEDICAL_REQUEST:
      return { ...state, actionLoading: true };
    case LOGIN_MEDICAL_SUCCESS:
      return { ...state, medicalData: action.payload, actionLoading: false };
    case LOGIN_MEDICAL_FAIL:
      return { ...state, actionLoading: false };

    case FORGOT_PASSWORD_REQUEST:
      return { ...state, actionLoading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, medicalData: action.payload, actionLoading: false };
    case FORGOT_PASSWORD_FAIL:
      return { ...state, actionLoading: false };

    case VERIFIED_CODE_REQUEST:
      return { ...state, actionLoading: true };
    case VERIFIED_CODE_SUCCESS:
      return { ...state, medicalData: action.payload, actionLoading: false };
    case VERIFIED_CODE_FAIL:
      return { ...state, actionLoading: false };

    case CHANGE_PASSWORD_REQUEST:
      return { ...state, actionLoading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, medicalData: action.payload, actionLoading: false };
    case CHANGE_PASSWORD_FAIL:
      return { ...state, actionLoading: false };

    case EDIT_MEDICAL_PROFILE_REQUEST:
      return { ...state, actionLoading: true };
    case EDIT_MEDICAL_PROFILE_SUCCESS:
      return { ...state, medicalData: action.payload, actionLoading: false };
    case EDIT_MEDICAL_PROFILE_FAIL:
      return { ...state, actionLoading: false };

    case GET_PROFILE_REQUEST:
      return { ...state, loading: true };
    case GET_PROFILE_SUCCESS:
      return { ...state, medicalData: action.payload, loading: false };
    case GET_PROFILE_FAIL:
      return { ...state, loading: false };

    case GET_MEDICAL_DASHBOARD_REQUEST:
      return { ...state, loading: true };
    case GET_MEDICAL_DASHBOARD_SUCCESS:
      return { ...state, medicalDashboard: action.payload, loading: false };
    case GET_MEDICAL_DASHBOARD_FAIL:
      return { ...state, loading: false };

    case GET_MEDICAL_SEARCH_REQUEST:
      return { ...state, loading: true };
    case GET_MEDICAL_SEARCH_SUCCESS:
      return { ...state, medicalSearch: action.payload, loading: false };
    case GET_MEDICAL_SEARCH_FAIL:
      return { ...state, loading: false };

    case SET_SOCIAL_MEDIA_REQUEST:
      return { ...state, actionLoading: true };
    case SET_SOCIAL_MEDIA_SUCCESS:
      return { ...state, actionLoading: false, medicalData: action.payload };
    case SET_SOCIAL_MEDIA_FAIL:
      return { ...state, actionLoading: false };

    case GET_ARTICLES_REQUEST:
      return { ...state, loading: true };
    case GET_ARTICLES_SUCCESS:
      return { ...state, loading: false, articles: action.payload };
    case GET_ARTICLES_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default medicalAuthReducer;
