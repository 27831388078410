import { onMessage, getToken } from "firebase/messaging";
import { messaging } from "./firebase";
// import { onBackgroundMessage } from "firebase/messaging";

// Request permission for receiving notifications
export const requestNotificationPermission = async () => {
  try {
    await Notification.requestPermission();
    console.log("Notification permission granted.");
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};

// Get the registration token for the current device
export const handleGetToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BOf5UNhEMXN-g168NjlQhTbTQOjEkuaA54YaZli2xDooGS9HKmWb-JJz5yB9a87ChFvho68dDgkSpuLdqD12vGM",
    });
    console.log("FCM registration token:", token);
  } catch (error) {
    console.error("Error getting FCM registration token:", error);
  }
};

export const handleGetMessages = () => {
  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
  });
};

export const requestForToken = async () => {
  return getToken(messaging, {
    vapidKey:
      "BOf5UNhEMXN-g168NjlQhTbTQOjEkuaA54YaZli2xDooGS9HKmWb-JJz5yB9a87ChFvho68dDgkSpuLdqD12vGM",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
