import React, { lazy } from "react";
import { RequireMedical } from "./RequireRoutes";
import Header from "../client/components/header";
import Backbtn from "../client/components/backbtn";

const Register = lazy(() => import("../client/components/medical/register"));
const Login = lazy(() => import("../client/components/medical/login"));
const DoctorForgotPassword = lazy(() =>
  import("../client/components/medical/forgot-password")
);
const DoctorVerifiedCode = lazy(() =>
  import("../client/components/medical/forgot-password/VerifiedCode")
);
const DoctorChangePassword = lazy(() =>
  import("../client/components/medical/forgot-password/ChangePassword")
);
const Dashboard = lazy(() => import("../client/components/medical/dashboard"));
const ProfileSetting = lazy(() =>
  import("../client/components/medical/profilesetting")
);
const Appointments = lazy(() =>
  import("../client/components/medical/appointments")
);
const MyPatients = lazy(() =>
  import("../client/components/medical/mypatients")
);
const MyDoctors = lazy(() => import("../client/components/medical/mydoctors"));
const DoctorProfile = lazy(() =>
  import("../client/components/medical/mydoctors/doctorprofile")
);
const AvailableTiming = lazy(() =>
  import("../client/components/medical/availabletimings")
);
const Invoice = lazy(() =>
  import("../client/components/medical/invoices/invoices")
);
const InvoiceView = lazy(() =>
  import("../client/components/medical/invoices/view")
);
const SocialMedia = lazy(() =>
  import("../client/components/medical/socialmedia")
);
const DoctorChangePasswword = lazy(() =>
  import("../client/components/medical/password")
);
const PatientProfile = lazy(() =>
  import("../client/components/medical/patientprofile")
);
const AppointmentDetails = lazy(() =>
  import("../client/components/medical/appointments/AppointmentDetails")
);
const PrescriptionDetails = lazy(() =>
  import("../client/components/medical/pescription/PrescriptionDetails")
);
const Articles = lazy(() => import("../client/components/medical/article"));
const Departments = lazy(() =>
  import("../client/components/medical/departments")
);
const Services = lazy(() => import("../client/components/medical/services"));

const MedicalRoutes = [
  {
    path: "/medical",
    component: (
      <RequireMedical>
        <Header />
        <Dashboard />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/register",
    component: <Register />,
  },
  {
    path: "/medical/login",
    component: <Login />,
  },
  {
    path: "/medical/forgot-password",
    component: <DoctorForgotPassword />,
  },
  {
    path: "/medical/verified-code",
    component: <DoctorVerifiedCode />,
  },
  {
    path: "/medical/change-password",
    component: <DoctorChangePassword />,
  },
  {
    path: "/medical/profile-setting",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <ProfileSetting />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/appointments",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <Appointments />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/my-patients",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <MyPatients />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/my-doctors",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <MyDoctors />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/doctors/:id",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <DoctorProfile />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/available-timing",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <AvailableTiming />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/invoice",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <Invoice />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/invoice-view/:id",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <InvoiceView />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/medical-change-passwword",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <DoctorChangePasswword />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/social-media",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <SocialMedia />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/patient-profile/:id",
    component: (
      <RequireMedical>
        <PatientProfile />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/appointments/:id",
    component: (
      <RequireMedical>
        <Header />
        <AppointmentDetails />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/prescription-details/:id",
    component: (
      <RequireMedical>
        <Header />
        <PrescriptionDetails />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/articles",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <Articles />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/departments",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <Departments />
      </RequireMedical>
    ),
  },
  {
    path: "/medical/services",
    component: (
      <RequireMedical>
        <Header />
        <Backbtn />
        <Services />
      </RequireMedical>
    ),
  },
];

export default MedicalRoutes;
