export const CREATE_PRESCRIPTION_REQUEST = "CREATE_PRESCRIPTION_REQUEST";
export const CREATE_PRESCRIPTION_SUCCESS = "CREATE_PRESCRIPTION_SUCCESS";
export const CREATE_PRESCRIPTION_FAIL = "CREATE_PRESCRIPTION_FAIL";

export const GET_PRESCRIPTIONS_REQUEST = "GET_PRESCRIPTIONS_REQUEST";
export const GET_PRESCRIPTIONS_SUCCESS = "GET_PRESCRIPTIONS_SUCCESS";
export const GET_PRESCRIPTIONS_FAIL = "GET_PRESCRIPTIONS_FAIL";

export const GET_PRES_DETAILS_REQUEST = "GET_PRES_DETAILS_REQUEST";
export const GET_PRES_DETAILS_SUCCESS = "GET_PRES_DETAILS_SUCCESS";
export const GET_PRES_DETAILS_FAIL = "GET_PRES_DETAILS_FAIL";

export const DELETE_PRESCRIPTION_REQUEST = "DELETE_PRESCRIPTION_REQUEST";
export const DELETE_PRESCRIPTION_SUCCESS = "DELETE_PRESCRIPTION_SUCCESS";
export const DELETE_PRESCRIPTION_FAIL = "DELETE_PRESCRIPTION_FAIL";

export const UPDATE_PRESCRIPTION_REQUEST = "UPDATE_PRESCRIPTION_REQUEST";
export const UPDATE_PRESCRIPTION_SUCCESS = "UPDATE_PRESCRIPTION_SUCCESS";
export const UPDATE_PRESCRIPTION_FAIL = "UPDATE_PRESCRIPTION_FAIL";
