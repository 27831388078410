export const GET_APPOINTMENTS_REQUEST = "GET_APPOINTMENTS_REQUEST";
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";
export const GET_APPOINTMENTS_FAIL = "GET_APPOINTMENTS_FAIL";

export const GET_APPOINT_DETAILS_REQUEST = "GET_APPOINT_DETAILS_REQUEST";
export const GET_APPOINT_DETAILS_SUCCESS = "GET_APPOINT_DETAILS_SUCCESS";
export const GET_APPOINT_DETAILS_FAIL = "GET_APPOINT_DETAILS_FAIL";

export const GET_COMPLETED_APPOINTMENTS_REQUEST =
  "GET_COMPLETED_APPOINTMENTS_REQUEST";
export const GET_COMPLETED_APPOINTMENTS_SUCCESS =
  "GET_COMPLETED_APPOINTMENTS_SUCCESS";
export const GET_COMPLETED_APPOINTMENTS_FAIL =
  "GET_COMPLETED_APPOINTMENTS_FAIL";

export const MEDICAL_CHANGE_APPOINTMENT_REQUEST =
  "MEDICAL_CHANGE_APPOINTMENT_REQUEST";
export const MEDICAL_CHANGE_APPOINTMENT_SUCCESS =
  "MEDICAL_CHANGE_APPOINTMENT_SUCCESS";
export const MEDICAL_CHANGE_APPOINTMENT_FAIL =
  "MEDICAL_CHANGE_APPOINTMENT_FAIL";

export const DOCTOR_CHANGE_APPOINTMENT_REQUEST =
  "DOCTOR_CHANGE_APPOINTMENT_REQUEST";
export const DOCTOR_CHANGE_APPOINTMENT_SUCCESS =
  "DOCTOR_CHANGE_APPOINTMENT_SUCCESS";
export const DOCTOR_CHANGE_APPOINTMENT_FAIL = "DOCTOR_CHANGE_APPOINTMENT_FAIL";

export const ADD_APPOINT_DESC_REQUEST = "ADD_APPOINT_DESC_REQUEST";
export const ADD_APPOINT_DESC_SUCCESS = "ADD_APPOINT_DESC_SUCCESS";
export const ADD_APPOINT_DESC_FAIL = "ADD_APPOINT_DESC_FAIL";
