import {
  CREATE_TOPIC_REQUEST,
  CREATE_TOPIC_SUCCESS,
  CREATE_TOPIC_FAIL,
  GET_TOPICS_REQUEST,
  GET_TOPICS_SUCCESS,
  GET_TOPICS_FAIL,
  GET_TOPIC_DETAILS_REQUEST,
  GET_TOPIC_DETAILS_SUCCESS,
  GET_TOPIC_DETAILS_FAIL,
  DELETE_TOPIC_REQUEST,
  DELETE_TOPIC_SUCCESS,
  DELETE_TOPIC_FAIL,
  UPDATE_TOPIC_REQUEST,
  UPDATE_TOPIC_SUCCESS,
  UPDATE_TOPIC_FAIL,
} from "../types/topicTypes";

const initialState = {
  topics: [],
  topicDetails: "",
  loading: false,
};

function adminTopicReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_TOPIC_REQUEST:
      return { ...state, loading: true };
    case CREATE_TOPIC_SUCCESS:
      return { ...state, topics: action.payload, loading: false };
    case CREATE_TOPIC_FAIL:
      return { ...state, loading: false };

    case GET_TOPICS_REQUEST:
      return { ...state, loading: true };
    case GET_TOPICS_SUCCESS:
      return { ...state, topics: action.payload, loading: false };
    case GET_TOPICS_FAIL:
      return { ...state, loading: false };

    case GET_TOPIC_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_TOPIC_DETAILS_SUCCESS:
      return { ...state, topicDetails: action.payload, loading: false };
    case GET_TOPIC_DETAILS_FAIL:
      return { ...state, loading: false };

    case DELETE_TOPIC_REQUEST:
      return { ...state, loading: true };
    case DELETE_TOPIC_SUCCESS:
      return { ...state, topics: action.payload, loading: false };
    case DELETE_TOPIC_FAIL:
      return { ...state, loading: false };

    case UPDATE_TOPIC_REQUEST:
      return { ...state, loading: true };
    case UPDATE_TOPIC_SUCCESS:
      return { ...state, topics: action.payload, loading: false };
    case UPDATE_TOPIC_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminTopicReducer;
