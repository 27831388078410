import {
  REGISTER_ADMIN_REQUEST,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_FAIL,
  LOGIN_ADMIN_REQUEST,
  LOGIN_ADMIN_SUCCESS,
  LOGIN_ADMIN_FAIL,
  GET_ADMIN_DASHBOARD_REQUEST,
  GET_ADMIN_DASHBOARD_SUCCESS,
  GET_ADMIN_DASHBOARD_FAIL,
} from "../types/authTypes";
import { USER_LOGOUT_SUCCESS } from "../../common/types/commonTypes";

const initialState = {
  adminData: JSON.parse(localStorage.getItem("user")) || {},
  adminDashboard: {},
  loading: false,
};

function adminAuthReducers(state = initialState, action) {
  switch (action.type) {
    case USER_LOGOUT_SUCCESS:
      return { ...state, adminData: {} };

    case REGISTER_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_ADMIN_SUCCESS:
      return {
        ...state,
        adminData: action.payload,
        loading: false,
      };
    case REGISTER_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
      };

    case LOGIN_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_ADMIN_SUCCESS:
      return {
        ...state,
        adminData: action.payload,
        loading: false,
      };
    case LOGIN_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_ADMIN_DASHBOARD_REQUEST:
      return { ...state, loading: true };
    case GET_ADMIN_DASHBOARD_SUCCESS:
      return { ...state, adminDashboard: action.payload, loading: false };
    case GET_ADMIN_DASHBOARD_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default adminAuthReducers;
