import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import MessageListener from "./MessageListener";
import { getNotifications } from "../../redux/common/actions/notificationAction";

function Notifications({ user, statusApp, userId }) {
  const { notifications } = useSelector((state) => state.notification);
  const [alertCount, setAlertCount] = useState(0);
  const [clicksCount, setClicksCount] = useState(0);

  const dispatch = useDispatch();

  const handleGetNotifications = () => {
    if (clicksCount === 0) {
      dispatch(getNotifications(`${user?.role}s`, 1));
      setAlertCount(0);
      setClicksCount(clicksCount + 1);
    }
  };

  useEffect(() => {
    let n;
    notifications?.map((i) => {
      i?.notifications?.reduce((acc, curr) => {
        if (!curr?.readAt) {
          n = acc + 1;
        }
      }, 0);
    });
    setAlertCount(n);
  }, [notifications]);

  return (
    <>
      <MessageListener user={user} statusApp={statusApp} userId={userId} />
      <li className="nav-item dropdown position-relative">
        <Link
          to="#"
          className="dropdown-toggle nav-link"
          data-bs-toggle="dropdown"
          onClick={handleGetNotifications}
        >
          <FeatherIcon icon="bell" />
          {alertCount > 0 && (
            <span className="badge bg-info text-white">{alertCount}</span>
          )}
        </Link>
        <div
          style={{
            left: "-247px",
            width: "400px",
            height: "300px",
            overflow: "scroll",
          }}
          className="position-absolute dropdown-menu user-drop nav-item dropdown has-arrow logged-item shadow-none"
        >
          {notifications?.map((item, index) => {
            return (
              <div className="list-group" key={index}>
                <div className="list-group-item list-group-item-action">
                  {item?.date}
                </div>
                {item?.notifications?.map((item) => {
                  return (
                    <Link
                      key={item?.id}
                      to={`/${user?.role}/appointments`}
                      className="list-group-item list-group-item-action"
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{item?.title}</h5>
                        <span>
                          <small>{item?.time}</small>
                        </span>
                      </div>
                      <p className="mb-1 text-start">{item?.body}</p>
                    </Link>
                  );
                })}
              </div>
            );
          })}
        </div>
      </li>
    </>
  );
}

export default Notifications;
