import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
} from "../types/notificationTypes";

const initialState = {
  notifications: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      if (!action.readable) {
        return { ...state, notifications: action.payload };
      }

    default:
      return state;
  }
};

export default notificationReducer;
