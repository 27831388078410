import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  CHANGE_ORDER_STATUS_REQUEST,
  CHANGE_ORDER_STATUS_SUCCESS,
  CHANGE_ORDER_STATUS_FAIL,
  CHECK_PEN_CODE_REQUEST,
  CHECK_PEN_CODE_SUCCESS,
  CHECK_PEN_CODE_FAIL,
} from "../types/orderTypes";

const initialValue = {
  orders: [],
  orderDetails: {},
  loading: false,
};

function labtestOrderReducer(state = initialValue, action) {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return { ...state, loading: true };
    case GET_ORDERS_SUCCESS:
      return { ...state, orders: action.payload, loading: false };
    case GET_ORDERS_FAIL:
      return { ...state, loading: false };

    case GET_ORDER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case GET_ORDER_DETAILS_SUCCESS:
      return { ...state, orderDetails: action.payload, loading: false };
    case GET_ORDER_DETAILS_FAIL:
      return { ...state, loading: false };

    case CHANGE_ORDER_STATUS_REQUEST:
      return { ...state, loading: true };
    case CHANGE_ORDER_STATUS_SUCCESS:
      return { ...state, orderDetails: action.payload, loading: false };
    case CHANGE_ORDER_STATUS_FAIL:
      return { ...state, loading: false };

    case CHECK_PEN_CODE_REQUEST:
      return { ...state, loading: true };
    case CHECK_PEN_CODE_SUCCESS:
      return { ...state, orderDetails: action.payload, loading: false };
    case CHECK_PEN_CODE_FAIL:
      return { ...state, loading: false };

    default:
      return state;
  }
}

export default labtestOrderReducer;
